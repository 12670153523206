import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../app/features/DataSlice";
import HOnePurchaseSummary from "../../../components/HeliorOne/HOnePurchaseSummary";
import HOnePurchaseForm from "../../../components/HeliorOne/HOnePurchaseForm";

const initialInputData = {
  goldPriceWithMargin: 0,
  costSpreadWithMargin: 0,
  storageCostWithMargin: 0,
  total_payment_with_margin: 0,
  bank_transfer: 0,
  duration: 5,
  goldCost: 0,
  storageValue: 0,
  storageCost: 0,
  spreadValue: 0,
  spreadCost: 0,
  totalManufacturingCost: 0,
  totalCost: 0,
  totalGrams: 0,
  type: "custody",
  acceptTNC: false,
  paymentMethod: "",
  ingots: [
    { ingot: 10, quantity: 0, manufacturingCost: 0, goldCost: 0, totalCost: 0 },
    { ingot: 50, quantity: 0, manufacturingCost: 0, goldCost: 0, totalCost: 0 },
    {
      ingot: 100,
      quantity: 0,
      manufacturingCost: 0,
      goldCost: 0,
      totalCost: 0,
    },
    {
      ingot: 250,
      quantity: 0,
      manufacturingCost: 0,
      goldCost: 0,
      totalCost: 0,
    },
    {
      ingot: 500,
      quantity: 0,
      manufacturingCost: 0,
      goldCost: 0,
      totalCost: 0,
    },
    {
      ingot: 1000,
      quantity: 0,
      manufacturingCost: 0,
      goldCost: 0,
      totalCost: 0,
    },
  ],
};

const HeliorOne = () => {
  const pageTitle = "Helior One";
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState(initialInputData);

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mt-3">
      <h4 className="heading mb-3">{pageTitle}</h4>
      <Row className="my-5">
        <Col lg={7}>
          <Card bg="dark1 text-light">
            <Card.Body>
              <HOnePurchaseForm
                inputData={inputData}
                setInputData={setInputData}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={5}>
          <Card bg="dark1 text-light">
            <HOnePurchaseSummary inputData={inputData} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HeliorOne;
