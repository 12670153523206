import React, { useState, useRef } from "react";
import { Badge, ButtonGroup, Card, Form, Button } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SetPageTitle from "./SetPageTitle";

const ClientListTable = ({ tableData, setTitle = false }) => {
  const dt = useRef();
  const { t } = useTranslation();
  const { lang } = useParams();
  const [globalFilter, setGlobalFilter] = useState("");
  const exportCSV = () => dt.current.exportCSV();
  return (
    <Card bg="dark1 text-light">
      <Card.Header className="d-flex justify-content-between align-items-center">
        {setTitle ? (
          <SetPageTitle title="sidebar.clients" />
        ) : (
          <div>{t(`sidebar.clients`)}</div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <Button
            variant="icon-button text-light"
            onClick={() => exportCSV(false)}
            data-pr-tooltip="CSV"
          >
            <i className="fa fa-download" />
          </Button>
          <Form.Control
            type="text"
            placeholder="Search"
            className=" mr-sm-2"
            size="sm"
            onInput={(e) => setGlobalFilter(e.target.value)}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <DataTable
          value={tableData}
          sortMode="multiple"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          ref={dt}
          size={"small"}
          showGridlines
          stripedRows
          removableSort
          globalFilter={globalFilter}
        >
          <Column sortable field="sno" header={"#"}></Column>
          <Column
            sortable
            field="name"
            header={t("client")}
          ></Column>
          <Column sortable field="email" header={t("form.email")}></Column>
          <Column
            sortable
            field="phone"
            header={t("form.mobileNumber")}
          ></Column>
          <Column
            sortable
            header={t("status")}
            body={(rowData) => (
              <Badge
                bg={`${rowData.status === "Active" ? "success" : "secondary"}`}
              >
                {rowData.status === "Active" ? t("active") : t("inactive")}
              </Badge>
            )}
          ></Column>
          <Column
            body={(rowData) => (
              <ButtonGroup size="sm">
                <Link
                  to={`/${lang}/client/${rowData.id}`}
                  className="btn btn-outline-light"
                >
                  <i className="fa fa-eye" />
                </Link>
              </ButtonGroup>
            )}
          ></Column>
        </DataTable>
      </Card.Body>
    </Card>
  );
};

export default ClientListTable;
