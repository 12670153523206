import React, { useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useRequestForPayoutMutation } from "../../app/features/apiSlice";
import { toastError, toastSuccess } from "../../helpers";

const RequestPayout = () => {
  const { t } = useTranslation(); 
  const { lang } = useParams();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(1);

  const [requestForPayout] = useRequestForPayoutMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = { amount, language: lang };
      const response = await requestForPayout(data).unwrap();
      if (response?.error) {
        toastError(response, t("error.serverNotResponding"));
      } else {
        toastSuccess(response?.message);
      }
      setShow(false);
    } catch (err) {
      toastError(err, t("error.serverNotResponding"));
      setShow(false);
    }
    setLoading(false);
  };
  return (
    <>
      <Button
        variant="icon-button text-light"
        className=""
        onClick={() => setShow(true)}
      >
        <i className="fa fa-paper-plane" />
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark h6">
            {t("requestForPayout")}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>{t("amount")}</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>€</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  min="1"
                />
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setShow(false)}
            >
              {t("close")}
            </Button>
            <Button type="submit" variant="gold" size="sm" disabled={loading}>
              {t("confirm")} {loading && <Spinner size="sm" variant="dark" />}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default RequestPayout;
