import React from 'react'
import { Card } from 'react-bootstrap'

const CreditCardTransfer = () => {
  return (
    <Card className="shadow p-3" bg="dark1 text-light">
      <Card.Body>
        <div>CreditCardTransfer</div>
      </Card.Body>
    </Card>
  )
}

export default CreditCardTransfer