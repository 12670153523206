import React, { useRef } from "react";
import { Accordion, Col, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatNumberToLocal, hOneCalculation, toastError } from "../../helpers";
import { useGetValueQuery } from "../../app/features/apiSlice";
import heliorOneTnc from "../../assets/helior_one_tnc.pdf";
import PdfViewer from "../PdfViewer";

const HOnePurchaseForm = ({ inputData, setInputData }) => {
  const sessionId = useRef(Date.now()).current;
  const { t } = useTranslation();
  const { data: StaticValue } = useGetValueQuery({ sessionId });

  // const handleChange = (e, index = null) => {
  //   const { name, value, checked } = e.target;
  //   switch (name) {
  //     case "quantity":
  //       setInputData((prevState) => {
  //         const ingots = prevState.ingots.map((item, i) =>
  //           i === index ? { ...item, [name]: parseInt(value) || 0 } : item
  //         );
  //         const result = hOneCalculation(StaticValue, prevState, ingots);
  //         if (result?.totalGrams < 1000) {
  //           result.duration = "5";
  //           setSelDisable(true);
  //         } else {
  //           setSelDisable(false);
  //         }
  //         return {
  //           ...prevState,
  //           ...result,
  //         };
  //       });
  //       break;

  //     case "duration":
  //       setInputData((prevState) => {
  //         prevState.duration = value;
  //         const result = hOneCalculation(
  //           StaticValue,
  //           prevState,
  //           prevState.ingots
  //         );
  //         return result;
  //       });
  //       break;

  //     case "acceptTNC":
  //       setInputData((prev) => ({
  //         ...prev,
  //         [name]: checked,
  //       }));
  //       break;

  //     default:
  //       setInputData((prevState) => {
  //         prevState[name] = value;
  //         const result = hOneCalculation(
  //           StaticValue,
  //           prevState,
  //           prevState.ingots
  //         );
  //         return result;
  //       });
  //   }
  // };

  const handleChange = (e, index = null) => {
    const { name, value, checked } = e.target;
    setInputData((prevState) => {
      const updatedState = { ...prevState };
      switch (name) {
        case "quantity":{
          updatedState.ingots = prevState.ingots.map((item, i) =>
            i === index ? { ...item, quantity: parseInt(value) || 0 } : item
          );
          break;
        }
        case "duration":
          updatedState.duration = value;
          break;
        case "acceptTNC":
          updatedState[name] = checked;
          break;
        default:
          updatedState[name] = value;
          break;
      }

      let result = hOneCalculation(
        StaticValue,
        updatedState,
        updatedState.ingots
      );

      if(result?.totalGrams >= 1000000){
        toastError("", t('maxTotalgramsError'))
        updatedState.ingots = prevState.ingots.map((item, i) =>
          i === index ? { ...item, quantity: 0 } : item
        );
        result = hOneCalculation(
          StaticValue,
          updatedState,
          updatedState.ingots
        );
      }
      if (result?.totalGrams < 1000) {
        result.duration = "5";
      }
      return result;
    });
  };
  
  return (
    <Accordion className="purchase-page" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <small>Step 1</small>
          <span>{t("purchaseIngot")}</span>
        </Accordion.Header>
        <Accordion.Body>
          <Form.Group className="mb-3">
            <Form.Check
              type="radio"
              label={t("purchaseWithCase")}
              name="type"
              value="custody"
              checked={inputData.type === "custody" ? true : false}
              onChange={(e) => handleChange(e)}
            />
            <Form.Check
              type="radio"
              name="type"
              label={t("purchaseWithHomeDelivery")}
              value="home_delivery"
              checked={inputData.type === "home_delivery" ? true : false}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <Table className="hOne-input-data">
            <thead>
              <tr>
                <th>{t("ingot")}</th>
                <th>{t("quantity")}</th>
                <th>{t("goldCost")}</th>
                <th>{t("manufacturing2")}</th>
                <th>{t("total")}</th>
              </tr>
            </thead>
            <tbody>
              {inputData.ingots.map((item, i) => (
                <tr key={i}>
                  <td className="text-light">{item.ingot}gr</td>
                  <td className="text-light">
                    <Form.Control
                      type="number"
                      name="quantity"
                      value={item.quantity}
                      onChange={(e) => handleChange(e, i)}
                      min="0"
                    />
                  </td>
                  <td className="text-light">
                    {formatNumberToLocal(item.goldCost)} €
                  </td>
                  <td className="text-light">
                    {formatNumberToLocal(item.manufacturingCost)} €
                  </td>
                  <td className="text-light">
                    {formatNumberToLocal(item.totalCost)} €
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {inputData.type === "custody" && (
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>{t("caseDuration")}</Form.Label>
              <Form.Select
                name="duration"
                onChange={(e) => handleChange(e)}
                value={inputData.duration}
              >
                <option value="1" disabled={inputData.totalGrams < 1000 ? true : false}>
                  1 {t("years")}
                </option>
                <option value="5">5 {t("years")}</option>
                <option value="10" disabled={inputData.totalGrams < 1000 ? true : false}>
                  10 {t("years")}
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                error
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <small>Step 2</small>
          <span>{t("termsAndConditions")}</span>
        </Accordion.Header>
        <Accordion.Body>
          {/* <iframe src={heliorOneTnc} title="heliorOneTnc" width="100%" height="400px" frameborder="0"/> */}
          <PdfViewer file={heliorOneTnc} />
          <Form.Check
            type="checkbox"
            name="acceptTNC"
            label={`${t("heliorPlanTNC")}`}
            onChange={(e) => handleChange(e)}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <small>Step 3</small>
          <span>{t("paymentMethod")}</span>
        </Accordion.Header>
        <Accordion.Body>
          <Form.Select
            value={inputData.paymentMethod}
            name="paymentMethod"
            onChange={(e) => handleChange(e)}
          >
            <option value="" disabled>
              {t("select")}
            </option>
            <option value="postal">{t("postal")}</option>
            <option value="wireTransfer">{t("wireTransfer")}</option>
            {/* <option value="creditCard">{t("creditCard")}</option> */}
          </Form.Select>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default HOnePurchaseForm;
