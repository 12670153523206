import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../app/features/DataSlice";
import { Row, Col, Card, Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  usePayRecurringAmountMutation,
  useGetProfileQuery,
  useGetContractDetailsQuery,
  useGetValueQuery,
} from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { formatNumberToLocal, toastError } from "../../../helpers";
import { useNavigate, useParams } from "react-router";

const PayInstallment = () => {
  const pageTitle = "recurringPayment";
  const { lang } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contractCode, setContractCode] = useState("");
  const [restDoc, setRestDoc] = useState({});
  const sessionId = useRef(Date.now()).current;

  const getValue = useGetValueQuery({ sessionId });

  const { data, isLoading, isError, error, isSuccess } = useGetProfileQuery({
    sessionId,
  });

  const contractDetails = useGetContractDetailsQuery({
    sessionId,
    contractCode,
  });

  const [restAmount, setRestAmount] = useState(0);
  const initialPurchaseData = {
    paymentPeriodicity: "",
    recurringPaymentAmount: 0,
    recurringPaymentNumber: 1,
    paymentMethod: "",
    totalRecurringAmount: "",
    totalPayment: 0,
    initialPaymentNumber: 0,
    initialPaymentAmount: 0,
    dossierOpeningCharge: "",
    isDocIncluded: false,
  };

  const [purchaseData, setPurchaseData] = useState(initialPurchaseData);
  const [errorMsg, setErrorMsg] = useState({});
  const [isLoading2, setIsLoading] = useState(false);
  const [activePlan, setActivePlan] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      const plans = data?.plan;
      const currActivePlan = plans.filter((plan) => plan.status === "Active");
      if (currActivePlan.length > 0) {
        setActivePlan(currActivePlan[0]);
        setContractCode(currActivePlan[0]?.contract_code);
        const {
          payment_amount,
          periodicity_of_amount,
          total_payment,
          recurring_amount_paid,
        } = currActivePlan[0];
        setRestAmount(recurring_amount_paid - total_payment);
        setPurchaseData((prev) => ({
          ...prev,
          paymentPeriodicity: periodicity_of_amount,
          recurringPaymentAmount: payment_amount,
          totalPayment: payment_amount * 1,
        }));
        contractDetails.refetch();
      }
    }
    // eslint-disable-next-line
  }, [isSuccess, data]);

  useEffect(() => {
    if (contractDetails.isSuccess && contractCode) {
      const payments = contractDetails?.data?.data?.payment;
      const docData = payments.filter(
        (item) =>
          item.movement_type === "DOC" && item.payment_status === "Pending"
      );
      if (docData.length > 0) {
        setRestDoc(docData[0]);
      }
    }
  }, [contractDetails, contractCode]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setErrorMsg({});
    switch (name) {
      case "recurringPaymentNumber":
        if(value <= (activePlan?.number_of_recurring_payments -
          activePlan?.number_of_initial_payments)){
            const totalPayment = value * purchaseData.recurringPaymentAmount;
            setPurchaseData((prev) => ({
              ...prev,
              [name]: value,
              totalPayment,
            }));
          }
        break;
      case "isDocIncluded":
        setPurchaseData((prev) => ({
          ...prev,
          [name]: checked,
          totalPayment: checked
            ? prev.recurringPaymentAmount + restDoc.amount_paid
            : prev.recurringPaymentAmount,
        }));
        break;
      default:
        setPurchaseData((prev) => ({
          ...prev,
          [name]: value,
        }));
        break;
    }
  };

  const validateData = (data) => {
    if (data.totalPayment > restAmount) {
      toastError("", t("recurringAmountGreaterThanTotalPayment"));
      return false;
    }
    if (data.nroInitialPayments <= 0) {
      toastError("", t("initialPaymentNumberZero"));
      return false;
    }
    if (!data.paymentMethod) {
      toastError("", t("paymentMethodRequired"));
      return false;
    }
    return true;
  };

  const [payRecurringAmount] = usePayRecurringAmountMutation();
  const handleSubmit = async () => {
    setIsLoading(true);
    let paymentMethod = "";
    paymentMethod =
      purchaseData.paymentMethod === "wireTransfer"
        ? "Wire Transfer"
        : paymentMethod;
    paymentMethod =
      purchaseData.paymentMethod === "creditCard"
        ? "Credit Card"
        : paymentMethod;
    paymentMethod =
      purchaseData.paymentMethod === "postal" ? "Postal" : paymentMethod;
    try {
      const data = {
        paymentAmount: purchaseData.recurringPaymentAmount,
        nroInitialPayments: purchaseData.recurringPaymentNumber,
        paymentMethod,
        isDocIncluded: purchaseData.isDocIncluded,
      };

      if (validateData(data)) {
        const response = await payRecurringAmount({
          data,
          contractCode,
        });
        if (response?.error) {
          toastError(response, t("error.serverNotResponding"));
        } else {
          navigate(`/${lang}/payment-success`);
        }
      }
    } catch (err) {
      toastError(err, t("error.serverNotResponding"));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(setPageTitle(t(`${pageTitle}`)));
  });

  if (isLoading) return <Loader />;
  if (isError) return <Error error={error} />;

  return (
    <div className="my-3">
      <h4 className="heading mb-3">{t(`${pageTitle}`)}</h4>
      {isSuccess && (
        <>
          <Card className="mb-3" bg="dark1 text-light">
            <Card.Body>
              <Row>
                <Col lg="9">
                  <ul className="list-unstyled m-0 columns-3">
                    <li className="my-2">
                      <b>{t("date")}:</b>{" "}
                      {activePlan?.contract_date?.split("T")[0]}
                    </li>
                    <li className="my-2">
                      <b>{t("periodicity")}:</b>{" "}
                      {t(`${activePlan?.periodicity_of_amount}`)}
                    </li>
                    <li className="my-2">
                      <b>{t("paymentAmount")}:</b> €
                      {formatNumberToLocal(activePlan?.payment_amount)}
                    </li>
                    <li className="my-2">
                      <b>{t("initialPayment")}:</b>{" "}
                      {activePlan?.number_of_initial_payments}
                    </li>
                    <li className="my-2">
                      <b>{t("initialIngot")}:</b> {activePlan?.ingot_gr}
                    </li>
                    <li className="my-2">
                      <b>{t("recurringPayments")}:</b>{" "}
                      {activePlan?.number_of_recurring_payments}
                    </li>
                    <li className="my-2">
                      <b>{t("recurringIngot")}:</b>{" "}
                      {activePlan?.cutting_ingots_recurring_payments}
                    </li>
                    <li className="my-2">
                      <b>{t("totalPayment2")}:</b> €
                      {formatNumberToLocal(activePlan?.recurring_amount_paid)}
                    </li>
                    <li className="my-2">
                      <b>{t("note")}:</b> {activePlan?.note}
                    </li>
                    <li className="my-2">
                      <b>{t("quotaDoc")}:</b> €
                      {formatNumberToLocal(activePlan?.doc_share)}
                    </li>
                    <li className="my-2">
                      <b>{t("recurringAmountPaid")}:</b> €
                      {formatNumberToLocal(activePlan?.total_payment)}
                    </li>
                    <li className="my-2"></li>
                  </ul>
                </Col>
                <Col lg="3">
                  <div className="h-100 d-flex justify-content-evenly align-items-center">
                    <i className="fa fa-cart-shopping bg-gold text-light p-3 rounded-circle font-40" />
                    <div>
                      <h6>{activePlan?.contract_code}</h6>
                      N' Ordine
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col md="7">
              <Card bg="dark1 text-light">
                <Card.Body>
                  <Form>
                    <Row>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>
                          {t("amountOfRecurringPayments")}
                        </Form.Label>
                        <Form.Control
                          value={purchaseData?.recurringPaymentAmount}
                          disabled
                        />
                      </Form.Group>
                      {activePlan?.number_of_recurring_payments -
                        activePlan?.number_of_initial_payments >
                        0 && (
                        <Form.Group as={Col} className="mb-3">
                          <Form.Label>{t("numberOfPayments")}</Form.Label>
                          <Form.Control
                            type="number"
                            name="recurringPaymentNumber"
                            min={1}
                            max={
                              activePlan?.number_of_recurring_payments -
                              activePlan?.number_of_initial_payments
                            }
                            value={purchaseData.recurringPaymentNumber}
                            onChange={(e) => handleChange(e)}
                            isInvalid={errorMsg?.recurringPaymentNumber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errorMsg.recurringPaymentNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    </Row>
                    <Row>
                      <Form.Group as={Col} className="mb-2">
                        <Form.Label>{t("paymentMethod")}</Form.Label>
                        <Form.Select
                          value={purchaseData.paymentMethod}
                          name="paymentMethod"
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="" disabled>
                            {t("select")}
                          </option>
                          <option value="postal">{t("postal")}</option>
                          <option value="wireTransfer">
                            {t("wireTransfer")}
                          </option>
                          <option value="creditCard">{t("creditCard")}</option>
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    {restDoc.payment_status === "Pending" && (
                      <Row>
                        <Form.Group as={Col} className="mb-2">
                          <Form.Check
                            type="checkbox"
                            name="isDocIncluded"
                            label={t("payRestDocAlso")}
                            onChange={(e) => handleChange(e)}
                            checked={purchaseData.isDocIncluded}
                            className="mt-2"
                          />
                        </Form.Group>
                      </Row>
                    )}
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={5}>
              <Card bg="dark1 text-light">
                <Card.Body>
                  <ul className="list-unstyled">
                    <li className="d-flex justify-content-between mb-3">
                      <span>{t("numberOfRecurringPayments")} </span>{" "}
                      {purchaseData.recurringPaymentNumber || 0}
                    </li>
                    <li className="d-flex justify-content-between mb-3">
                      <span>{t("paymentAmount")} </span> €
                      {formatNumberToLocal(
                        purchaseData.recurringPaymentAmount
                      ) || 0}
                    </li>
                    {purchaseData.isDocIncluded && (
                      <li className="d-flex justify-content-between mb-3">
                        <span>{t("doc")} </span> €
                        {formatNumberToLocal(restDoc.amount_paid) || 0}
                      </li>
                    )}
                    {purchaseData.recurringPaymentAmount > 0 && (
                      <li className="d-flex justify-content-between mb-3">
                        <span>{t("stampDuty")} </span> €
                        {formatNumberToLocal(getValue.data?.stampDuty?.value) ||
                          0}
                      </li>
                    )}
                  </ul>
                  <hr />
                  <p className="d-flex justify-content-between text-capitalize mb-1">
                    <span>{t("totalPayment")}</span> €
                    {formatNumberToLocal(
                      purchaseData.totalPayment +
                        (getValue.data?.stampDuty?.value || 0) || 0
                    )}
                  </p>
                  <p className="d-flex justify-content-between text-capitalize">
                    <span>{t("paymentMethod")}</span>{" "}
                    {t(`${purchaseData.paymentMethod}`)}
                  </p>
                  <Button
                    variant="primary-outline"
                    className="w-100 d-flex align-items-center justify-content-center"
                    onClick={handleSubmit}
                  >
                    {t("payNow")}
                    {isLoading2 && (
                      <Spinner size="sm" className="ms-2" animation="grow" />
                    )}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default PayInstallment;
