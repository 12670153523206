import toast from "react-hot-toast";
import Swal from "sweetalert2";

// export const toastStyle = { background: "#333", color: "#fff", align: "left" };
export const toastStyle = { background: "#fff", color: "#333", align: "left" };

export const formValidator = (values) => {
  const errors = {};
  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/i;

  if (values.email && !emailRegExp.test(values.email)) {
    errors.email = "invalidEmail";
  }

  if (values.password && !passwordRegExp.test(values.password)) {
    errors.password = "invalidPassword";
  }

  if (values.confirmPassword && values.confirmPassword !== values.password) {
    errors.confirmPassword = "passwordMismatch";
  }

  if (!values.type) {
    errors.type = "typeRequired";
  }

  if (!values.taxCode) {
    errors.taxCode = "taxCodeRequired";
  }

  if (!values.affiliateCode) {
    errors.affiliateCode = "affiliateCodeRequired";
  }

  if (values.type === "company" && !values.vatNumber) {
    errors.vatNumber = "vatNumberRequired";
  }

  if (!values.name) {
    errors.name = "firstnameRequired";
  }

  if (!values.surName) {
    errors.surName = "lastnameRequired";
  }

  if (!values.dateOfBirth) {
    errors.dateOfBirth = "dobRequired";
  }

  if (!values.sex) {
    errors.sex = "sexRequired";
  }

  if (!values.placeOfBirth) {
    errors.placeOfBirth = "birthPlaceRequired";
  }

  if (!values.provinceOfBirth) {
    errors.provinceOfBirth = "birthCountryRequired";
  }

  if (!values.mobilePhone) {
    errors.mobilePhone = "mobileNumberRequired";
  }

  if (!values.address) {
    errors.address = "residenceAddressRequired";
  }

  if (!values.city) {
    errors.city = "residenceCityRequired";
  }

  if (!values.province) {
    errors.province = "residenceCountryRequired";
  }

  if (!values.postcode) {
    errors.postcode = "residencePostcodeRequired";
  }

  if (!values.addressHome) {
    errors.addressHome = "domicileAddressRequired";
  }

  if (!values.cityHome) {
    errors.cityHome = "domicileCityRequired";
  }

  if (!values.provinceHome) {
    errors.provinceHome = "domicileCountryRequired";
  }

  if (!values.postcodeHome) {
    errors.postcodeHome = "domicilePostcodeRequired";
  }

  return errors;
};

export const toastMessage = (message) => {
  toast(message, { icon: "👏", duration: 5000, style: toastStyle });
};

export const swalMessage = (message) => {
  Swal.fire({
    title: message,
    // showConfirmButton: false,
    timer: 5000,
  });
};

export const toastSuccess = (message) => {
  toast.success(message, { duration: 5000, style: toastStyle });
};

export const swalSuccess = (message) => {
  Swal.fire({
    icon: "success",
    title: message,
    timer: 5000,
  });
};

export const toastError = (err, serverNotResponding = "") => {
  console.log(err);
  let errorMessage = err?.data?.error ?? serverNotResponding;
  errorMessage = err?.error?.data?.message ?? errorMessage;
  errorMessage = err?.error?.data?.error ?? errorMessage;
  errorMessage = err?.data?.message ?? errorMessage;
  errorMessage = err?.data?.err ?? errorMessage;
  errorMessage = err?.data?.msg ?? errorMessage;
  toast.error(errorMessage, { duration: 5000, style: toastStyle });
};

export const swalError = (err, serverNotResponding = "") => {
  console.log(err);
  let errorMessage = err?.data?.error ?? serverNotResponding;
  errorMessage = err?.error?.data?.message ?? errorMessage;
  errorMessage = err?.error?.data?.error ?? errorMessage;
  errorMessage = err?.data?.message ?? errorMessage;
  errorMessage = err?.data?.err ?? errorMessage;
  errorMessage = err?.data?.msg ?? errorMessage;
  Swal.fire({
    icon: "error",
    title: errorMessage,
    timer: 5000,
  });
};

export const getReferralFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("referral");
};

export const formatNumberToLocal = (number = 0) => {
  if (number > 0) {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      // style: 'currency',
      // currency: 'EUR'  //Output: "€275.27"
    });
  } else {
    return 0;
  }
};

export const hOneCalculation = (StaticValue, inputData, ingots) => {
  let duration = parseInt(inputData.duration);
  let goldCost = 0;
  let storageValue = 0;
  let storageCost = 0;
  let spreadValue = 0;
  let costSpread = 0;
  let totalManufacturingCost = 0;
  let totalCost = 0;
  let total_gr = 0;
  let goldPriceWithMargin = 0;
  let costSpreadWithMargin = 0;
  let storageCostWithMargin = 0;
  let bank_transfer = 0;
  let total_payment_with_margin = 0;
  let homeDelivery = inputData.type === "home_delivery" ? true : false;

  const hOneDettaglios = ingots;
  // const isHaveQuantity = hOneDettaglios.some((item) => item.quantity > 0);
  const isHaveQuantity = true;

  if (isHaveQuantity) {
    const goldFixingPerGramValue = StaticValue.goldValue[0].value || 0;

    for (const item of hOneDettaglios) {
      const { ingot, quantity } = item;

      const manufacturingCost =
        StaticValue.manufacturingCost.find(
          (item) => item.ingot === `${ingot}gr`
        )?.cost || 0;

      const goldCost = ingot * goldFixingPerGramValue * quantity;

      item.manufacturingCost = quantity ? manufacturingCost * quantity : 0;
      item.goldCost = quantity ? goldCost : 0;
      item.totalCost = quantity ? item.manufacturingCost + goldCost : 0;
    }

    total_gr = hOneDettaglios.reduce(
      (total, item) => total + item.ingot * item.quantity,
      0
    );

    if (total_gr >= 100 && total_gr <= 240) {
      spreadValue = 0.05;
    } else if (total_gr >= 250 && total_gr <= 490) {
      spreadValue = 0.04;
    } else if (total_gr >= 500 && total_gr <= 990) {
      spreadValue = 0.03;
    } else if (total_gr >= 1000) {
      spreadValue = 0.02;
    }

    if (duration === 1 && total_gr >= 1000) {
      storageValue = 0.03;
    } else if (duration === 10 && total_gr >= 1000) {
      storageValue = 0.12;
    } else {
      storageValue = 0.075;
    }

    totalManufacturingCost = hOneDettaglios.reduce(
      (total, item) => total + item.manufacturingCost,
      0
    );

    if (homeDelivery) {
      spreadValue = spreadValue * 2;
      totalManufacturingCost = 0;
    }

    goldCost = hOneDettaglios.reduce((total, item) => total + item.goldCost, 0);

    storageCost = goldCost * storageValue;
    costSpread = goldCost * spreadValue;
    totalCost = goldCost + costSpread + storageCost + totalManufacturingCost;

    // const guaranteeMargin = StaticValue.data.find((item) => item.name === "guarantee margin")?.value || 0;
    const guaranteeMargin = StaticValue.marginValue.value || 0;
    const margin = parseInt(guaranteeMargin) / 100;

    goldPriceWithMargin = goldCost * margin;
    costSpreadWithMargin = costSpread * margin;
    storageCostWithMargin = storageCost * margin;

    total_payment_with_margin =
      goldPriceWithMargin + costSpreadWithMargin + storageCostWithMargin;

    bank_transfer = totalCost + total_payment_with_margin;
  }

  return {
    ...inputData,
    ingots: hOneDettaglios,
    duration,
    goldCost,
    storageValue,
    storageCost,
    spreadValue,
    spreadCost: costSpread,
    totalManufacturingCost,
    totalCost,
    totalGrams: total_gr,
    goldPriceWithMargin,
    costSpreadWithMargin,
    storageCostWithMargin,
    total_payment_with_margin,
    bank_transfer,
  };
};

export const hPlanCalculation = (data) => {
  let {
    initialPaymentNumber,
    recurringPaymentAmount,
    recurringPaymentNumber,
    docSplit,
    discountCode,
  } = data;

  let initialPaymentAmount = 0;
  let totalRecurringAmount = 0;
  let dossierOpeningCharge = 0;
  let docSplitAmount = 0;
  let totalPayment = 0;
  let appliedCode = discountCode;
  let message = { text: "", type: "" };

  initialPaymentAmount = initialPaymentNumber * recurringPaymentAmount;
  console.log({ initialPaymentAmount });
  totalRecurringAmount = recurringPaymentAmount * recurringPaymentNumber;
  dossierOpeningCharge = totalRecurringAmount / 10;
  totalPayment = dossierOpeningCharge + initialPaymentAmount;

  if (docSplit && dossierOpeningCharge < 4800) {
    message = { text: "docCantDivided", type: "error" };
    docSplit = false;
  }
  if (docSplit && dossierOpeningCharge > 4800) {
    docSplitAmount = dossierOpeningCharge / 2;
    totalPayment = docSplitAmount + initialPaymentAmount;
  }

  if (discountCode && dossierOpeningCharge >= 2000) {
    let discountPercentage = 0;
    if (discountCode === "CS10") {
      appliedCode = "CS10";
      discountPercentage += 0.1;
    }

    if (discountCode === "CS5") {
      appliedCode = "CS5";
      discountPercentage += 0.05;
    }

    if (
      discountPercentage !== 0 &&
      dossierOpeningCharge >= 6000 &&
      dossierOpeningCharge < 12000
    ) {
      message = { text: "extra5Discount", type: "success" };
      appliedCode = discountCode + "+5";
      discountPercentage += 0.05;
    }
    if (discountPercentage !== 0 && dossierOpeningCharge >= 12000) {
      message = { text: "extra10Discount", type: "success" };
      appliedCode = discountCode + "+10";
      discountPercentage += 0.1;
    }

    // console.log({discountPercentage, appliedCode})

    if (docSplit) {
      totalPayment = totalPayment - docSplitAmount * discountPercentage;
    } else {
      totalPayment = totalPayment - dossierOpeningCharge * discountPercentage;
    }
  }

  const result = {
    ...data,
    initialPaymentNumber: (parseInt(initialPaymentNumber) <= recurringPaymentAmount) ? initialPaymentNumber : recurringPaymentAmount,
    initialPaymentAmount,
    totalRecurringAmount,
    dossierOpeningCharge,
    docSplitAmount,
    totalPayment,
    discountCode,
    appliedCode,
    docSplit,
    message,
  };

  return result;
};

export const flattenUsers = (users, result = []) => {
  // Iterate through the array of users
  users.forEach(user => {
    // Add current user to the result array
    result.push(user);
    
    // If the user has a downline, recurse into it
    if (user.downline && user.downline.length > 0) {
      flattenUsers(user.downline, result);
    }
  });
  
  return result;
}

export const flattenHierarchy = (data) => {
  let result = [];
  const flattenUsers = (node, parentId, level = 1) => {
    let newNode = {
      id: node.user_id,
      referral_code: node.referral_code || "",
      name: node?.name ?? node?.user?.first_name + " " + node?.user?.last_name,
      status: node.status || "",
      email: node.email || node.user.email || "",
      parentId: parentId || "",
      totalDOC: node?.totalDOC || node?.total_doc || "",
      earnedCommission: node?.earnedCommission || "",
      restCommission: node?.restCommission || "",
      qualification: node?.qualification || "-",
      affiliateCode: node?.affiliate_code || "-",
      clients: node?.numberOfClients || 0,
      level: level-1
    };
    result.push(newNode);

    if (node.downline && node.downline.length > 0) {
      node.downline.forEach((child) => {
        flattenUsers(child, node.user_id, level + 1);
      });
    }
  }

  const parentId = data?.upline?.user_id || ""
  flattenUsers(data, parentId, 1);
  return result;
};