import React, { useState } from "react";
import { Accordion, Form, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OverlayTooltip from "../_includes/OverlayTooltip";
import {
  hPlanCalculation,
  toastError,
  toastSuccess,
} from "../../helpers";
import heliorPlanTnc from "../../assets/helior_plan_tnc.pdf"
import PdfViewer from "../PdfViewer";

const data = [
  {
    type: "Monthly",
    recurringAmount: [
      100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750,
      1000, 1250, 1500, 1750, 2000, 2500, 3000, 4000, 5000,
    ],
    minRecurringPaymentNumber: 60,
    maxRecurringPaymentNumber: 420,
  },
  {
    type: "Bimonthly",
    recurringAmount: [
      200, 400, 800, 1000, 2500, 3000, 3500, 4000, 5000, 6000, 8000, 10000,
    ],
    minRecurringPaymentNumber: 30,
    maxRecurringPaymentNumber: 210,
  },
  {
    type: "Quarterly",
    recurringAmount: [
      300, 600, 900, 1200, 1500, 3750, 4500, 5250, 6000, 7500, 9000, 12000,
      15000,
    ],
    minRecurringPaymentNumber: 20,
    maxRecurringPaymentNumber: 140,
  },
];

const HeliorPurchaseForm = ({
  purchaseData,
  setPurchaseData,
  resetPurchaseData,
  formData,
  setformData,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setError({});
    switch (name) {
      case "paymentPeriodicity":
        const recurringAmountOptions = data.filter(
          (item) => item.type === value
        );
        setformData(recurringAmountOptions[0]);
        resetPurchaseData();
        setPurchaseData((prev) => ({
          ...prev,
          [name]: value,
        }));
        break;
      case "acceptTNC":
        setPurchaseData((prev) => ({
          ...prev,
          [name]: checked,
        }));
        break;
      case "docSplit":
        {
          const newData = { ...purchaseData, [name]: checked };
          const { message, ...result } = hPlanCalculation(newData);
          if (message.type === "error") {
            toastError("", t(message.text));
          }
          if (message.type === "success") {
            toastSuccess(t(message.text));
          }
          setPurchaseData(result);
        }
        break;
      default:
        {
          const newData = { ...purchaseData, [name]: value };
          const { message, ...result } = hPlanCalculation(newData);
          // if (message.type === "error") {
          //   toastError("", t(message.text));
          // }
          // if (message.type === "success") {
          //   toastSuccess(t(message.text));
          // }
          setPurchaseData(result);
        }
        break;
    }
  };

  return (
    <Accordion className="purchase-page" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <small>Step 1</small>
          <span>{t("storagePlan")}</span>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>{t("paymentPeriodicity")}</Form.Label>
              <Form.Select
                name="paymentPeriodicity"
                onChange={(e) => handleChange(e)}
                value={purchaseData.paymentPeriodicity}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="Monthly">{t("monthly")} </option>
                <option value="Bimonthly">{t("bimonthly")} </option>
                <option value="Quarterly">{t("quarterly")} </option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>{t("amountOfRecurringPayments")}</Form.Label>
              <Form.Select
                name="recurringPaymentAmount"
                onChange={(e) => handleChange(e)}
                value={purchaseData.recurringPaymentAmount}
              >
                <option value="0" disabled>
                  Select
                </option>
                {formData?.recurringAmount.map((value, i) => (
                  <option key={i}>{value}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label className="d-flex justify-content-between">
                {t("numberOfRecurringPayments")}
                {purchaseData.paymentPeriodicity && (
                  <OverlayTooltip
                    text={`${t("minRecurringNumberMsg")} ${
                      formData?.minRecurringPaymentNumber
                    } ${t("and")} ${formData?.maxRecurringPaymentNumber}`}
                  />
                )}
              </Form.Label>
              <Form.Control
                type="number"
                name="recurringPaymentNumber"
                min={formData?.minRecurringPaymentNumber}
                max={formData?.maxRecurringPaymentNumber}
                onChange={(e) => handleChange(e)}
                value={purchaseData.recurringPaymentNumber}
                isInvalid={error?.recurringPaymentNumber}
              />
              <Form.Control.Feedback type="invalid">
                {error.recurringPaymentNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>{t("numberOfInitialPayments")}</Form.Label>
              <Form.Control
                type="number"
                name="initialPaymentNumber"
                min={1}
                max={purchaseData.recurringPaymentNumber - 1}
                value={purchaseData.initialPaymentNumber}
                onChange={(e) => handleChange(e)}
                isInvalid={error?.initialPaymentNumber}
              />
              <Form.Control.Feedback type="invalid">
                {error.initialPaymentNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>{t("ingotCutting")}</Form.Label>
            <Form.Select
              value={purchaseData.ingot}
              name="ingot"
              onChange={(e) => handleChange(e)}
              size="sm"
              required
            >
              <option>10gr</option>
              <option>50gr</option>
              <option>100gr</option>
              <option>250gr</option>
              <option>500gr</option>
              <option>1000gr</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3">
            <Form.Check
              type="switch"
              id="doc-switch"
              name="docSplit"
              checked={purchaseData.docSplit}
              onChange={(e) => handleChange(e)}
              label={t("docLabel")}
            />
            <Form.Text id="passwordHelpBlock" muted>
              <b>{t("note")}:</b>
              <br />- {t("docShareLine1")}
              <br />- {t("docShareLine2")}
              <br />- {t("docShareLine3")}
            </Form.Text>
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <small>Step 2</small>
          <span>{t("termsAndConditions")}</span>
        </Accordion.Header>
        <Accordion.Body>
          <PdfViewer file={heliorPlanTnc} />
          <Form.Check
            type="checkbox"
            name="acceptTNC"
            label={`${t("heliorPlanTNC")}`}
            onChange={(e) => handleChange(e)}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <small>Step 3</small>
          <span>{t("paymentMethod")}</span>
        </Accordion.Header>
        <Accordion.Body>
          <Form.Select
            value={purchaseData.paymentMethod}
            name="paymentMethod"
            onChange={(e) => handleChange(e)}
          >
            <option value="" disabled>
              {t("select")}
            </option>
            <option value="postal">{t("postal")}</option>
            <option value="wireTransfer">{t("wireTransfer")}</option>
            <option value="creditCard">{t("creditCard")}</option>
          </Form.Select>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default HeliorPurchaseForm;
