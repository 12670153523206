import React from "react";
import { Card } from "react-bootstrap";

const PostalTransfer = () => {
  return (
    <Card className="shadow p-3" bg="dark1 text-light">
      <Card.Body>
        <div>PostalTransfer</div>
      </Card.Body>
    </Card>
  );
};

export default PostalTransfer;
