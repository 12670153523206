import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const OverlayTooltip = ({ text }) => {
  return (
    <OverlayTrigger
      placement={"top"}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      <i className="fa fa-info-circle" />
    </OverlayTrigger>
  );
};

export default OverlayTooltip;
