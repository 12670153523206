import React, { useEffect, useRef, useState } from "react";
import { useGetClientsQuery } from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import ClientListTable from "../../../components/ClientListTable";

const ListClient = () => {
  const sessionId = useRef(Date.now()).current;
  const [tableData, setTableData] = useState([]);
  const { data, isLoading, isSuccess, isError, error } = useGetClientsQuery({
    sessionId,
  });

  useEffect(() => {
    if (isSuccess) {
      const items = data?.data;
      const tableItem = [];
      let i = 1;
      if (items.length > 0) {
        items.forEach((item) => {
          tableItem.push({
            sno: i,
            name: `${item?.customer?.first_name} ${item?.customer?.last_name}`,
            email: item?.customer?.email,
            phone: item?.customer?.phone,
            id: item?.customer?.id,
            status: item?.customer?.organizationUser?.status,
          });
          i++;
        });
      }
      setTableData(tableItem);
    }
  }, [isSuccess, data]);

  if (isLoading) <Loader />;
  if (isError) <Error error={error} />;
  return (
    <div className="my-3">
      <ClientListTable tableData={tableData} setTitle={true}/>
    </div>
  );
};

export default ListClient;
