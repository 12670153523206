import React, { useRef, useState, useEffect } from "react";
import { Button, ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { useGetDownlineCollaboratorQuery } from "../../app/features/apiSlice";
import SetPageTitle from "../../components/SetPageTitle";
import { OrgChartComponent } from "../../components/OrgChart";
import { flattenHierarchy } from "../../helpers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserData } from "../../app/features/DataSlice";

const Structure = () => {
  const { t } = useTranslation();
  const loggedUser = useSelector(selectUserData);
  const targetRef = useRef(null);
  const sessionId = useRef(Date.now()).current;
  const [orgChart, setOrgChart] = useState([]);
  const [flatUsers, setFlatUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [uniqueLvls, setUniqueLvls] = useState([]);
  const [highlightedId, setHighlightedId] = useState(null);
  const { data, isSuccess } = useGetDownlineCollaboratorQuery({ sessionId });

  useEffect(() => {
    if (isSuccess) {
      const result = flattenHierarchy(data.data);
      const removeLoggedUserParent = result.filter(user => (user.parentId && user.id !== loggedUser.id))
      const sortedData = removeLoggedUserParent.sort((a, b) => a.level - b.level);
      setFlatUsers(sortedData);
      setFilteredUsers(sortedData);
      const levels = result.map((item) => item.level);
      const uniqueLevels = [...new Set(levels)];
      const filteredLevels = uniqueLevels.filter(level => level !== 0);
      setUniqueLvls(filteredLevels);

      if (data?.data?.upline?.user_id) {
        const uplineData = {
          id: data?.data?.upline?.user_id,
          referral_code: data?.data?.upline?.referral_code || "",
          name: data?.data?.upline?.name,
          status: data?.data?.upline?.status || "",
          email: data?.data?.upline?.email || "",
          parentId: "",
          totalDOC: data?.data?.totalDOC || "",
          earnedCommission: data?.data?.earnedCommission || "",
          restCommission: data?.data?.restCommission || "",
          qualification: data?.data?.qualification || "",
        };
        result.push(uplineData);
      }
      setOrgChart(result);
    }
  }, [isSuccess, data, loggedUser]);

  const scrollToDiv = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const level = e.target.level.value;
    const result = flatUsers.filter(
      (user) =>
        (!name || user.name === name) &&
        (!level || user.level === parseInt(level, 10))
    );
    setFilteredUsers(result);
  };

  return (
    <div className="my-3">
      <Card bg="dark1 text-light mb-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <SetPageTitle title="sidebar.structure" />
        </Card.Header>
        <Card.Body>
          <Row className="mb-3 align-items-ceter">
            <Col className="d-flex text-center">
              <p className="mb-0">
                {t("totalDownlines")} <br />
                {flatUsers.length || 0}
              </p>
              <p className="ms-3 mb-0">
                {t("totalLevels")} <br /> {uniqueLvls.length || 0}
              </p>
            </Col>
            <Col className="d-flex justify-content-end">
              <Form
                onSubmit={handleSubmit}
                onReset={() => setFilteredUsers(flatUsers)}
                className="d-flex"
              >
                <Form.Group>
                  <Form.Label>{t("name")}</Form.Label>
                  <Form.Select size="sm" name="name" defaultValue={""}>
                    <option value="" disabled>
                      {t("select")}
                    </option>
                    {flatUsers.map((user, i) => (
                      <option key={i} value={user.name}>
                        {user.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="ms-1">
                  <Form.Label>{t("level")}</Form.Label>
                  <Form.Select size="sm" name="level" defaultValue={""}>
                    <option disabled value="">
                      {t("select")}
                    </option>
                    {uniqueLvls.map((level, i) => (
                      <option key={i} value={level}>
                        {level}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <div className="d-flex align-items-end">
                  <Button
                    size="sm"
                    variant="outline-light"
                    type="submit"
                    className="ms-1"
                  >
                    {t("view")}
                  </Button>
                  <Button
                    type="reset"
                    size="sm"
                    variant="outline-light"
                    className="ms-1"
                  >
                    {t("reset")}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>

          <DataTable
            value={filteredUsers}
            sortMode="multiple"
            paginator
            rows={5}
            // rowsPerPageOptions={[10, 25, 50]}
            // ref={dt}
            size={"small"}
            showGridlines
            stripedRows
            removableSort
            // globalFilter={globalFilter}
          >
            <Column sortable field="name" header={t("members")} />
            <Column sortable field="referral_code" header={t("referralCode")} />
            <Column sortable field="affiliateCode" header={t("sponsor")} />
            <Column sortable field="level" header={t("level")} />
            <Column
              sortable
              field="qualification"
              header={t("qualification")}
            />
            <Column
              body={(rowData) => (
                <ButtonGroup size="sm">
                  <Button
                    variant="outline-light"
                    onClick={() => {
                      setHighlightedId(rowData.id);
                      scrollToDiv();
                    }}
                  >
                    <i className="fa fa-diagram-project"></i>
                  </Button>
                </ButtonGroup>
              )}
            ></Column>
          </DataTable>
        </Card.Body>
      </Card>
      <Card bg="dark1 text-light" ref={targetRef}>
        <Card.Body>
          {orgChart.length > 0 && (
            <OrgChartComponent data={orgChart} highlightedId={highlightedId} />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Structure;
