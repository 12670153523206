import React, { useState, useRef } from "react";
import { Button, ButtonGroup, Card, Form } from "react-bootstrap";
import SetPageTitle from "../../components/SetPageTitle";
import AddDiscount from "../../components/discounts/AddDiscount";
import {
  useDeleteDiscountMutation,
  useGetDiscountQuery,
} from "../../app/features/apiSlice";
import Loader from "../../components/Loader";
import Error from "../../components/Error";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import EditDiscount from "../../components/discounts/EditDiscount";
import DeleteItem from "../../components/DeleteItem";
import moment from "moment";
import "moment/locale/it";
import { useParams } from "react-router";

const Discounts = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  lang === "it" ? moment.locale("it") : moment.locale("en");
  const dt = useRef();
  const sessionId = useRef(Date.now()).current;
  const exportCSV = () => dt.current.exportCSV();
  const [globalFilter, setGlobalFilter] = useState("");
  const { data, isLoading, isError, error, refetch } = useGetDiscountQuery({
    sessionId,
  });
  const [deleteDiscount] = useDeleteDiscountMutation();

  if (isLoading) return <Loader />;
  if (isError) return <Error error={error} />;

  return (
    <div className="my-3">
      <Card bg="dark1 text-light">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <SetPageTitle title="discounts" />
          <div className="d-flex justify-content-between align-items-center">
            <AddDiscount refetch={refetch} />
            <Button
              variant="icon-button text-light"
              onClick={() => exportCSV(false)}
              data-pr-tooltip="CSV"
            >
              <i className="fa fa-download" />
            </Button>
            <Form.Control
              type="text"
              placeholder="Search"
              className=" mr-sm-2"
              size="sm"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </div>
        </Card.Header>
        <Card.Body>
          <DataTable
            value={data?.discounts}
            sortMode="multiple"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            ref={dt}
            size={"small"}
            showGridlines
            stripedRows
            removableSort
            globalFilter={globalFilter}
          >
            <Column sortable field="dis_code" header={t("code")} />
            <Column
              sortable
              header={t("assignTo")}
              body={(rowData) =>
                `${rowData.user.first_name} ${rowData.user.last_name}`
              }
            />
            <Column
              sortable
              field="expired_at"
              header={t("expiredAt")}
              body={(rowData) => moment(rowData.expired_at).fromNow()}
            />
            <Column
              body={(rowData) => (
                <ButtonGroup size="sm">
                  <EditDiscount rowData={rowData} refetch={refetch} />
                  <DeleteItem
                    data={{ id: rowData.id }}
                    delFunction={deleteDiscount}
                    refetch={refetch}
                  />
                </ButtonGroup>
              )}
            />
          </DataTable>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Discounts;
