import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Swal from "sweetalert2";

const DeleteItem = ({ data, delFunction, refetch }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const handleDelete = async () => {
    Swal.fire({
      title: t("deleteAlert.title"),
      text: t("deleteAlert.text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#b8932c",
      cancelButtonColor: "#282828",
      cancelButtonText: t("deleteAlert.cancelButtonText"),
      confirmButtonText: t("deleteAlert.confirmButtonText"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await delFunction({
            ...data,
            language: lang,
          }).unwrap();
          if (response.error) {
            console.log({ response });
            let errorMessage =
              response?.error.data.error ?? t("error.serverNotResponding");
            Swal.fire("Error!", errorMessage, "error");
          } else {
            Swal.fire(response?.message, "", "success");
            window.location.reload();
          }
        } catch (err) {
          console.log(err);
          let errorMessage = err?.data?.error ?? t("error.serverNotResponding");
          errorMessage = err?.data?.message ?? errorMessage;
          Swal.fire("Error!", errorMessage, "error");
        }
      }
    });
  };
  return (
    <Button variant="outline-light">
      <i className="fa fa-trash" onClick={() => handleDelete()} />
    </Button>
  );
};

export default DeleteItem;
