import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { formatNumberToLocal, toastError, toastSuccess } from "../../helpers";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { hPlanCalculation } from "../../helpers";

const PurchaseSummary = ({ paymentData, stampDuty }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseData, setPurchaseData] = useState({});
  const navigate = useNavigate();
  const { lang } = useParams();

  const handleConfirmPurchase = () => {
    setIsLoading(true);
    navigate(`/${lang}/payment-success`);
    setIsLoading(false);
  };

  useEffect(() => {
    const {
      disc_code,
      number_of_recurring_payments,
      number_of_initial_payments,
      payment_amount,
      docSplitRequest,
    } = paymentData;

    const data = {
      initialPaymentNumber: number_of_initial_payments,
      recurringPaymentAmount: payment_amount,
      recurringPaymentNumber: number_of_recurring_payments,
      docSplit: docSplitRequest === "Approve" ? true : false,
      discountCode: disc_code,
    };
    const { message, ...result } = hPlanCalculation(data);
    if (message.type === "error") {
      toastError("", t(message.text));
    }
    if (message.type === "success") {
      toastSuccess(t(message.text));
    }
    setPurchaseData(result);
  }, [paymentData, t]);

  return (
    <div className="px-5 py-2">
      <ul className="list-unstyled">
        <li className="d-flex justify-content-between mb-3 text-light">
          <span>{t("recurringAmountPaid")} </span>€
          {formatNumberToLocal(purchaseData.totalRecurringAmount) || 0}
        </li>
        <li className="d-flex justify-content-between mb-3 text-light">
          <span>{t("numberOfInitialPayments2")} </span>
          {purchaseData.initialPaymentNumber || 0}
        </li>
        <li className="d-flex justify-content-between mb-3 text-light">
          <span>{t("numberOfRecurringPayments2")} </span>
          {purchaseData.recurringPaymentNumber || 0}
        </li>
        <li className="d-flex justify-content-between mb-3 text-light">
          <span>{t("paymentAmount")} </span>€
          {purchaseData.recurringPaymentAmount || 0}
        </li>
        <li className="d-flex justify-content-between mb-3 text-light">
          <span>{t("docShareOfTotal")} </span>€
          {formatNumberToLocal(purchaseData.dossierOpeningCharge) || 0}
        </li>
        {purchaseData.docSplit && (
          <li className="d-flex justify-content-between mb-3 text-light">
            <span>{t("docSplitAmount")} </span>€
            {formatNumberToLocal(purchaseData.docSplitAmount) || 0}
          </li>
        )}
        <li className="d-flex justify-content-between mb-3 text-light">
          <span>{t("initialPaymentAmount")} </span>€
          {formatNumberToLocal(purchaseData.initialPaymentAmount) || 0}
        </li>
        {paymentData.disc_code && (
          <li className="d-flex justify-content-between mb-3 text-gold">
            <span>
              <i className="fa fa-gift"></i> {paymentData.disc_code}
            </span>
          </li>
        )}
      </ul>
      <hr />
      <p className="d-flex justify-content-between text-uppercase mb-1">
        <span>{t("price")}</span>€
        {formatNumberToLocal(purchaseData.totalPayment)}
      </p>
      <p className="d-flex justify-content-between text-uppercase mb-1">
        <span>{t("stampDuty")}</span>€{formatNumberToLocal(stampDuty)}
      </p>
      <hr />
      <p className="d-flex justify-content-between text-uppercase mb-5">
        <span>{t("totalPayment")}</span>€
        {formatNumberToLocal(purchaseData.totalPayment + stampDuty) || 0}
      </p>
      <Button
        variant="primary-outline"
        className="w-100 d-flex align-items-center justify-content-center"
        onClick={handleConfirmPurchase}
      >
        {t("confirmPurchase")}
        {isLoading && <Spinner size="sm" className="ms-2" animation="grow" />}
      </Button>
    </div>
  );
};

export default PurchaseSummary;
