import React, { useState } from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

const WireTransfer = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const [copy, setCopy] = useState("");

  return (
    <Card className="shadow p-3" bg="dark1 text-light">
      <Card.Body>
        <Form.Label>{t("payment.accountName")}:</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control disabled={true} value={"accountName"} />
          <CopyToClipboard text={"accountName"} onCopy={() => setCopy()}>
            <Button variant="outline-secondary">
              <i className="fa fa-copy"></i>
            </Button>
          </CopyToClipboard>
        </InputGroup>

        <Form.Label>{t("payment.accountNumber")}:</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control disabled={true} value={"accountNumber"} />
          <CopyToClipboard text={"accountNumber"} onCopy={() => setCopy()}>
            <Button variant="outline-secondary">
              <i className="fa fa-copy"></i>
            </Button>
          </CopyToClipboard>
        </InputGroup>

        <Form.Label>{t("payment.IBAN")}:</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control disabled={true} value={"iban"} />
          <CopyToClipboard text={"iban"} onCopy={() => setCopy()}>
            <Button variant="outline-secondary">
              <i className="fa fa-copy"></i>
            </Button>
          </CopyToClipboard>
        </InputGroup>

        <Form.Label>{t("payment.SWIFT/BIC")}:</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control disabled={true} value={"swift"} />
          <CopyToClipboard text={"swift"} onCopy={() => setCopy()}>
            <Button variant="outline-secondary">
              <i className="fa fa-copy"></i>
            </Button>
          </CopyToClipboard>
        </InputGroup>
      </Card.Body>
    </Card>
  );
};

export default WireTransfer;
