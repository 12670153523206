import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import SetPageTitle from "../../../components/SetPageTitle";
import BonusChart from "./BonusChart";
import { formatNumberToLocal } from "../../../helpers";
import { useTranslation } from "react-i18next";

const tableData = [
  {
    level: 1,
    doc: 1000000,
    maxDoc: 50000,
  },
  {
    level: 2,
    doc: 5000000,
    maxDoc: 250000,
  },
  {
    level: 3,
    doc: 10000000,
    maxDoc: 500000,
  },
  {
    level: 4,
    doc: 20000000,
    maxDoc: 1000000,
  },
  {
    level: 5,
    doc: 30000000,
    maxDoc: 1500000,
  },
];

const Career = () => {
  const { t } = useTranslation();
  return (
    <>
      <Card bg="dark1 text-light mb-3">
        <Card.Header>
          <SetPageTitle title="career" />
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className="align-self-end">
              <Table striped bordered hover>
                <thead>
                <tr>
                  <th className="text-light">{t("careerLevel")}</th>
                  <th className="text-light">{t("totalVolumeDOC")}</th>
                  <th className="text-light">{t("maxNumberDOCs")}</th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((data, i) => (
                  <tr key={i}>
                    <td className="text-light">{data.level}</td>
                    <td className="text-light">{formatNumberToLocal(data.doc)}</td>
                    <td className="text-light">{formatNumberToLocal(data.maxDoc)}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
            <Col>
              <h6 className="text-center">
                {t("teamManagerCompensationPlan")}
              </h6>
              <BonusChart />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card bg="dark1 text-light mb-3">
        <Card.Header>{t("currentCareerLevel")}</Card.Header>
        <Card.Body>
          <h6 className="mt-3">{t("directLines")}</h6>
          <Table className="responsive career">
            <thead>
              <tr>
                <th></th>
                <th>{t('name')}</th>
                <th>{t('name')}</th>
                <th>{t('name')}</th>
                <th>{t('name')}</th>
                <th>{t('name')}</th>
                <th>{t('name')}</th>
                <th>DOC Pers</th>
                <th>{t('total')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-success">{t('DOCVolume')} <br/>{t('currentMonth')}</td>
                <td>5600</td>
                <td>2200</td>
                <td>1800</td>
                <td>1200</td>
                <td>0</td>
                <td>0</td>
                <td className="tbl-bg-dark px-0"><div>2400</div></td>
                <td>13200</td>
                <td className="text-success">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr className="add-space">
                <td className="bg-success">{t('ProgressiveDOCVolume')} <br/>{t('with50Rule')}</td>
                <td>11500</td>
                <td>22000</td>
                <td>28000</td>
                <td>18400</td>
                <td>8000</td>
                <td>2400</td>
                <td className="tbl-bg-dark px-0"><div>4000</div></td>
                <td>94300</td>
                <td className="text-danger">
                  <i className="fa fa-times" />
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Career;
