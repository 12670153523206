import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useForgotPasswordMutation } from "../app/features/apiSlice";
import { swalError, swalSuccess } from "../helpers";
import { Form, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthLogo from "../components/AuthLogo";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const [inputData, setInputData] = useState({
    email: "",
    language: lang,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value.trim() }));
  };

  const [forgotPassword] = useForgotPasswordMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await forgotPassword(inputData).unwrap();
      if (response?.error) {
        swalError(response, t("error.serverNotResponding"));
      } else {
        swalSuccess(response?.msg)
      }
    } catch (err) {
      swalError(err, t("error.serverNotResponding"))
    }
  };

  return (
    <Col lg="4" md="8" sm="12">
      <AuthLogo title="forgotPassword" />
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>{t("email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("emailPlaceholder")}
            name="email"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="btn-block btn-primary mt-3 mb-1 py-2"
        >
          {t("submit")}
        </Button>
      </Form>

      <p className="m-0 mt-3 text-center">
        {t("haveAccount")}?{" "}
        <Link to={`/${lang}/login`} className="text-gold">
          {t("login")}
        </Link>
      </p>
      <p className="text-center">
        {t("noAccount")}{" "}
        <Link to={`/${lang}/signup`} className="text-gold">
          {t("noAccountLink")}
        </Link>
      </p>
    </Col>
  );
};

export default ForgotPassword;
