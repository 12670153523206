import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Button, Accordion, Form, Col, Spinner } from "react-bootstrap";
import * as formik from "formik";
import * as Yup from "yup";
import Credentials from "../components/signup-form/Credentials";
import PersonalData from "../components/signup-form/PersonalData";
import Residence from "../components/signup-form/Residence";
import Domicile from "../components/signup-form/Domicile";
import AuthLogo from "../components/AuthLogo";
import { Link } from "react-router-dom";
import {
  useCheckAffiliateCodeMutation,
  useCheckEmailExistsMutation,
  useRegisterMutation,
} from "../app/features/apiSlice";
import { getReferralFromUrl, swalError, swalSuccess } from "../helpers";
import { isValidPhoneNumber } from "react-phone-number-input";

function SignUp() {
  const { Formik } = formik;
  const { t } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [validEmail, setValidEmail] = useState({
    status: false,
    value: "",
  });
  const [validCode, setValidCode] = useState({
    status: false,
    value: "",
  });
  const referralCode = getReferralFromUrl();
  const initialValues = {
    collaborator: null,
    email: "",
    password: "",
    confirmPassword: "",
    type: "",
    taxCode: "",
    pec: "",
    affiliateCode: referralCode ?? "",
    vatNumber: "",
    name: "",
    surName: "",
    dateOfBirth: "",
    sex: "",
    placeOfBirth: "",
    provinceOfBirth: "",
    mobilePhone: "",
    telephone: "",
    address: "",
    city: "",
    province: "",
    postcode: "",
    addressHome: "",
    cityHome: "",
    provinceHome: "",
    postcodeHome: "",
    language: lang,
    roleId: referralCode ? "10" : "9",
  };

  const [checkEmailExists] = useCheckEmailExistsMutation();
  const checkEmailExistence = async (email) => {
    try {
      const response = await checkEmailExists({ email });
      return response.data.message === "exists" ? true : false;
    } catch (err) {
      console.log(err);
    }
  };

  const [checkAffiliateCode] = useCheckAffiliateCodeMutation();
  const validateAffiliateCode = async (affiliate_code) => {
    try {
      const response = await checkAffiliateCode({ affiliate_code });
      return response.data.message === "exists" ? true : false;
    } catch (err) {
      console.log(err);
    }
  };

  const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/i;
  // const lettersNoSpaces = /^[A-Za-zÀ-ÿ]+$/;
  const lettersWithSpaces = /^[a-zA-ZÀ-ÿ\s]+$/;
  const alphanumericNoSpaces = /^[0-9a-zA-Z]+$/;
  const alphanumericWithSpaces = /^[0-9a-zA-Z\s]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("invalidEmail")
      .required("emailRequired")
      .test("unique-email", "emailAlreadyExists", async (value) => {
        if (!value) return true; // Skip validation if value is empty
        if (emailRegex.test(value)) {
          if (validEmail.value === value) {
            return !validEmail.status;
          } else {
            const emailExists = await checkEmailExistence(value);
            setValidEmail({ status: emailExists, value });
            return !emailExists;
          }
        }
      }),
    password: Yup.string()
      .required("passwordRequired")
      .matches(passwordRegExp, "invalidPassword"),
    confirmPassword: Yup.string()
      .required("confirmPasswordRequired")
      .oneOf([Yup.ref("password"), null], "passwordMismatch"),
    type: Yup.string().required("typeRequired"),
    vatNumber: Yup.string().when("type", {
      is: "COMPANY",
      then: () => Yup.string().required("vatNumberRequired"),
    }),
    taxCode: Yup.string()
      .required("taxCodeRequired")
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
    // pec: Yup.string().required("pecRequired"),
    affiliateCode: Yup.string()
      .test("affiliate-code", "inValidAffiliateCode", async function (value) {
        if (!value) return true; // Skip validation if value is empty
        if (value.length > 8) {
          if (validCode.value === value) {
            return validCode.status;
          } else {
            const isExists = await validateAffiliateCode(value);
            setValidCode({ status: isExists, value });
            return isExists;
          }
        } else {
          return false;
        }
      })
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
    // affiliateCode: Yup.string().when("roleId", {
    //   is: "10",
    //   then: () =>
    //     Yup.string()
    //       .required("affiliateCodeRequired")
    //       .test(
    //         "affiliate-code",
    //         "inValidAffiliateCode",
    //         async function (value) {
    //           if (!value) return true; // Skip validation if value is empty
    //           if (value.length > 8) {
    //             if (validCode.value === value) {
    //               return validCode.status;
    //             } else {
    //               const isExists = await validateAffiliateCode(value);
    //               setValidCode({ status: isExists, value });
    //               return isExists;
    //             }
    //           } else {
    //             return false;
    //           }
    //         }
    //       )
    //       .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
    // }),
    name: Yup.string()
      .required("firstnameRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    surName: Yup.string()
      .required("lastnameRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    dateOfBirth: Yup.string()
      .required("dobRequired")
      .test("is-adult", "dobUnderAge", function (value) {
        const currentDate = new Date();
        const dob = new Date(value);
        const minAgeDate = new Date(
          currentDate.getFullYear() - 18,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return dob <= minAgeDate;
      }),
    sex: Yup.string().required("sexRequired"),
    placeOfBirth: Yup.string().required("birthPlaceRequired"),
    provinceOfBirth: Yup.string().required("birthCountryRequired"),
    mobilePhone: Yup.string()
      .test("is-valid-phone", "invalidPhoneNumber", (value) => {
        return !value || isValidPhoneNumber(value);
      })
      .required("mobileNumberRequired"),
    address: Yup.string()
      .required("residenceAddressRequired")
      .matches(alphanumericWithSpaces, "specialCharacterNotAllowed"),
    city: Yup.string()
      .required("residenceCityRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    province: Yup.string().required("residenceCountryRequired"),
    postcode: Yup.string()
      .required("residencePostcodeRequired")
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
    addressHome: Yup.string()
      .required("domicileAddressRequired")
      .matches(alphanumericWithSpaces, "specialCharacterNotAllowed"),
    cityHome: Yup.string()
      .required("domicileCityRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    provinceHome: Yup.string().required("domicileCountryRequired"),
    postcodeHome: Yup.string()
      .required("domicilePostcodeRequired")
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
  });

  const [register] = useRegisterMutation();
  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await register(values);
      if (response?.error) {
        swalError(response, t("error.serverNotResponding"));
      } else {
        swalSuccess(response?.data?.msg);
        setTimeout(() => {
          navigate(`/${lang}/login`);
        }, 3000);
      }
    } catch (err) {
      swalError(err, t("error.serverNotResponding"));
    }
    setIsLoading(false);
  };

  return (
    <Col lg="6" md="10" sm="12">
      <AuthLogo title="registerToAccess" />
      <Formik
        validationSchema={schema}
        onSubmit={(values) => handleSubmit(values)}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur, //Validation is working on onBlur
          values,
          touched,
          errors,
          setFieldValue,
          setValues,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <small>Step 1</small>
                  <span>{t("signup.credentials")}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <Credentials
                    formik={{
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <small>Step 2</small>
                  <span>{t("signup.personalData")}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <PersonalData
                    formik={{
                      values,
                      handleChange,
                      touched,
                      errors,
                      setFieldValue,
                      handleBlur,
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <small>Step 3</small>
                  <span>{t("signup.residence")}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <Residence
                    formik={{
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <small>Step 4</small>
                  <span>{t("signup.domicile")}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <Domicile
                    formik={{
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      setValues,
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Button variant="primary" type="submit" className="mt-5">
              {t("register")}
              {isLoading && (
                <Spinner size="sm" className="ms-2" animation="grow" />
              )}
            </Button>
            <p className="m-0 mt-3 text-center">
              {t("haveAccount")}?{" "}
              <Link to={`/${lang}/login`} className="text-gold">
                {t("login")}
              </Link>
            </p>
          </Form>
        )}
      </Formik>
    </Col>
  );
}

export default SignUp;
