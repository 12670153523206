import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../app/features/DataSlice";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import WireTransfer from "../../components/paymentMethods/WireTransfer";
import CreditCardTransfer from "../../components/paymentMethods/CreditCardTransfer";
import PostalTransfer from "../../components/paymentMethods/PostalTransfer";
import { useNavigate, useParams } from "react-router";
import PurchaseSummary from "../../components/HeliorPlan/PurchaseSummary";
import {
  useGetProfileQuery,
  useGetPlanDetailMutation,
  useGetHOneDetailMutation,
} from "../../app/features/apiSlice";
import Loader from "../../components/Loader";
import { toastError } from "../../helpers";
import HOneConfirmCard from "../../components/HeliorOne/HOneConfirmCard";
// import Error from "../components/Error";

const PurchaseConfirmation = () => {
  const pageTitle = "purchaseConfirmation";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang, planType } = useParams();
  const [paymentType, setPaymentType] = useState();

  const sessionId = useRef(Date.now()).current;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [stampDuty, setStampDuty] = useState(0)
  const { data: profile, isSuccess: isProfileSuccess } = useGetProfileQuery({
    sessionId,
  });

  const [getPlanDetail] = useGetPlanDetailMutation();
  const [getHOneDetail] = useGetHOneDetailMutation();
  useEffect(() => {
    if (isProfileSuccess) {
      const getHOnePlanDetail = async (contractCode) => {
        setIsLoading(true);
        try {
          const response = await getHOneDetail(contractCode);
          if (response?.error) {
            toastError(response, t("error.serverNotResponding"));
          } else {
            setData(response?.data);
            setStampDuty(response?.data?.stamp_duty);
            setIsLoading(false);
          }
        } catch (err) {
          toastError(err, t("error.serverNotResponding"));
        }
      };

      const getHPlanDetail = async (contractCode) => {
        setIsLoading(true);
        try {
          const response = await getPlanDetail(contractCode);
          if (response?.error) {
            toastError(response, t("error.serverNotResponding"));
          } else {
            const res = response?.data?.data
            const payment = res?.payment
            setData(res);
            const value = payment.find(item => item.stamp_duty > 0)?.stamp_duty || 0;
            setStampDuty(value);
            setPaymentType(payment[0]?.payment_method);
            setIsLoading(false);
          }
        } catch (err) {
          toastError(err, t("error.serverNotResponding"));
        }
      };

      if (planType === "helior-plan") {
        const plans = profile?.plan;
        const currActivePlan = plans.filter((plan) => plan.status === "Active");
        if (currActivePlan.length > 0) {
          getHPlanDetail(currActivePlan[0]?.contract_code);
        } else {
          navigate(`/${lang}/products`);
        }
      } else if (planType === "helior-one") {
        const plans = profile?.heliorOne;
        const currActivePlan = plans.filter((plan) => plan.status === "Active");
        if (currActivePlan.length > 0) {
          getHOnePlanDetail(currActivePlan[0]?.contract_code);
          setPaymentType(currActivePlan[0]?.payment_method);
        } else {
          navigate(`/${lang}/products`);
        }
      } else {
        navigate(`/${lang}/products`);
      }
    }
  }, [
    isProfileSuccess,
    profile,
    lang,
    navigate,
    planType,
    t,
    getHOneDetail,
    getPlanDetail,
  ]);

  const handleChangePaymentMethod = (e) => {
    const { value } = e.target;
    setPaymentType(value);
  };

  useEffect(() => {
    dispatch(setPageTitle(t(`${pageTitle}`)));
    // eslint-disable-next-line
  }, []);

  if (isLoading) return <Loader />;
  return (
    <Row className="my-5">
      <Col md="12">
        <div className="mb-5">
          <Form.Label>{t("paymentMethod")}</Form.Label>
          <Form.Select
            name="paymentMethod"
            style={{ width: "270px" }}
            value={paymentType}
            onChange={(e) => handleChangePaymentMethod(e)}
          >
            <option value="" disabled>
              {t("select")}
            </option>
            <option value="Postal">{t("postal")}</option>
            <option value="Wire Transfer">{t("wireTransfer")}</option>
            <option value="Credit Card">{t("creditCard")}</option>
          </Form.Select>
        </div>
      </Col>
      <Col md="6">
        {paymentType === "Wire Transfer" && <WireTransfer />}
        {paymentType === "Postal" && <PostalTransfer />}
        {paymentType === "Credit Card" && <CreditCardTransfer />}
      </Col>
      {planType === "helior-plan" && (
        <Col md="6">{data && <PurchaseSummary paymentData={data} stampDuty={stampDuty}/>}</Col>
      )}
      {planType === "helior-one" && (
        <Col md="6">{data && <HOneConfirmCard paymentData={data} stampDuty={stampDuty}/>}</Col>
      )}
    </Row>
  );
};

export default PurchaseConfirmation;
