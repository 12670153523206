import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Badge,
  // Badge,
  Card,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setPageTitle, setUserData } from "../../app/features/DataSlice";
import bgGold from "../../assets/images/bg-gold.jpg";
import { useGetProfileQuery } from "../../app/features/apiSlice";
import Loader from "../../components/Loader";
import Error from "../../components/Error";
import { Link, useParams } from "react-router-dom";
import { formatNumberToLocal } from "../../helpers";

const ClientDashboard = () => {
  const dispath = useDispatch();
  const { t } = useTranslation();
  const { lang } = useParams();
  const sessionId = useRef(Date.now()).current;
  const dispatch = useDispatch();
  const [currPlan, setCurrPlan] = useState({});
  const [currHOnePlan, setCurrHOnePlan] = useState({});
  const { data, isSuccess, isLoading, isError, error } = useGetProfileQuery({
    sessionId,
  });

  useEffect(() => {
    if (isSuccess) {
      const user = data?.user;
      dispatch(setUserData(user));
      const plans = data?.plan;
      const hOnePlans = data?.heliorOne;
      if (plans.length > 0) {
        setCurrPlan(plans[0]);
      }
      if (hOnePlans.length > 0) {
        setCurrHOnePlan(hOnePlans[0]);
      }
    }
    // eslint-disable-next-line
  }, [isSuccess, data]);

  useEffect(() => {
    dispath(setPageTitle("Dashboard"));
  });

  if (isLoading) return <Loader />;
  if (isError) return <Error error={error} />;

  return (
    <div className="my-3">
      {isSuccess && (
        <>
          <h4 className="heading mb-3 text-capitalize">
            {t("welcome")} {data?.user?.first_name} {data?.user?.last_name}
          </h4>
          {currHOnePlan?.contract_date && (
            <Card className="mb-3 p-3" bg="dark1">
              <Card.Title className="d-flex justify-content-between align-items-center">
                <div className="text-gold">Helior One®</div>
                <Link
                  to={`/${lang}/plan/helior-one/${currHOnePlan.contract_code}`}
                  className="btn btn-primary-outline"
                >
                  {t("seeAllData")}
                </Link>
              </Card.Title>
              <Card.Body className="px-0 py-4">
                <ul className="p-0 columns-2">
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("contractDate")}</span>
                    <span>{currHOnePlan?.contract_date?.split("T")[0]}</span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("contractNumber")}</span>
                    <span>{currHOnePlan.contract_code}</span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("durationOfHousing")}</span>
                    <span>
                      {currHOnePlan.duration} {t("years")}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("gramsPurchased")}</span>
                    <span>{currHOnePlan.total_gr || 0}gr</span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("totalPayment")}</span>
                    <span>
                      €{formatNumberToLocal(currHOnePlan.total_payment) || 0}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("totalGuarantee")}</span>
                    <span>
                      €
                      {formatNumberToLocal(
                        currHOnePlan.total_payment_with_margin
                      ) || 0}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("totalBankTransfer")}</span>
                    <span>
                      €{formatNumberToLocal(currHOnePlan.bankTransfer) || 0}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("status")}</span>
                    <span>
                      <Badge
                        bg={
                          currHOnePlan.status === "Active" ? "gold" : "danger"
                        }
                      >
                        {currHOnePlan?.status === "Active"
                          ? t("active")
                          : t("inactive")}
                      </Badge>
                    </span>
                  </li>
                  {/* <li className="d-flex justify-content-between mb-3">
                  <span>{t("imStaying")}</span>
                  <span>-</span>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("ingotsSerialNumbers")}</span>
                  <span>-</span>
                </li> */}
                </ul>
              </Card.Body>
            </Card>
          )}

          {currPlan?.contract_code ? (
            <Card className="mb-3 p-3" bg="dark1">
              <Card.Title className="d-flex justify-content-between align-items-center">
                <div className="text-gold">Helior Plan®</div>
                <Link
                  to={`/${lang}/plan/helior-plan/${currPlan?.contract_code}`}
                  className="btn btn-primary-outline"
                >
                  {t("seeAllData")}
                </Link>
              </Card.Title>
              <Card.Body className="px-0 py-4">
                <ul className="p-0 columns-2">
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("contractDate")}</span>
                    <span>{currPlan?.contract_date?.split("T")[0]}</span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("contractNumber")}</span>
                    <span>{currPlan?.contract_code}</span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("periodicity")}</span>
                    <span>{t(`${currPlan?.periodicity_of_amount}`)}</span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("totalPayment")}</span>
                    <span>
                      €{formatNumberToLocal(currPlan?.recurring_amount_paid)}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("docFeePaid")}</span>
                    <span>€{formatNumberToLocal(currPlan?.doc_share)}</span>
                  </li>
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("numberOfRecurringInstallments")}</span>
                    <span>{currPlan?.number_of_recurring_payments}</span>
                  </li>
                  {/* <li className="d-flex justify-content-between mb-3">
                    <span>{t("amountOfRecurringPayments")}</span>
                    <span>
                      €{formatNumberToLocal(currPlan?.payment_amount)}
                    </span>
                  </li> */}
                  {/* <li className="d-flex justify-content-between mb-3">
                    <span>{t("totalInitialDeposit")}</span>
                    <span>€{formatNumberToLocal(currPlan?.total_payment)}</span>
                  </li> */}
                  {/* <li className="d-flex justify-content-between mb-3">
                    <span>{t("numberOfInitialPayments")}</span>
                    <span>{currPlan?.number_of_initial_payments}</span>
                  </li> */}
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("ingotCutting")}</span>
                    <span>{currPlan?.ingot_gr}</span>
                  </li>
                  {currPlan?.docSplitRequest && (
                    <li className="d-flex justify-content-between mb-3">
                      <span>{t("docSplitStatus")}</span>
                      <span>{t(`${currPlan?.docSplitRequest}`)}</span>
                    </li>
                  )}
                  <li className="d-flex justify-content-between mb-3">
                    <span>{t("status")}</span>
                    <span>
                      <Badge
                        bg={currPlan?.status === "Active" ? "gold" : "danger"}
                      >
                        {currPlan?.status === "Active"
                          ? t("active")
                          : t("inactive")}
                      </Badge>
                    </span>
                  </li>
                </ul>
              </Card.Body>
            </Card>
          ) : (
            <Card className="bg-dark text-white">
              <Card.Img src={bgGold} alt="Card image" />
              <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center">
                <Card.Title>{t("buyNewPlan")}</Card.Title>
                <Card.Text>{t("accessProductsArea")}</Card.Text>
                <Link
                  to={`/${lang}/products`}
                  className="text-light mt-3 btn btn-primary-outline"
                >
                  {t("discoverAllProducts")}
                </Link>
              </Card.ImgOverlay>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default ClientDashboard;
