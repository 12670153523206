import React from "react";
import { Image } from "react-bootstrap";

const Performer = ({ user }) => {
  return (
    <div className="d-flex align-items-center justify-content-between mb-2 text-light">
      <div className="d-flex align-items-center">
        <Image src="/man.png" alt="Man" height={30} />
        <b className="ms-3">{user?.user?.first_name} {user?.user?.last_name}</b>
      </div>
      <p className="mb-0">{user?.referral_code}</p>
    </div>
  );
};

export default Performer;
