import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../app/features/DataSlice";
import { useGetProfileQuery } from "../../app/features/apiSlice";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import Loader from "../../components/Loader";
import Error from "../../components/Error";
import HeliorPlanDetails from "../../components/HeliorPlan/HeliorPlanDetails";
import HOneDetails from "../../components/HeliorOne/HOneDetails";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import "moment/locale/it";
import UserCard from "../../components/UserCard";
import PersonalDetails from "../../components/profileForm/PersonalDetails";
import BankDetails from "../../components/profileForm/BankDetails";
import ContactDetails from "../../components/profileForm/ContactDetails";

const Profile = () => {
  const pageTitle = "profile";
  const { t } = useTranslation();
  const { lang } = useParams();
  lang === "it" ? moment.locale("it") : moment.locale("en");
  const dispatch = useDispatch();
  const sessionId = useRef(Date.now()).current;
  const { data, isLoading, isSuccess, isError, error, refetch } =
    useGetProfileQuery({
      sessionId,
    });

  useEffect(() => {
    dispatch(setPageTitle(t(`${pageTitle}`)));
    // eslint-disable-next-line
  }, [t]);

  return (
    <Row className="my-5">
      {isLoading && <Loader />}
      {isError && <Error error={error} />}
      {isSuccess && (
        <>
          {/* <Col md="12" sm="12" className="text-end">
            <Link
              variant="icon-button"
              to={`/${lang}/profile/update/${data?.user?.id}`}
            >
              <i className="fa fa-pen mx-3 text-light" />
            </Link>
          </Col> */}
          <Col lg={3}>
            <UserCard userData={data} commission={data?.commission} />
          </Col>
          <Col lg={9}>
            <Card bg="dark1 text-light h-100">
              <Card.Body>
                <div className="mb-5">
                  <h6>{t('careerLevel')}</h6>
                  <p>GOLD ADVISOR / TEAM MANAGER STEEL, IRON, BRONZE...</p>
                </div>
                <h6 className="text-uppercase">{t("myTeam")}</h6>
                <ul className="list-unstyled">
                  <li className="mb-2">{t("direct")}: {data?.directCollaboratorCount}</li>
                  <li className="mb-2">{t("first2Levels")}: {data?.firstTwoLevelCollaboratorCount}</li>
                  <li className="mb-2">{t("totalTeam")}: {data?.totalTeamCount}</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12" className="mt-3">
            <Card bg="dark1 text-light">
              <Card.Body>
                <Tab.Container defaultActiveKey="first">
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="first">{t('personalDetails')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">{t('bankDetails')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">{t('contactDetails')}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <PersonalDetails data={data} refetch={refetch} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <BankDetails />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <ContactDetails data={data} refetch={refetch} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
          {data?.plan.length > 0 && (
            <Col className="mt-3" md="12">
              <HeliorPlanDetails plans={data?.plan} />
            </Col>
          )}
          {data?.heliorOne.length > 0 && (
            <Col className="mt-3" md="12">
              <HOneDetails plans={data?.heliorOne} />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default Profile;
