import { Container, Row } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import SmHeader from "./_includes/SmHeader";
import Footer from "./_includes/Footer";
import { useSelector } from "react-redux";
import { selectIsLogged } from "../app/features/authSlice";

const AuthLayout = () => {
  const { lang } = useParams();
  const isLoggedIn = useSelector(selectIsLogged);
  const navigate = useNavigate();

  if (isLoggedIn) {
    // return navigate(-1)
    return navigate(`/${lang}/dashboard`);
  }

  return (
    <>
      <Container fluid className="auth">
        <Row className="min-vh-100 align-items-center justify-content-center">
          <SmHeader />
          <Outlet />
        </Row>
      </Container>
      <Footer />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default AuthLayout;
