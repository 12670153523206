import React, { useEffect, useLayoutEffect, useRef } from "react";
import { OrgChart } from "d3-org-chart";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

export const OrgChartComponent = ({ data, highlightedId }) => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const d3Container = useRef(null);
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    if (data && d3Container.current) {
      if (!chartRef.current) {
        chartRef.current = new OrgChart();
      }
      const chart = chartRef.current;

      chart
        .container(d3Container.current)
        .data(data)
        .rootMargin(100)
        .nodeWidth((d) => 210)
        .nodeHeight((d) => 90)
        .childrenMargin((d) => 50)
        // .compactMarginBetween((d) => 75)
        .compactMarginPair((d) => 50)
        .nodeContent(function (d, i, arr, state) {
          const imageDim = 50;
          const isHighlight = d.data.id === highlightedId ? true : false;
          return `
            <div style="background-color:#000; position:absolute;width:${d.width}px;height:${d.height}px;border:1px solid ${d.data.status === "InActive" ? "red" : "#B8932C"}; ${isHighlight ? 'border: 3px solid yellow' : ''}">
              <img src="/man.png" style="position:absolute;margin-top:-20px;margin-left:${d.width / 2 - imageDim / 2}px;border-radius:100px;width:${imageDim}px;height:${imageDim}px;" />
              <div class="card" style="top:35px;position:absolute;width:${d.width}px;background-color:transparent;">
                <div style="text-align:center;color:#fff;font-weight:bold;font-size:14px">
                  <a href="${`/${lang}/collaborator/${d.data.id}`}" style="color:#B8932C">${d.data.name}</a>
                </div>
                <div style="text-align:center;color:#fff;font-size:12px">${d.data.email}</div>
                <div style="text-align:center;color:#fff;font-size:12px">${t('sidebar.clients')}: ${d.data.clients}</div>
              </div>
            </div>
          `;
        })
        // Comment this line to remove all node in one go
        // .expandAll().fit()
        .render();
    }
  }, [data, lang, t, highlightedId]);

  useEffect(() => {
    if (highlightedId) {
      const chart = chartRef.current;
      chart.clearHighlighting()
      chart.setCentered(highlightedId).render().fit();
      // chart.setUpToTheRootHighlighted(highlightedId).render().fit()
    }
  }, [highlightedId]);

  return (
    <div>
      <div ref={d3Container} />
    </div>
  );
};
