import React from "react";
import { useTranslation } from "react-i18next";

const AuthLogo = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div className="my-5">
      <img
        alt=""
        src="/helior-logo-light.svg"
        width="220"
        className="d-block m-auto mb-3"
      />
      <h4>{t(`${title}`)}</h4>
    </div>
  );
};

export default AuthLogo;
