import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, Button } from "react-bootstrap";

const BankDetails = () => {
  const { t } = useTranslation();
  return (
    <Form noValidate className="add-inner-form">
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
        <Form.Label column sm={2}>
          {t("payment.accountName")} *
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="text"
            name="accountName"
            placeholder={t("payment.accountName")}
            size="sm"
            disabled
          />
          {/* <Form.Control.Feedback type="invalid">
                {t(`error.${errors.name}`)}
              </Form.Control.Feedback> */}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
        <Form.Label column sm={2}>
          {t("payment.accountNumber")} *
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="text"
            name="accountNumber"
            placeholder={t("payment.accountNumber")}
            size="sm"
            disabled
          />
          {/* <Form.Control.Feedback type="invalid">
                {t(`error.${errors.accountNumber}`)}
              </Form.Control.Feedback> */}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
        <Form.Label column sm={2}>
          {t("payment.IBAN")} *
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="text"
            name="iban"
            placeholder={t("payment.iban")}
            size="sm"
            disabled
          />
          {/* <Form.Control.Feedback type="invalid">
            {t(`error.${errors.iban}`)}
          </Form.Control.Feedback> */}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
        <Form.Label column sm={2}>
          {t("payment.SWIFT/BIC")} *
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="text"
            name="accountName"
            placeholder={t("payment.SWIFT/BIC")}
            size="sm"
            disabled
          />
          {/* <Form.Control.Feedback type="invalid">
            {t(`error.${errors.SWIFT/BIC}`)}
          </Form.Control.Feedback> */}
        </Col>
      </Form.Group>
      <Button variant="primary-outline" type="submit" disabled>
        {t("update")}
      </Button>
    </Form>
  );
};

export default BankDetails;
