import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../app/features/DataSlice";
import HeliorPurchaseForm from "../../../components/HeliorPlan/HeliorPurchaseForm";
import HeliorPurchaseSummary from "../../../components/HeliorPlan/HeliorPurchaseSummary";
import { useGetProfileQuery } from "../../../app/features/apiSlice";
import { useNavigate, useParams } from "react-router";
import toast from "react-hot-toast";
import { toastStyle } from "../../../helpers";
import { useTranslation } from "react-i18next";

const HeliorPlan = () => {
  const sessionId = useRef(Date.now()).current;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = useParams();
  const { data, isSuccess } = useGetProfileQuery({ sessionId });

  useEffect(() => {
    if (isSuccess) {
      const plans = data?.plan;
      const isActive = plans.some((user) => user.status === "Active");
      if (isActive) {
        toast(t("planAlreadyPurchased"), {
          style: toastStyle,
          icon: "⚠️",
        });
        navigate(`/${lang}/dashboard`);
      }
    }
  }, [data, isSuccess, lang, navigate, t]);

  const pageTitle = "Helior Plan";
  const dispatch = useDispatch();

  const initialPurchaseData = {
    paymentPeriodicity: "",
    recurringPaymentAmount: 0,
    recurringPaymentNumber: 0,
    initialPaymentNumber: 0,
    initialPaymentAmount: 0,
    acceptTNC: false,
    paymentMethod: "",
    totalRecurringAmount: "",
    dossierOpeningCharge: "",
    totalPayment: 0,
    docSplit: false,
    docSplitAmount: 0,
    ingot: "50gr",
    discountCode: "",
    appliedCode: "",
  };

  const [purchaseData, setPurchaseData] = useState(initialPurchaseData);
  const [formData, setformData] = useState({
    type: "",
    recurringAmount: [],
    minRecurringPaymentNumber: 0,
    maxRecurringPaymentNumber: 0,
  });

  const resetPurchaseData = () => {
    setPurchaseData(initialPurchaseData);
  };

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mt-3">
      <h4 className="heading mb-3">{pageTitle}</h4>
      <Row className="my-5">
        <Col lg={7}>
          <Card bg="dark1 text-light">
            <Card.Body>
              <HeliorPurchaseForm
                purchaseData={purchaseData}
                setPurchaseData={setPurchaseData}
                resetPurchaseData={resetPurchaseData}
                formData={formData}
                setformData={setformData}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={5} className="position-relative">
          {/* <Card bg="dark1 text-light" className="position-sticky sticky-top"> */}
          <Card bg="dark1 text-light mb-3">
            <Card.Body>
              <HeliorPurchaseSummary
                purchaseData={purchaseData}
                setPurchaseData={setPurchaseData}
                formData={formData}
              />
            </Card.Body>
          </Card>
          <Form.Text id="passwordHelpBlock" className="text-light">
            <b>{t("docDiscountNote")}:</b>
            <br />- {t("docDiscountNote1")}
            <br />- {t("docDiscountNote2")}
            <br />- {t("docDiscountNote3")}
          </Form.Text>
        </Col>
      </Row>
    </div>
  );
};

export default HeliorPlan;
