import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Tabs, Tab } from "react-bootstrap";
import { formatNumberToLocal } from "../../helpers";

const IncomeDOC = ({ size, data }) => {
  const { t } = useTranslation();
  return (
    <Col md={size} className="mb-3">
      <Card bg="dark1" className="h-100">
        <Card.Header className="text-light text-uppercase">
          {t("incomeNdoc")}
        </Card.Header>
        <Card.Body className="text-light">
          <Tabs defaultActiveKey="income" className="mb-4">
            <Tab eventKey="income" title={t("income")}>
              <ul className="list-unstyled">
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("direct")}</span>
                  <span>{formatNumberToLocal(data?.data?.dcCommission || data?.data?.directCommission)}€</span>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("first2Levels")}</span>
                  <span>{formatNumberToLocal(data?.data?.dcOverCommission || data?.data?.firstTwoLevelCommission)}€</span>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("poolBonus")}</span>
                  <span>{formatNumberToLocal(data?.data?.poolBonus)}€</span>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("teamManager")}</span>
                  <span>{formatNumberToLocal(data?.data?.teamManagerCommission)}€</span>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("teamManagerPool")}</span>
                  <span>{formatNumberToLocal(data?.data?.teamManagerPool)}€</span>
                </li>
              </ul>
            </Tab>
            <Tab eventKey="doc" title="DOC">
              <ul className="list-unstyled">
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("direct")}</span>
                  <span>{formatNumberToLocal(data?.data?.doc?.directDoc)}€</span>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("first2Levels")}</span>
                  <span>{formatNumberToLocal(data?.data?.doc?.firstTwoLevelDoc)}€</span>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("poolBonus")}</span>
                  <span>{formatNumberToLocal(data?.data?.poolBonus)}€</span>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("teamManager")}</span>
                  <span>{formatNumberToLocal(data?.data?.doc?.teamManagerDoc)}€</span>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <span>{t("teamManagerPool")}</span>
                  <span>{formatNumberToLocal(data?.data?.teamManagerPool)}€</span>
                </li>
              </ul>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default IncomeDOC;
