import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import productImg from "../../../assets/images/product-image.svg";
import {
  useGetHeliorOneQuery,
  useGetHeliorPlanQuery,
  useGetProfileQuery,
} from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import { setPageTitle } from "../../../app/features/DataSlice";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Error from "../../../components/Error";
import toast from "react-hot-toast";
import { toastStyle } from "../../../helpers";

const Products = () => {
  const pageTitle = "products";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { lang } = useParams();
  const [activePlan, setActivePlan] = useState({});

  const sessionId = useRef(Date.now()).current;
  const { data, isSuccess } = useGetProfileQuery({
    sessionId,
  });

  useEffect(() => {
    if (isSuccess) {
      const plans = data?.plan;
      if (plans.length > 0) {
        setActivePlan(plans[0]);
      }
    }
  }, [isSuccess, data]);

  const {
    data: heliorOne,
    isSuccess: isHOSuccess,
    isLoading: isLoading1,
    isError: isError1,
    error: error1,
  } = useGetHeliorOneQuery({
    sessionId,
  });
  
  const {
    data: heliorPlan,
    isSuccess: isHPSuccess,
    isLoading: isLoading2,
    isError: isError2,
    error: error2,
  } = useGetHeliorPlanQuery({
    sessionId,
  });

  const showRequestPending = () => {
    toast.error(t("docSolitRequestPending"), { style: toastStyle });
  };

  useEffect(() => {
    dispatch(setPageTitle(t(`${pageTitle}`)));
    // eslint-disable-next-line
  }, [t]);

  return (
    <div className="mt-3">
      <h4 className="heading mb-3">{t(`${pageTitle}`)}</h4>
      <h5 className="subheading mb-3 text-light">{t("heliorAccumulation")}</h5>
      <Row className="m3-5">
        {(isLoading1 || isLoading2) && <Loader />}
        {isError1 && <Error error={error1} />}
        {isError2 && <Error error={error2} />}
        {isHPSuccess && (
          <Col lg={5} md={6} className="mb-3">
            <Card className="p-3 h-100 products" bg="dark1 text-light">
              <Card.Body className="d-flex flex-column">
                <Card.Title className="mb-4 fs-2">
                  <Image
                    src={productImg}
                    className="img-fluid"
                    alt={heliorPlan?.data?.name}
                  />
                  <h4 className="title">{heliorPlan?.data?.name}</h4>
                  <p className="sub-title">
                    {JSON.parse(heliorPlan?.data?.description)[lang]}
                  </p>
                </Card.Title>

                <div className="mb-4">
                  <p className="price-title">{t("startingFrom")}</p>
                  <span className="price">
                    {heliorPlan?.data?.amount}
                    {heliorPlan?.data?.display}
                  </span>
                  <span className="unit">/ {t("perYear")}</span>
                </div>
                <hr />
                <ul className="mt-auto list-unstyled icon-list">
                  {JSON.parse(heliorPlan?.data?.benefit)[lang].map(
                    (item, i) => (
                      <li key={i}>
                        <i className="fa-regular fa-circle-check h6 text-gold pe-2"></i>
                        {item}
                      </li>
                    )
                  )}
                </ul>
                {activePlan.docSplitRequest === "Pending" ? (
                  <Button
                    variant="primary-outline"
                    onClick={showRequestPending}
                  >
                    {t("choosePlan")}
                  </Button>
                ) : (
                  <>
                    {heliorPlan.hasPurchasedHeliorPlan ? (
                      <Link
                        className="btn btn-primary-outline"
                        to={`/${lang}/pay-next-amount`}
                      >
                        {t("payRecurringAmount")}
                      </Link>
                    ) : (
                      <Link
                        className="btn btn-primary-outline"
                        to={`/${lang}/products/helior-plan/${heliorPlan?.data?.id}`}
                      >
                        {t("choosePlan")}
                      </Link>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        )}

        {isHOSuccess && (
          <Col lg={5} md={6} className="mb-3">
            <Card className="p-3 h-100 products" bg="dark1 text-light">
              <Card.Body className="d-flex flex-column">
                <Card.Title className="mb-4 fs-2">
                  <Image
                    src={productImg}
                    className="img-fluid"
                    alt={heliorOne?.data?.name}
                  />
                  <h4 className="title">{heliorOne?.data?.name}</h4>
                  <p className="sub-title">
                    {JSON.parse(heliorOne?.data?.description)[lang]}
                  </p>
                </Card.Title>

                <div className="mb-4">
                  <p className="price-title">{t("startingFrom")}</p>
                  <span className="price text-lowercase">
                    {heliorOne?.data?.amount} {heliorOne?.data?.display}
                  </span>
                </div>
                <hr />
                <ul className="mt-auto list-unstyled icon-list">
                  {JSON.parse(heliorOne?.data?.benefit)[lang].map((item, i) => (
                    <li key={i}>
                      <i className="fa-regular fa-circle-check h6 text-gold pe-2"></i>
                      {item}
                    </li>
                  ))}
                </ul>
                <Link
                  className="btn btn-primary-outline"
                  to={`/${lang}/products/helior-one/${heliorOne?.data?.id}`}
                >
                  {t("choosePlan")}
                </Link>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Products;
