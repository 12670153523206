import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useUpdateUserMutation } from "../../app/features/apiSlice";
import { toastError, toastSuccess } from "../../helpers";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Formik } from "formik";
import * as yup from "yup";

const ContactDetails = ({ data, refetch }) => {
  const { t } = useTranslation();
  const { lang } = useParams();

  const initialValues = {
    email: data?.user?.email || "",
    type: data?.orgUser?.type || "",
    taxCode: data?.orgUser?.tax_code || "",
    pec: data?.orgUser?.PEC || "",
    affiliateCode: data?.orgUser?.affiliate_code || "",
    vatNumber: data?.orgUser?.vat_number || "",
    name: data?.user?.first_name || "",
    surName: data?.user?.last_name || "",
    dateOfBirth: data?.orgUser?.date_of_birth || "",
    sex: data?.orgUser?.sex || "",
    placeOfBirth: data?.orgUser?.place_of_birth || "",
    provinceOfBirth: data?.orgUser?.province_of_birth || "",
    mobilePhone: data?.user?.phone || "",
    telephone: data?.orgUser?.telephone || "",
    address: data?.orgUser?.address || "",
    city: data?.orgUser?.city || "",
    province: data?.orgUser?.province || "",
    postcode: data?.orgUser?.postcode || "",
    addressHome: data?.orgUser?.address_home || "",
    cityHome: data?.orgUser?.city_home || "",
    provinceHome: data?.orgUser?.province_home || "",
    postcodeHome: data?.orgUser?.postcode_home || "",
    status: data?.orgUser?.status || "",
    language: lang,
    roleId: 9,
  };

  const lettersWithSpaces = /^[a-zA-ZÀ-ÿ\s]+$/;
  const alphanumericNoSpaces = /^[0-9a-zA-Z]+$/;
  const alphanumericWithSpaces = /^[0-9a-zA-Z\s]+$/;

  const schema = yup.object().shape({
    type: yup.string().required("typeRequired"),
    vatNumber: yup.string().when("type", {
      is: "COMPANY",
      then: () => yup.string().required("vatNumberRequired"),
    }),
    taxCode: yup
      .string()
      .required("taxCodeRequired")
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
    // pec: yup.string().required("pecRequired"),
    name: yup
      .string()
      .required("firstnameRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    surName: yup
      .string()
      .required("lastnameRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    dateOfBirth: yup
      .string()
      .required("dobRequired")
      .test("is-adult", "dobUnderAge", function (value) {
        const currentDate = new Date();
        const dob = new Date(value);
        const minAgeDate = new Date(
          currentDate.getFullYear() - 18,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return dob <= minAgeDate;
      }),
    sex: yup.string().required("sexRequired"),
    placeOfBirth: yup.string().required("birthPlaceRequired"),
    provinceOfBirth: yup.string().required("birthCountryRequired"),
    mobilePhone: yup
      .string()
      .test("is-valid-phone", "invalidPhoneNumber", (value) => {
        return !value || isValidPhoneNumber(value);
      })
      .required("mobileNumberRequired"),
    address: yup
      .string()
      .required("residenceAddressRequired")
      .matches(alphanumericWithSpaces, "specialCharacterNotAllowed"),
    city: yup
      .string()
      .required("residenceCityRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    province: yup.string().required("residenceCountryRequired"),
    postcode: yup
      .string()
      .required("residencePostcodeRequired")
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
    addressHome: yup
      .string()
      .required("domicileAddressRequired")
      .matches(alphanumericWithSpaces, "specialCharacterNotAllowed"),
    cityHome: yup
      .string()
      .required("domicileCityRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    provinceHome: yup.string().required("domicileCountryRequired"),
    postcodeHome: yup
      .string()
      .required("domicilePostcodeRequired")
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
  });

  const [updateUser] = useUpdateUserMutation();
  const handleSubmit = async (values) => {
    try {
      const response = await updateUser({
        data: values,
        userId: data?.user?.id,
      });
      if (response?.error) {
        toastError(response, t("error.serverNotResponding"));
      } else {
        const message = response?.data?.msg ?? response?.data?.message;
        toastSuccess(message);
        refetch();
      }
    } catch (err) {
      toastError(err, t("error.serverNotResponding"));
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values) => handleSubmit(values)}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur, //Validation is working on onBlur
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="add-inner-form">
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              {t("form.email")} *
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="email"
                placeholder={t("form.emailPlaceholder")}
                name="email"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
                disabled={true}
                size="sm"
              />
              <Form.Control.Feedback type="invalid">
                {t(`error.${errors.email}`)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              {t("form.mobileNumber")} *
            </Form.Label>
            <Col sm={10}>
              <PhoneInput
                international
                defaultCountry="US"
                name="mobilePhone"
                onChange={(value) => setFieldValue("mobilePhone", value)}
                onBlur={handleBlur}
                value={values.mobilePhone}
                required={true}
                className={
                  errors.mobilePhone && touched.mobilePhone ? "is-invalid" : ""
                }
              />
              <Form.Control.Feedback type="invalid">
                {t(`error.${errors?.mobilePhone}`)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              {t("telephone")} *
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="telephone"
                value={values.telephone}
                placeholder={t("form.telephone")}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.telephone && !!errors.telephone}
                size="sm"
              />
              <Form.Control.Feedback type="invalid">
                {t(`error.${errors.telephone}`)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Button variant="primary-outline" type="submit">
            {t("update")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactDetails;
