import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Card, Col, Row, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetContractDetailsQuery } from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { setPageTitle } from "../../../app/features/DataSlice";
import { formatNumberToLocal } from "../../../helpers";
import ContractDetailTable from "../../../components/HeliorPlan/ContractDetailTable";

const ContractDetail = () => {
  const pageTitle = "Helior Plan";
  const { t } = useTranslation();
  const { contractCode } = useParams();
  const dispatch = useDispatch();
  const sessionId = useRef(Date.now()).current;

  const { data, isLoading, isError, error, isSuccess } =
    useGetContractDetailsQuery({ sessionId, contractCode });

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  });

  return (
    <div className="my-3">
      {isLoading && <Loader />}
      {isError && <Error error={error} />}
      {isSuccess && (
        <Card className="mb-2" bg="dark1 text-light">
          <Card.Body>
            <Row>
              <Col lg="9">
                <ul className="list-unstyled m-0 columns-3">
                  <li className="my-2">
                    <b>{t("date")}:</b>{" "}
                    {data?.data?.contract_date?.split("T")[0]}
                  </li>
                  <li className="my-2">
                    <b>{t("periodicity")}:</b>{" "}
                    {t(`${data?.data?.periodicity_of_amount}`)}
                  </li>
                  <li className="my-2">
                    <b>{t("paymentAmount")}:</b> €
                    {formatNumberToLocal(data?.data?.payment_amount)}
                  </li>
                  <li className="my-2">
                    <b>{t("initialPayment")}:</b>{" "}
                    {data?.data?.number_of_initial_payments}
                  </li>
                  <li className="my-2">
                    <b>{t("initialIngot")}:</b> {data?.data?.ingot_gr}
                  </li>
                  <li className="my-2">
                    <b>{t("recurringPayments")}:</b>{" "}
                    {data?.data?.number_of_recurring_payments}
                  </li>
                  <li className="my-2">
                    <b>{t("recurringIngot")}:</b>{" "}
                    {data?.data?.cutting_ingots_recurring_payments}
                  </li>
                  <li className="my-2">
                    <b>{t("totalPayment2")}:</b> €
                    {formatNumberToLocal(data?.data?.recurring_amount_paid)}
                  </li>
                  <li className="my-2">
                    <b>{t("note")}:</b> {data?.data?.note}
                  </li>
                  <li className="my-2">
                    <b>{t("quotaDoc")}:</b> €
                    {formatNumberToLocal(data?.data?.doc_share)}
                  </li>
                  <li className="my-2">
                    <b>{t("recurringAmountPaid")}:</b> €
                    {formatNumberToLocal(data?.data?.total_payment)}
                  </li>
                  <li className="my-2 text-gold">
                    {data?.data?.disc_code && (
                      <>
                        <b>{t("couponCode")}:</b> {data?.data?.disc_code}
                      </>
                    )}
                  </li>
                </ul>
              </Col>
              <Col lg="3">
                <div className="h-100 d-flex justify-content-end align-items-center">
                  <i className="fa fa-cart-shopping bg-gold text-light p-3 rounded-circle font-40" />
                  <div className="m-3">
                    <h6 className="mb-0">{contractCode}</h6>
                    {data?.data?.docSplitRequest === "Pending" ? (
                      <p className="text-light">
                        {t("docSplitRequest")}: {data?.data?.docSplitRequest}
                      </p>
                    ) : (
                      <Badge
                        bg={`${
                          data?.data?.status === "Active" ? "success" : "danger"
                        }`}
                      >
                        {data?.data?.status === "Active"
                          ? t("active")
                          : t("inactive")}
                      </Badge>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      <ContractDetailTable payments={data?.data?.payment} />
    </div>
  );
};

export default ContractDetail;
