import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useLoginMutation } from "../app/features/apiSlice";
import { swalError } from "../helpers";
import { Form, Button, Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthLogo from "../components/AuthLogo";
import { useDispatch } from "react-redux";
import { setCredentials } from "../app/features/authSlice";

const Login = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
    language: lang,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value.trim() }));
  };

  const [login] = useLoginMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userData = await login(inputData).unwrap();
      const data = { email: inputData.email, ...userData };
      dispatch(setCredentials({ data }));
      navigate(`/${lang}/dashboard`);
    } catch (err) {
      swalError(err, t("error.serverNotResponding"))
    }
    setIsLoading(false);
  };

  return (
    <Col lg="4" md="8" sm="12">
      <AuthLogo title="accessHelior" />
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>{t("email")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("emailPlaceholder")}
            name="email"
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>{t("form.password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder="**********"
            name="password"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Link
          to={`/${lang}/forgot-password`}
          className="d-block text-center text-gold"
        >
          {t("forgotYourPassword")}
        </Link>
        <Button
          variant="primary"
          type="submit"
          className="btn-block btn-primary mt-3 mb-1 py-2"
        >
          {t("login")}
          {isLoading && <Spinner size="sm" className="ms-2" animation="grow" />}
        </Button>
      </Form>
      <p className="mt-3 text-center">
        {t("noAccount")}{" "}
        <Link to={`/${lang}/signup`} className="text-gold">
          {t("noAccountLink")}
        </Link>
      </p>
    </Col>
  );
};

export default Login;
