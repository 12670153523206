// https://reactdatepicker.com/#example-custom-month
// https://reactdatepicker.com/#example-date-range-using-input-with-clear-button

import React, { useState } from "react";
import SetPageTitle from "../../../components/SetPageTitle";
import SmallCards from "../../../components/dashboard/SmallCards";
import { Badge, Button, Card, Form, InputGroup, Row, Table } from "react-bootstrap";
import { formatNumberToLocal } from "../../../helpers";
import wallet from "../../../assets/images/wallet.webp";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import network from "../../../assets/images/network.webp";

import "react-datepicker/dist/react-datepicker.css";

const data = [
  {
      "transaction_id": "TXN001",
      "date": "2024-09-01",
      "type": "bonus",
      "amount": 150.00,
      "status": "completed"
  },
  {
      "transaction_id": "TXN002",
      "date": "2024-09-05",
      "type": "commission",
      "amount": 200.00,
      "status": "completed"
  },
  {
      "transaction_id": "TXN003",
      "date": "2024-09-10",
      "type": "payment_made",
      "amount": 50.00,
      "status": "completed"
  },
  {
      "transaction_id": "TXN004",
      "date": "2024-09-12",
      "type": "bonus",
      "amount": 120.00,
      "status": "completed"
  },
  {
      "transaction_id": "TXN005",
      "date": "2024-09-15",
      "type": "deduction",
      "amount": -30.00,
      "status": "completed"
  },
  {
      "transaction_id": "TXN006",
      "date": "2024-09-18",
      "type": "commission",
      "amount": 180.00,
      "status": "pending"
  },
  {
      "transaction_id": "TXN007",
      "date": "2024-09-20",
      "type": "bonus",
      "amount": 160.00,
      "status": "completed"
  },
  {
      "transaction_id": "TXN008",
      "date": "2024-09-22",
      "type": "payment_made",
      "amount": 75.00,
      "status": "completed"
  },
  {
      "transaction_id": "TXN009",
      "date": "2024-09-25",
      "type": "deduction",
      "amount": -20.00,
      "status": "completed"
  },
  {
      "transaction_id": "TXN010",
      "date": "2024-09-28",
      "type": "commission",
      "amount": 140.00,
      "status": "completed"
  }
]

const Wallet = () => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  return (
    <>
      <SetPageTitle title="sidebar.wallet" display={false} />
      <Row>
        <SmallCards
          imgSrc={wallet}
          title={t('currentBalance')}
          number={`${formatNumberToLocal(100)}€`}
        />
        <SmallCards
          icon={<i class="fas fa-calculator"></i>}
          title={t('totalAccumulated')}
          number={`${formatNumberToLocal(100)}€`}
        />
        <SmallCards
          icon={<i class="fas fa-calendar"></i>}
          title={t('productionMonth')}
          number="15th Sept - 10th Oct"
        />
        <SmallCards
          imgSrc={network}
          title={t('nextPaymentRequestDate')}
          number="17th Oct"
        />
      </Row>
      <Card bg="dark1" className="my-3 text-light">
        <Card.Header>{t('availableForWithdrawal')}: {formatNumberToLocal(80)}€</Card.Header>
        <Card.Body>
          <InputGroup className="mb-1" size="sm">
            <Form.Control value="0" style={{ maxWidth: "12rem" }} />
            <Button variant="outline-warning">{t('request')}</Button>
          </InputGroup>
          <Form.Text className="text-danger">
            {t('noteForPaymentRequest')}
          </Form.Text>
        </Card.Body>
      </Card>
      <Card bg="dark1" className="text-light">
        <Card.Header>{t('transactions')}</Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-end align-items-center">
            <Form.Group className="d-flex flex-column form-calendar">
              <Form.Label>{t('paymentStatus')}</Form.Label>
              <Form.Select defaultValue={""} size="sm" className="ms-1">
                <option value={""} disabled>
                  {t('status')}
                </option>
                <option value="transferred">{t('transferred')}</option>
                <option value="pending">{t('pending')}</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="ms-2 d-flex flex-column form-calendar">
              <Form.Label>{t('productionMonth')}</Form.Label>
              <DatePicker
                // selected={new Date()}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                className="form-control form-control-sm"
              />
            </Form.Group>
            <Form.Group className="ms-1 d-flex flex-column form-calendar">
              <Form.Label>{t('dateRange')}</Form.Label>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                className="form-control form-control-sm"
                // isClearable={true}
              />
            </Form.Group>
          </div>
          <Table>
            <thead>
              <tr>
                <th className="text-light">{t('id')}</th>
                <th className="text-light">{t('date')}</th>
                <th className="text-light">{t('paymentType')}</th>
                <th className="text-light">{t('amount')}</th>
                <th className="text-light">{t('status')}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>
                  <td className="text-light">{item.transaction_id}</td>
                  <td className="text-light">{item.date}</td>
                  <td className={item.type === "deduction" ? 'text-danger' : 'text-light'}>{t(`${item.type}`)}</td>
                  <td className={item.amount > 0 ? 'text-success' : 'text-danger'}>{item.amount}</td>
                  <td><Badge bg={item.status === "pending" ? 'danger' : 'success'}>{t(`${item.status}`)}</Badge></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default Wallet;
