import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useCreateDiscountMutation,
  useGetClientsQuery,
} from "../../app/features/apiSlice";
import Loader from "../Loader";
import Error from "../Error";
import { toastError, toastSuccess } from "../../helpers";

const AddDiscount = ({ refetch }) => {
  const sessionId = useRef(Date.now()).current;
  const { t } = useTranslation();
  const { lang } = useParams();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [inputData, setInputData] = useState({
    code: "",
    userId: "",
    language: lang,
  });
  const { data, isLoading, isSuccess, isError, error } = useGetClientsQuery({
    sessionId,
  });

  const [createDiscount] = useCreateDiscountMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await createDiscount(inputData).unwrap();
      toastSuccess(response?.message);
      refetch();
      setShow(false);
    } catch (err) {
      toastError(err, t("error.serverNotResponding"));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isSuccess) {
      const items = data?.data;
      if (items.length > 0) {
        const customerData = items.map((item) => item.customer);
        setCustomers(customerData);
      }
    }
  }, [isSuccess, data]);

  return (
    <>
      <Button
        variant="icon-button text-light"
        className=""
        onClick={() => setShow(true)}
      >
        <i className="fa fa-plus" />
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark h6">
            {t("assignDiscountCode")}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {isLoading && <Loader />}
            {isError && <Error error={error} />}
            {isSuccess && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>{t("selectDiscountCode")}</Form.Label>
                  <Form.Select
                    value={inputData?.code}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        code: e.target.value,
                      }))
                    }
                    size="sm"
                    required
                  >
                    <option value="" disabled>
                      {t("sltLabel")}
                    </option>
                    {["CS5", "CS10"].map((code, i) => (
                      <option value={code} key={i}>
                        {code}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("selectClient")}</Form.Label>
                  <Form.Select
                    value={inputData?.userId}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        userId: e.target.value,
                      }))
                    }
                    size="sm"
                    required
                  >
                    <option value="" disabled>
                      {t("sltLabel")}
                    </option>
                    {customers.map((customer, i) => (
                      <option value={customer.id} key={i}>
                        {customer.first_name} {customer.last_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setShow(false)}
            >
              {t("close")}
            </Button>
            <Button type="submit" variant="gold" size="sm" disabled={loading}>
              {t("confirm")} {loading && <Spinner size="sm" variant="dark" />}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddDiscount;
