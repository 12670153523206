import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Error = ({ error }) => {
  const { t } = useTranslation();

  console.log(error);
  return (
    <>
      <Alert variant="danger">
        <Alert.Heading>{t("error.errorAlertHeading")}</Alert.Heading>
        <p>
          {error.status === "FETCH_ERROR"
            ? t("error.internalServerError")
            : error.message}
        </p>
      </Alert>
    </>
  );
};

export default Error;
