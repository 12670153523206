import React, { useRef, useState, useEffect } from "react";
import { useGetCollaboratorsQuery } from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { Badge, Button, ButtonGroup, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SetPageTitle from "../../../components/SetPageTitle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useParams } from "react-router-dom";

const ListCollab = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const dt = useRef();
  const exportCSV = () => dt.current.exportCSV();
  const [globalFilter, setGlobalFilter] = useState("");
  const [tableData, setTableData] = useState([]);
  const sessionId = useRef(Date.now()).current;
  const { data, isLoading, isError, error } = useGetCollaboratorsQuery({
    sessionId,
  });

  useEffect(() => {
    if (!isLoading) {
      const items = data?.data;
      const tableItem = [];
      let i = 1;
      if (items.length > 0) {
        items.forEach((item) => {
          tableItem.push({
            sno: i,
            firstname: item?.user?.first_name,
            lastname: item?.user?.last_name,
            email: item?.user?.email,
            phone: item?.user?.phone,
            id: item?.user?.id,
            status: item?.status,
          });
          i++;
        });
      }
      setTableData(tableItem);
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error error={error} />;
  }
  return (
    <div className="my-3">
      <Card bg="dark1 text-light">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <SetPageTitle title="collaborator" />
          <div className="d-flex justify-content-between align-items-center">
            <Link
              variant="icon-button"
              className="btn text-light"
              to={`/${lang}/add-collaborator`}
            >
              <i className="fa fa-plus" />
            </Link>
            <Button
              variant="icon-button text-light"
              onClick={() => exportCSV(false)}
              data-pr-tooltip="CSV"
            >
              <i className="fa fa-download" />
            </Button>
            <Form.Control
              type="text"
              placeholder="Search"
              className=" mr-sm-2"
              size="sm"
              onInput={(e) => setGlobalFilter(e.target.value)}
            />
          </div>
        </Card.Header>
        <Card.Body>
          <DataTable
            value={tableData}
            sortMode="multiple"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50]}
            ref={dt}
            size={"small"}
            showGridlines
            stripedRows
            removableSort
            globalFilter={globalFilter}
          >
            <Column sortable field="sno" header={"#"}></Column>
            <Column
              sortable
              field="firstname"
              header={t("form.firstname")}
            ></Column>
            <Column
              sortable
              field="lastname"
              header={t("form.lastname")}
            ></Column>
            <Column sortable field="email" header={t("form.email")}></Column>
            <Column
              sortable
              field="phone"
              header={t("form.mobileNumber")}
            ></Column>
            <Column
              sortable
              header={t("status")}
              body={(rowData) => (
                <Badge
                  bg={`${
                    rowData.status === "Active" ? "success" : "secondary"
                  }`}
                >
                  {rowData.status === "Active" ? t("active") : t("inactive")}
                </Badge>
              )}
            ></Column>
            <Column
              body={(rowData) => (
                <ButtonGroup size="sm">
                  <Link
                    to={`/${lang}/collaborator/${rowData.id}`}
                    className="btn btn-outline-light"
                  >
                    <i className="fa fa-eye" />
                  </Link>
                  <Link
                    to={`/${lang}/collaborator/update/${rowData.id}`}
                    className="btn btn-outline-light"
                  >
                    <i className="fa fa-pen" />
                  </Link>
                </ButtonGroup>
              )}
            ></Column>
          </DataTable>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ListCollab;
