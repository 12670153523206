import React from "react";
import { useSelector } from "react-redux";
import SwitchLanguage from "../SwitchLanguage";
import { selectPageTitle } from "../../app/features/DataSlice";
import { Container, Navbar } from "react-bootstrap";

const TopNav = ({ setToggleMenu, toggleMenu }) => {
  const PageTitle = useSelector(selectPageTitle);
  return (
    <Navbar bg="dark1" data-bs-theme="dark">
      <Container fluid className="px-3">
        <Navbar.Brand>{PageTitle}</Navbar.Brand>
        <div className="d-flex">
          <button
            className="btn btn-sm"
            id="sidebarToggle"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <i className="fas fa-bars"></i>
          </button>
          <SwitchLanguage />
        </div>
      </Container>
    </Navbar>
  );
};

export default TopNav;
