import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useGetCommissionsQuery } from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { formatNumberToLocal } from "../../../helpers";
import SmallCards from "../../../components/dashboard/SmallCards";
import directCommission from "../../../assets/images/direct-commission.webp";
import first2levels from "../../../assets/images/first2levels.webp";
import poolSilver from "../../../assets/images/pool-silver.webp";
import teamManagerImg from "../../../assets/images/team-manager.webp";
import teamManagerPoolImg from "../../../assets/images/team-manager-pool.webp";
import SetPageTitle from "../../../components/SetPageTitle";

const Commissions = () => {
  const dt = useRef();
  const { t } = useTranslation();
  const { lang } = useParams();
  const exportCSV = () => dt.current.exportCSV();
  const sessionId = useRef(Date.now()).current;
  const [globalFilter, setGlobalFilter] = useState("");
  const [tableData, setTableData] = useState([]);

  const { data, isLoading, isError, error, isSuccess } = useGetCommissionsQuery(
    {
      sessionId,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const tableItems = [];
      data?.data?.uniqueData?.forEach((item) => {
        let client = "";
        if (item?.hp_plans?.client?.id) {
          client = `${item?.hp_plans?.client?.first_name} ${item?.hp_plans?.client?.last_name}`;
        } else if (item?.ho_plans?.client?.id) {
          client = `${item?.ho_plans?.client?.first_name} ${item?.ho_plans?.client?.last_name}`;
        } else {
          client = "-";
        }

        let collab = "";
        if (item?.hp_plans) {
          collab = `${item?.hp_plans?.client?.Collaborators[0]?.first_name} ${item?.hp_plans?.client?.Collaborators[0]?.last_name}`;
        } else if (item?.ho_plans) {
          collab = `${item?.ho_plans?.client?.Collaborators[0]?.first_name} ${item?.ho_plans?.client?.Collaborators[0]?.last_name}`;
        }

        let collabId = "";
        if (item?.hp_plans) {
          collabId = item?.hp_plans?.client?.Collaborators[0]?.id;
        } else if (item?.ho_plans) {
          collabId = item?.ho_plans?.client?.Collaborators[0]?.id;
        }

        tableItems.push({
          client,
          collabId,
          collab,
          clientId: item?.hp_plans?.client?.id ?? item?.ho_plans?.client?.id,
          contractCode: item.hp_contractId ?? item.ho_contractId,
          plan: item.hp_contractId ? "HPLAN" : "HONE",
          percentage: (item.percent * 100).toFixed(2),
          amount: formatNumberToLocal(item?.amount) + "€",
          role: item.role,
          action: "",
        });
      });
      setTableData(tableItems);
    }
  }, [isSuccess, data]);

  const handlePercentageLabel = (value) => {
    switch (value) {
      case "30.00":
        return `${value}% ${t("directDoc")}`;
      case "12.5":
        return `${value}% ${t("indirectDoc")}`;
      case "7.5":
        return `${value}% ${t("secondLevelDoc")}`;
      case "5.06":
        return `${value}% ${t("recurringDirect")}.`;
      case "2.55":
        return `${value}% ${t("recurringIndirectEvents")}.`;
      case "1.28":
        return `${value}% ${t("firstLevelRecurring")}.`;
      case "15.00":
        return `${value}% ${t("dcDocShare")}`;
      case "3.59":
        return `${value}% ${t("recurringDcQuota")}.`;
      case "0.10":
        return `${value}% ${t("tmIronRecurringFee")}`;
      case "0.20":
        return `${value}% ${t("tmSteelRecurringQuota")}`;
      case "0.30":
        return `${value}% ${t("tmBronzeRecurringFee")}`;
      case "0.40":
        return `${value}% ${t("tmSilverRecurringFee")}`;
      case "0.50":
        return `${value}% ${t("tmGoldRecurringFee")}`;
      default:
        return `${value}%`;
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error error={error} />;
  }

  return (
    <div className="my-3">
      <SetPageTitle title="commission" display="false" />
      <Row className="mb-3">
        <SmallCards
          imgSrc={directCommission}
          title={t("direct")}
          number={`${formatNumberToLocal(
            data?.data?.dcCommission || data?.data?.directCommission
          )}€`}
        />
        <SmallCards
          imgSrc={first2levels}
          title={t("overFirst2Levels")}
          number={`${formatNumberToLocal(
            data?.data?.dcOverCommission || data?.data?.firstTwoLevelCommission
          )}€`}
        />
        <SmallCards
          imgSrc={poolSilver}
          title={t("Pool Silver - Gold")}
          number={`${formatNumberToLocal(data?.data?.totalPoolBonus || 0)}€`}
        />
        <SmallCards
          imgSrc={teamManagerImg}
          title={t("teamManager")}
          number={`${formatNumberToLocal(
            data?.data?.teamManagerCommission || 0
          )}€`}
        />
        <SmallCards
          imgSrc={teamManagerPoolImg}
          title={t("teamManagerPool")}
          number={`${formatNumberToLocal(data?.data?.teamManagerPool || 0)}€`}
        />
      </Row>
      <Card bg="dark1 text-light">
        <Card.Body>
          <Tabs defaultActiveKey="summary" className="mb-4">
            <Tab eventKey="summary" title="Business Summary">
              <Row className="mb-3">
                <Col md="4">
                  <h5>{t("totalMonth")}</h5>
                  <span className="bg-gold py-1 px-3">
                    {formatNumberToLocal(data?.data?.commissionOfMonth)}€
                  </span>
                </Col>
                <Col md="8">
                  <h5>{t("recurring")}</h5>
                  <p>
                    {t("liveOverFromNet")}{" "}
                    <span className="ms-5 bg-gold py-1 px-3">
                      {formatNumberToLocal(data?.data?.overFromNetCommission)}€
                    </span>
                  </p>
                  <p>
                    {t("recurringTeamManagers")}{" "}
                    <span className="ms-5 bg-gold py-1 px-3">
                      {formatNumberToLocal(data?.data?.recurringTeamManager)}€
                    </span>
                  </p>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="transaction" title="Business Transaction">
              <div className="d-flex justify-content-end">
                <Button
                  variant="icon-button text-light"
                  onClick={() => exportCSV(false)}
                  data-pr-tooltip="CSV"
                >
                  <i className="fa fa-download" />
                </Button>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className="mr-sm-2 mb-3"
                  size="sm"
                  onInput={(e) => setGlobalFilter(e.target.value)}
                  style={{ width: "250px" }}
                />
              </div>
              <DataTable
                value={tableData}
                sortMode="multiple"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                ref={dt}
                size={"small"}
                showGridlines
                stripedRows
                removableSort
                globalFilter={globalFilter}
              >
                <Column
                  sortable
                  field="client"
                  header={t("collaborator")}
                  body={(rowData) => (
                    <>
                      {(() => {
                        if (rowData.collabId) {
                          return (
                            <Link
                              to={`/${lang}/collaborator/${rowData?.collabId}`}
                              className="text-light"
                            >
                              {rowData?.collab}
                            </Link>
                          );
                        } else if (rowData.clientId) {
                          return (
                            <Link
                              to={`/${lang}/collaborator/${rowData?.clientId}`}
                              className="text-light"
                            >
                              {rowData?.client}
                            </Link>
                          );
                        } else {
                          return "-";
                        }
                      })()}
                    </>
                  )}
                ></Column>
                <Column
                  sortable
                  field="contractCode"
                  header={t("number")}
                ></Column>
                <Column
                  sortable
                  field="percentage"
                  header={t("percentage")}
                  body={(rowData) => {
                    return handlePercentageLabel(rowData?.percentage);
                  }}
                ></Column>
                <Column sortable field="amount" header={t("amount")}></Column>
                <Column sortable field="role" header={t("role")}></Column>
                <Column sortable field="plan" header={t("plan")}></Column>
                <Column
                  body={(rowData) => (
                    <Link
                      to={`/${lang}/plan/helior-${
                        rowData.plan === "HPLAN" ? "plan" : "one"
                      }/${rowData.contractCode}`}
                      className="text-light"
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </Link>
                  )}
                />
              </DataTable>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Commissions;
