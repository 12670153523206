import React, { useEffect } from "react";
import { logout } from "../app/features/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../components/Loader";
import { setResetData } from "../app/features/DataSlice";

const Logout = () => {
  const { lang } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setResetData());
    dispatch(logout());
    navigate(`/${lang}/dashboard`);
  });

  return <Loader />;
};

export default Logout;
