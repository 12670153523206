import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation} from "react-router";
import { setDefaultLang } from "../app/features/DataSlice";

const SwitchLanguage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [showLang, setShowLang] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const changeLanguage = () => {
    const lang = selectedLanguage === "en" ? "it" : "en";
    dispatch(setDefaultLang(lang));
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    const path = location.pathname.slice(1).split("/");
    path[0] = lang;
    window.location.href = `/${[...path].join("/")}`;
  };

  useEffect(() => {
    const path = location.pathname.slice(1).split("/");
    setSelectedLanguage(path[0]);
    dispatch(setDefaultLang(path[0]));
  }, [i18n, location.pathname, dispatch]);

  return (
    <div className="dropdown">
      <button
        type="button"
        className="dropdown-toggle"
        style={{
          padding: "10px 20px",
          borderRadius: "30px",
          backgroundColor: "#fff",
          textTransform: "uppercase",
          border: 0,
          color: "black"
        }}
        onClick={() => setShowLang(!showLang)}
      >
        {selectedLanguage}
      </button>
      <ul
        className={`dropdown-menu ${showLang ? "show" : ""}`}
        style={{
          padding: "10px 20px",
          backgroundColor: "#fff",
          borderRadius: "30px",
          minWidth: "68px",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        <li style={{ cursor: "pointer" }} onClick={() => changeLanguage()}>
          {selectedLanguage === "en" ? "it" : "en"}
        </li>
      </ul>
    </div>
  );
};

export default SwitchLanguage;
