import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SetPageTitle from "../../../components/SetPageTitle";
import { useGetCommissionsQuery } from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { formatNumberToLocal } from "../../../helpers";
import moment from "moment";
import "moment/locale/it";

const PoolBonus = () => {
  const dt = useRef();
  const { t } = useTranslation();
  const { lang } = useParams();
  if (lang === "it") {
    moment.locale("it");
  } else {
    moment.locale("en");
  }
  const exportCSV = () => dt.current.exportCSV();
  const sessionId = useRef(Date.now()).current;
  const [globalFilter, setGlobalFilter] = useState("");
  const [tableData, setTableData] = useState([]);

  const { data, isLoading, isError, error, isSuccess } = useGetCommissionsQuery(
    {
      sessionId,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const tableItems = [];
      if(data?.data?.poolBonusData.length > 0) {
        data?.data?.poolBonusData.forEach((item) => {
          tableItems.push({
            userId: item?.userId,
            amount: formatNumberToLocal(item?.value) + "€",
            type: item.type,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          });
        });
      }
      setTableData(tableItems);
    }
  }, [isSuccess, data]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error error={error} />;
  }

  return (
    <Card bg="dark1 text-light">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <SetPageTitle title="poolBonus" />
        <div>
          {t(`totalGoldBonus`)}:{" "}
          {formatNumberToLocal(data?.data?.totalGoldBonus || 0)}€
          <br />
          {t(`totalSilverBonus`)}:{" "}
          {formatNumberToLocal(data?.data?.totalSilverBonus || 0)}€
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            variant="icon-button text-light"
            onClick={() => exportCSV(false)}
            data-pr-tooltip="CSV"
          >
            <i className="fa fa-download" />
          </Button>
          <Form.Control
            type="text"
            placeholder="Search"
            className=" mr-sm-2"
            size="sm"
            onInput={(e) => setGlobalFilter(e.target.value)}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <DataTable
          value={tableData}
          sortMode="multiple"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          ref={dt}
          size={"small"}
          showGridlines
          stripedRows
          removableSort
          globalFilter={globalFilter}
        >
          <Column
            sortable
            field="type"
            header={t("bonus")}
            body={(rowData) => t(`${rowData.type}`)}
          ></Column>
          <Column sortable field="amount" header={t("amount")}></Column>
          <Column
            sortable
            field="createdAt"
            header={t("createdAt")}
            body={(rowData) => moment(rowData.createdAt).fromNow()}
          ></Column>
          <Column
            sortable
            field="updatedAt"
            header={t("updatedAt")}
            body={(rowData) => moment(rowData.updatedAt).fromNow()}
          ></Column>
          <Column></Column>
        </DataTable>
      </Card.Body>
    </Card>
  );
};

export default PoolBonus;
