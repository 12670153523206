import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Badge, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetSingleHOneQuery } from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { setPageTitle } from "../../../app/features/DataSlice";
import { formatNumberToLocal } from "../../../helpers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const HOneContractDetails = () => {
  const pageTitle = "Helior One";
  const { t } = useTranslation();
  const { contractCode } = useParams();
  const dispatch = useDispatch();
  const sessionId = useRef(Date.now()).current;

  const { data, isLoading, isError, error, isSuccess } = useGetSingleHOneQuery({
    sessionId,
    contractCode,
  });

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  });

  return (
    <div className="my-3">
      {isLoading && <Loader />}
      {isError && <Error error={error} />}
      {isSuccess && (
        <>
          <Card className="mb-2" bg="dark1 text-light">
            <Card.Header className="text-uppercase">
              {t("purchase")}
            </Card.Header>
            <Card.Body>
              <ul className="columns-2 list-unstyled m-0">
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("contractDate")}</span>
                  <span>{data?.contract_date?.split("T")[0]}</span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("duration")}</span>
                  <span>
                    {data?.duration} {t("years")}
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("goldValue")}</span>
                  <span>
                    {formatNumberToLocal(data?.purchase?.goldPrice)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("spread")} 2%</span>
                  <span>
                    {formatNumberToLocal(data?.purchase?.cost_spread)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("manufacturing2")}</span>
                  <span>
                    {formatNumberToLocal(
                      data?.purchase?.totalManufacturingCost
                    )}{" "}
                    €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("contractNumber")}</span>
                  <span>{contractCode}</span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("goldCost")}</span>
                  <span>
                    {formatNumberToLocal(data?.purchase?.goldPrice)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("custody")} 7.5%</span>
                  <span>
                    {formatNumberToLocal(data?.purchase?.cost_storage)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("totalPurchase")}</span>
                  <span>
                    {formatNumberToLocal(data?.purchase?.total_payment)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("status")}</span>
                  <Badge
                    bg={`${data?.status === "Active" ? "success" : "danger"}`}
                  >
                    {data?.status === "Active" ? t("active") : t("inactive")}
                  </Badge>
                </li>
              </ul>
            </Card.Body>
          </Card>
          <Card className="mb-2" bg="dark1 text-light">
            <Card.Header className="text-uppercase">
              {t("warranty")}
            </Card.Header>
            <Card.Body>
              <ul className="columns-2 list-unstyled m-0">
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("goldValue")}</span>
                  <span>
                    {formatNumberToLocal(data?.warranty?.goldPriceWithMargin)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("spread")} 2%</span>
                  <span>
                    {formatNumberToLocal(data?.warranty?.costSpreadWithMargin)}{" "}
                    €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("custody")} 7.5%</span>
                  <span>
                    {formatNumberToLocal(data?.warranty?.storageCostWithMargin)}{" "}
                    €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("totalGuarantee")}</span>
                  <span>
                    {formatNumberToLocal(
                      data?.warranty?.total_payment_with_margin
                    )}{" "}
                    €
                  </span>
                </li>
              </ul>
            </Card.Body>
          </Card>
          <Card className="mb-2" bg="dark1 text-light">
            <Card.Header className="text-uppercase">
              {t("collection")}
            </Card.Header>
            <Card.Body>
              <ul className="columns-2 list-unstyled m-0">
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("date")}</span>
                  <span>{data?.collection_date?.split("T")[0]}</span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("goldValue")}</span>
                  <span>
                    {formatNumberToLocal(data?.collection?.collectionGoldPrice)}{" "}
                    €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("spread")} 2%</span>
                  <span>
                    {formatNumberToLocal(data?.collection?.spread_cost)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("manufacturing2")}</span>
                  <span>
                    {formatNumberToLocal(
                      data?.collection?.totalManufacturingCost
                    )}{" "}
                    €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("goldCost")}</span>
                  <span>
                    {formatNumberToLocal(data?.collection?.goldFixingPrice)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("custody")} 7.5%</span>
                  <span>
                    {formatNumberToLocal(data?.collection?.storage_cost)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("totalPurchase")}</span>
                  <span>
                    {formatNumberToLocal(data?.collection?.totalCollection)} €
                  </span>
                </li>
                <li className="mb-2 d-flex justify-content-between">
                  <span>{t("rest")}</span>
                  <span>{formatNumberToLocal(data?.collection?.rest)} €</span>
                </li>
              </ul>
            </Card.Body>
          </Card>
          {data?.commission?.length > 0 && (
            <Card className="mb-2" bg="dark1 text-light">
              <Card.Header className="text-uppercase">
                {t("provisions")}
              </Card.Header>
              <Card.Body>
                <DataTable
                  value={data?.commission}
                  size={"small"}
                  showGridlines
                  stripedRows
                >
                  <Column field="role" header={t("role")} />
                  <Column
                    field="firstName"
                    header={t("collaborator")}
                    body={(rowData) =>
                      rowData.firstName
                        ? `${rowData.firstName + " " + rowData.lastName}`
                        : "-"
                    }
                  />
                  <Column
                    field="percentage"
                    header={t("percentage")}
                    body={(rowData) => `${(rowData.percent * 100).toFixed(2)}%`}
                  />
                  <Column field="amount" header={t("compensationAmount")} />
                  <Column field="overFromNet" header={t("overFromNet")} />
                  <Column field="" header={t("liquidated")} />
                  <Column />
                </DataTable>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default HOneContractDetails;
