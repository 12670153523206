import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../app/features/DataSlice";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import {
  useGetSingleCollaboratorQuery,
  useUpdateUserMutation,
} from "../../../app/features/apiSlice";
import { toastError, toastSuccess } from "../../../helpers";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import locationData from "../../../countries.json";
import { Formik } from "formik";
import * as yup from "yup";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import moment from "moment";
import { Link } from "react-router-dom";
import "moment/locale/it";
import SetPageTitle from "../../../components/SetPageTitle";

const UpdateClient = () => {
  const dispatch = useDispatch();
  const pageTitle = "Update Client";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, lang } = useParams();
  lang === "it" ? moment.locale("it") : moment.locale("en");
  const sessionId = useRef(Date.now()).current;
  const [countries, setCountries] = useState([]);
  const { data, isLoading, isSuccess, isError, error } =
    useGetSingleCollaboratorQuery({
      sessionId,
      userId,
    });

  const initialValues = {
    email: data?.user?.email || "",
    type: data?.user?.organizationUser?.type || "",
    taxCode: data?.user?.organizationUser?.tax_code || "",
    pec: data?.user?.organizationUser?.PEC || "",
    affiliateCode: data?.user?.organizationUser?.affiliate_code || "",
    vatNumber: data?.user?.organizationUser?.vat_number || "",
    name: data?.user?.first_name || "",
    surName: data?.user?.last_name || "",
    dateOfBirth: data?.user?.organizationUser?.date_of_birth || "",
    sex: data?.user?.organizationUser?.sex || "",
    placeOfBirth: data?.user?.organizationUser?.place_of_birth || "",
    provinceOfBirth: data?.user?.organizationUser?.province_of_birth || "",
    mobilePhone: data?.user?.phone || "",
    telephone: data?.user?.organizationUser?.telephone || "",
    address: data?.user?.organizationUser?.address || "",
    city: data?.user?.organizationUser?.city || "",
    province: data?.user?.organizationUser?.province || "",
    postcode: data?.user?.organizationUser?.postcode || "",
    addressHome: data?.user?.organizationUser?.address_home || "",
    cityHome: data?.user?.organizationUser?.city_home || "",
    provinceHome: data?.user?.organizationUser?.province_home || "",
    postcodeHome: data?.user?.organizationUser?.postcode_home || "",
    status: data?.user?.organizationUser?.status || "",
    language: lang,
    roleId: 9,
  };

  const lettersWithSpaces = /^[a-zA-ZÀ-ÿ\s]+$/;
  const alphanumericNoSpaces = /^[0-9a-zA-Z]+$/;
  const alphanumericWithSpaces = /^[0-9a-zA-Z\s]+$/;

  const schema = yup.object().shape({
    type: yup.string().required("typeRequired"),
    vatNumber: yup.string().when("type", {
      is: "COMPANY",
      then: () => yup.string().required("vatNumberRequired"),
    }),
    taxCode: yup
      .string()
      .required("taxCodeRequired")
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
    // pec: yup.string().required("pecRequired"),
    name: yup
      .string()
      .required("firstnameRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    surName: yup
      .string()
      .required("lastnameRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    dateOfBirth: yup
      .string()
      .required("dobRequired")
      .test("is-adult", "dobUnderAge", function (value) {
        const currentDate = new Date();
        const dob = new Date(value);
        const minAgeDate = new Date(
          currentDate.getFullYear() - 18,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return dob <= minAgeDate;
      }),
    sex: yup.string().required("sexRequired"),
    placeOfBirth: yup.string().required("birthPlaceRequired"),
    provinceOfBirth: yup.string().required("birthCountryRequired"),
    mobilePhone: yup
      .string()
      .test("is-valid-phone", "invalidPhoneNumber", (value) => {
        return !value || isValidPhoneNumber(value);
      })
      .required("mobileNumberRequired"),
    address: yup
      .string()
      .required("residenceAddressRequired")
      .matches(alphanumericWithSpaces, "specialCharacterNotAllowed"),
    city: yup
      .string()
      .required("residenceCityRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    province: yup.string().required("residenceCountryRequired"),
    postcode: yup
      .string()
      .required("residencePostcodeRequired")
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
    addressHome: yup
      .string()
      .required("domicileAddressRequired")
      .matches(alphanumericWithSpaces, "specialCharacterNotAllowed"),
    cityHome: yup
      .string()
      .required("domicileCityRequired")
      .matches(lettersWithSpaces, "specialCharacterNotAllowed"),
    provinceHome: yup.string().required("domicileCountryRequired"),
    postcodeHome: yup
      .string()
      .required("domicilePostcodeRequired")
      .matches(alphanumericNoSpaces, "specialCharacterNotAllowed"),
  });

  const handleSameAsDomicile = (e, values, setValues) => {
    const { checked } = e.target;
    if (checked) {
      const { address, province, city, postcode } = values;
      setValues({
        ...values,
        addressHome: address,
        provinceHome: province,
        cityHome: city,
        postcodeHome: postcode,
      });
    } else {
      const { address_home, province_home, city_home, postcode_home } =
        data?.user?.organizationUser;
      setValues({
        ...values,
        addressHome: address_home,
        provinceHome: province_home,
        cityHome: city_home,
        postcodeHome: postcode_home,
      });
    }
  };

  const [updateUser] = useUpdateUserMutation();
  const handleSubmit = async (values) => {
    try {
      const response = await updateUser({ data: values, userId });
      if (response?.error) {
        toastError(response, t("error.serverNotResponding"));
      } else {
        const message = response?.data?.msg ?? response?.data?.message;
        toastSuccess(message);
        navigate(`/${lang}/client/${userId}`);
      }
    } catch (err) {
      toastError(err, t("error.serverNotResponding"));
    }
  };

  useEffect(() => {
    const data = locationData.map((country) => ({
      label: country.name,
      value: country.iso3,
    }));
    setCountries(data);
  }, []);

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  });

  return (
    <Row className="my-3">
      {isLoading && <Loader />}
      {isError && <Error error={error} />}
      {isSuccess && (
        <Card bg="dark1 text-light">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <SetPageTitle title="updateClient" />
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {t("created")}:{" "}
                {moment(data?.user?.organizationUser?.createdAt[0]).fromNow()}
                <br />
                {t("lastUpdated")}:{" "}
                {moment(data?.user?.organizationUser?.updatedAt).fromNow()}
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Formik
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={(values) => handleSubmit(values)}
              initialValues={initialValues}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur, //Validation is working on onBlur
                values,
                touched,
                errors,
                setFieldValue,
                setValues,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="add-inner-form"
                >
                  {/*****************
                   * Step 1
                   *****************/}
                  <fieldset className="p-3 mb-3">
                    <legend className="scheduler-border">
                      Step 1: {t("signup.credentials")}
                    </legend>
                    <Row>
                      <Form.Group
                        as={Col}
                        className="mb-3"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>{t("form.email")} *</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={t("form.emailPlaceholder")}
                          name="email"
                          autoComplete="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.email && !!errors.email}
                          disabled={true}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.email}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.typology")} *</Form.Label>
                        <Form.Select
                          name="type"
                          onChange={handleChange}
                          value={values.type}
                          onBlur={handleBlur}
                          isInvalid={touched.type && !!errors.type}
                          size="sm"
                        >
                          <option value="" disabled>
                            {t("form.select")}
                          </option>
                          <option value="PRIVATE">{t("form.private")}</option>
                          <option value="COMPANY">{t("form.company")}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.type}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.taxId")} *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("form.taxId")}
                          name="taxCode"
                          value={values.taxCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.taxCode && !!errors.taxCode}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.taxCode}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.pec")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("form.pec")}
                          name="pec"
                          value={values.pec}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.pec && !!errors.pec}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.pec}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    {values.type === "COMPANY" && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("form.vat")} *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("form.vat")}
                          name="vatNumber"
                          value={values.vatNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.vatNumber && !!errors.vatNumber}
                          size="sm"
                        />
                      </Form.Group>
                    )}
                  </fieldset>

                  {/*****************
                   * Step 2
                   *****************/}
                  <fieldset className="p-3 mb-3">
                    <legend className="scheduler-border">
                      Step 2: {t("signup.personalData")}
                    </legend>
                    <Row>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.firstname")} *</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder={t("form.firstname")}
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.name && !!errors.name}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.name}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.lastname")} *</Form.Label>
                        <Form.Control
                          type="text"
                          name="surName"
                          placeholder={t("form.lastname")}
                          value={values.surName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.surName && !!errors.surName}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.surName}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.dob")} *</Form.Label>
                        <Form.Control
                          type="date"
                          name="dateOfBirth"
                          placeholder={t("form.dob")}
                          value={values.dateOfBirth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.dateOfBirth && !!errors.dateOfBirth
                          }
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.dateOfBirth}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.sex")} *</Form.Label>
                        <Form.Select
                          name="sex"
                          value={values.sex}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.sex && !!errors.sex}
                          size="sm"
                        >
                          <option value="" disabled>
                            {t("form.select")}
                          </option>
                          <option value="MALE">{t("form.man")}</option>
                          <option value="FEMALE">{t("form.woman")}</option>
                          <option value="NOT DECLARED">{t("form.nd")}</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.sex}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.birthPlace")} *</Form.Label>
                        <Form.Control
                          type="text"
                          name="placeOfBirth"
                          value={values.placeOfBirth}
                          placeholder={t("form.birthPlace")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.placeOfBirth && !!errors.placeOfBirth
                          }
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.placeOfBirth}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.birthCountry")} *</Form.Label>
                        <Form.Select
                          name="provinceOfBirth"
                          value={values.provinceOfBirth}
                          placeholder={t("form.birthCountry")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.provinceOfBirth && !!errors.provinceOfBirth
                          }
                          size="sm"
                        >
                          <option value="" disabled>
                            {t("form.select")}
                          </option>
                          {countries.map((country, i) => (
                            <option key={i} value={country.value}>
                              {country.label}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.provinceOfBirth}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.mobileNumber")} *</Form.Label>
                        <PhoneInput
                          international
                          defaultCountry="IT"
                          name="mobilePhone"
                          // onChange={(value) => setFieldValue("mobilePhone", value)}
                          value={values.mobilePhone}
                          required={true}
                          disabled={true}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("telephone")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="telephone"
                          value={values.telephone}
                          placeholder={t("form.telephone")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.telephone && !!errors.telephone}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.telephone}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </fieldset>

                  {/*****************
                   * Step 3
                   *****************/}
                  <fieldset className="p-3 mb-3">
                    <legend className="scheduler-border">
                      Step 3: {t("signup.residence")}
                    </legend>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("form.address")} *</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={values.address}
                        placeholder={t("form.address")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.address && !!errors.address}
                        size="sm"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(`error.${errors.address}`)}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.country")} *</Form.Label>
                        <Form.Select
                          name="province"
                          value={values.province}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.province && !!errors.province}
                          size="sm"
                        >
                          <option value="" disabled>
                            {t("form.select")}
                          </option>
                          {countries.map((country, i) => (
                            <option key={i} value={country.label}>
                              {country.label}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.province}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.city")} *</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          value={values.city}
                          placeholder={t("form.city")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.city && !!errors.city}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.city}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.postcode")} *</Form.Label>
                        <Form.Control
                          type="text"
                          name="postcode"
                          value={values.postcode}
                          placeholder={t("form.postcode")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.postcode && !!errors.postcode}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.postcode}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </fieldset>

                  {/*****************
                   * Step 4
                   *****************/}
                  <fieldset className="p-3 mb-3">
                    <legend className="scheduler-border">
                      Step 4: {t("signup.domicile")}
                    </legend>
                    <Form.Group className="mt-3">
                      <Form.Check
                        type="switch"
                        label={t("sameAsDomicile")}
                        className="mb-3"
                        onChange={(e) =>
                          handleSameAsDomicile(e, values, setValues)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("form.address")} *</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressHome"
                        value={values.addressHome}
                        placeholder={t("form.address")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.addressHome && !!errors.addressHome}
                        size="sm"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(`error.${errors.addressHome}`)}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.country")} *</Form.Label>
                        <Form.Select
                          name="provinceHome"
                          value={values.provinceHome}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.provinceHome && !!errors.provinceHome
                          }
                          size="sm"
                        >
                          <option value="" disabled>
                            {t("form.select")}
                          </option>
                          {countries.map((country, i) => (
                            <option key={i} value={country.label}>
                              {country.label}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.provinceHome}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.city")} *</Form.Label>
                        <Form.Control
                          type="text"
                          name="cityHome"
                          value={values.cityHome}
                          placeholder={t("form.city")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.city && !!errors.city}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.cityHome}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3">
                        <Form.Label>{t("form.postcode")} *</Form.Label>
                        <Form.Control
                          type="text"
                          name="postcodeHome"
                          value={values.postcodeHome}
                          placeholder={t("form.postcode")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.postcode && !!errors.postcode}
                          size="sm"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(`error.${errors.postcodeHome}`)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </fieldset>
                  <Button variant="primary-outline" type="submit">
                    Save
                  </Button>
                  <Link
                    className="btn btn-light-outline ms-2"
                    to={`/${lang}/clients/${userId}`}
                  >
                    Cancel
                  </Link>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      )}
    </Row>
  );
};

export default UpdateClient;
