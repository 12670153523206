import React from "react";
import { Badge, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatNumberToLocal } from "../../helpers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useParams } from "react-router-dom";

const HeliorPlanDetails = ({ plans }) => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <Card bg="dark1">
      <Card.Header>
        <h6 className="text-light">Helior Plan</h6>
      </Card.Header>
      <Card.Body>
        <DataTable
          value={plans}
          sortMode="multiple"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          size={"small"}
          showGridlines
          stripedRows
          removableSort
        >
          <Column
            sortable
            field="contract_date"
            header={t("date")}
            body={(rowData) => rowData?.contract_date?.split("T")[0]}
          />
          <Column sortable field="contract_code" header={t("contractNumber")} />
          <Column
            sortable
            field="periodicity_of_amount"
            header={t("periodicity")}
            body={(rowData) => t(`${rowData.periodicity_of_amount}`)}
          />
          <Column
            sortable
            field="payment_amount"
            header={t("paymentAmount2")}
            body={(rowData) => formatNumberToLocal(rowData?.payment_amount)}
          />
          <Column
            sortable
            field="number_of_recurring_payments"
            header={t("initialPayment")}
            body={(rowData) => rowData?.number_of_recurring_payments}
          />
          <Column
            sortable
            field="doc_share"
            header={t("quotaDoc")}
            body={(rowData) => rowData?.doc_share}
          />
          <Column
            sortable
            header={t("status")}
            body={(rowData) => (
              <Badge
                bg={`${rowData.status === "Active" ? "success" : "secondary"}`}
              >
                {rowData.status === "Active" ? t("active") : t("inactive")}
              </Badge>
            )}
          ></Column>
          <Column
            body={(rowData) => (
              <Link
                to={`/${lang}/plan/helior-plan/${rowData.contract_code}`}
                className="btn btn-outline-light btn-sm"
              >
                <i className="fa fa-eye" />
              </Link>
            )}
          />
        </DataTable>
      </Card.Body>
    </Card>
  );
};

export default HeliorPlanDetails;
