import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../app/features/DataSlice";
import { useGetProfileQuery } from "../../app/features/apiSlice";
import { Card, Col, Row } from "react-bootstrap";
import Loader from "../../components/Loader";
import Error from "../../components/Error";
import HeliorPlanDetails from "../../components/HeliorPlan/HeliorPlanDetails";
import HOneDetails from "../../components/HeliorOne/HOneDetails";
import { Link, useParams } from "react-router-dom";
import UserCard from "../../components/UserCard";

const Profile = () => {
  const pageTitle = "profile";
  const { t } = useTranslation();
  const { lang } = useParams();
  const dispatch = useDispatch();
  const sessionId = useRef(Date.now()).current;
  const { data, isLoading, isSuccess, isError, error } = useGetProfileQuery({
    sessionId,
  });

  useEffect(() => {
    dispatch(setPageTitle(t(`${pageTitle}`)));
    // eslint-disable-next-line
  }, [t]);

  return (
    <Row className="my-5">
      {isLoading && <Loader />}
      {isError && <Error error={error} />}
      {isSuccess && (
        <>
          <Col md="12" sm="12" className="text-end">
            <Link
              variant="icon-button"
              to={`/${lang}/profile/update/${data?.user?.id}`}
            >
              <i className="fa fa-pen mx-3 text-light" />
            </Link>
          </Col>
          <Col lg={3}>
            <UserCard userData={data} />
          </Col>
          <Col lg={9}>
            <Card bg="dark1 text-light">
              <Card.Body>
                <Row className="py-3">
                  <Col lg={4}>
                    <h6 className="text-silverBlue">
                      {t("signup.personalData")}
                    </h6>
                    <ul className="list-unstyled user-data my-3">
                      <li className="text-unset">
                        <span className="text-uppercase">{t("email")}:</span>{" "}
                        {data?.user?.email}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("form.mobileNumber")}:
                        </span>{" "}
                        {data?.user?.phone}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("form.telephone")}:
                        </span>{" "}
                        {data?.orgUser?.telephone}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">{t("dob")}:</span>{" "}
                        {data?.orgUser?.date_of_birth}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">{t("form.sex")}:</span>{" "}
                        {data?.orgUser?.sex}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("form.typology")}:
                        </span>{" "}
                        {data?.orgUser?.type}
                      </li>
                      {data?.orgUser?.type === "COMPANY" && (
                        <li className="text-unset">
                          <span className="text-uppercase">
                            {t("form.vat")}:
                          </span>{" "}
                          {data?.orgUser?.vat_number}
                        </li>
                      )}
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("form.taxId")}:
                        </span>{" "}
                        {data?.orgUser?.tax_code}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">{t("form.pec")}:</span>{" "}
                        {data?.orgUser?.PEC}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("form.affiliateCode")}:{" "}
                        </span>
                        {data?.orgUser?.affiliate_code}
                      </li>
                      {data?.user?.role_id === 10 && (
                        <li className="text-unset">
                          <span className="text-uppercase">
                            {t("referralCode")}:{" "}
                          </span>
                          {data?.orgUser?.referral_code}
                        </li>
                      )}
                    </ul>
                  </Col>
                  <Col lg={4}>
                    <h6 className="text-silverBlue">{t("signup.residence")}</h6>
                    <ul className="list-unstyled user-data my-3">
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("deliveryAddress")}:
                        </span>{" "}
                        {data?.orgUser?.address}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("provinceDelivery")}:
                        </span>{" "}
                        {data?.orgUser?.province}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("cityDelivery")}:
                        </span>{" "}
                        {data?.orgUser?.city}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("postalAddress")}:
                        </span>{" "}
                        {data?.orgUser?.postcode}
                      </li>
                    </ul>
                  </Col>
                  <Col lg={4}>
                    <h6 className="text-silverBlue">{t("signup.domicile")}</h6>
                    <ul className="list-unstyled user-data my-3">
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("deliveryAddress")}:
                        </span>{" "}
                        {data?.orgUser?.address_home}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("provinceDelivery")}:
                        </span>{" "}
                        {data?.orgUser?.province_home}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("cityDelivery")}:
                        </span>{" "}
                        {data?.orgUser?.city_home}
                      </li>
                      <li className="text-unset">
                        <span className="text-uppercase">
                          {t("postalAddress")}:
                        </span>{" "}
                        {data?.orgUser?.postcode_home}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {data?.plan.length > 0 && (
            <Col className="mt-3" md="12">
              <HeliorPlanDetails plans={data?.plan} />
            </Col>
          )}
          {data?.heliorOne.length > 0 && (
            <Col className="mt-3" md="12">
              <HOneDetails plans={data?.heliorOne} />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default Profile;
