import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useResetPasswordMutation } from "../app/features/apiSlice";
import { Form, Button, Col } from "react-bootstrap";
import { swalError, swalSuccess } from "../helpers";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import AuthLogo from "../components/AuthLogo";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { lang, token } = useParams();
  const navigate = useNavigate();

  const formValidator = (values) => {
    const errors = {};
    const passwordRegExp =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/i;

    if (values.password && !passwordRegExp.test(values.password)) {
      errors.password = "invalidPassword";
    }

    if (values.confirmPassword && values.confirmPassword !== values.password) {
      errors.confirmPassword = "passwordMismatch";
    }

    return errors;
  };

  const [resetPassword] = useResetPasswordMutation();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: (values) => formValidator(values),
    onSubmit: async (values) => {
      try {
        const data = {
          token: token,
          newPassword: values.password,
          language: lang,
        };
        const response = await resetPassword(data).unwrap();
        if (response?.error) {
          swalError(response, t("error.serverNotResponding"));
        } else {
          swalSuccess(response?.message)
          navigate(`/${lang}/login`);
        }
      } catch (err) {
        swalError(err, t("error.serverNotResponding"));
      }
    },
  });

  return (
    <Col lg="4" md="8" sm="12">
      <AuthLogo title="resetPassword" />
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>{t("password")}</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder={t("form.passwordPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.password}
            isInvalid={formik.errors.password}
            required
          />
          <Form.Control.Feedback type="invalid">
            <i className="fa fa-info-circle"></i> {t("form.characters")}
            <br />
            {t("form.useLetterNumberCharacter")}
            <br />
            {t("form.specialCharacters")}:{" "}
            <span aria-label="exclamation mark">!</span>{" "}
            <span aria-label="at symbol">@</span>{" "}
            <span aria-label="hashtag">#</span>{" "}
            <span aria-label="dollar sign">$</span>{" "}
            <span aria-label="percent">%</span>
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t("form.confirmPassword")}</Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            placeholder={t("form.confirmPasswordPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            isInvalid={formik.errors.confirmPassword}
            required
          />
          <Form.Control.Feedback type="invalid">
            {t("error.passwordMismatch")}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="btn-block btn-primary mt-3 mb-1 py-2"
        >
          {t("submit")}
        </Button>
      </Form>
      <p className="m-0 mt-3 text-center">
        {t("haveAccount")}?{" "}
        <Link to={`/${lang}/login`} className="text-gold">
          {t("login")}
        </Link>
      </p>
      <p className="text-center">
        {t("noAccount")}{" "}
        <Link to={`/${lang}/signup`} className="text-gold">
          {t("noAccountLink")}
        </Link>
      </p>
    </Col>
  );
};

export default ResetPassword;
