import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import locationData from "../../countries.json";

const PersonalData = ({ formik }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const data = locationData.map((country) => ({
      label: country.name,
      value: country.iso3,
    }));
    setCountries(data);
  }, []);

  return (
    <>
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.firstname")} *</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder={t("form.firstname")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            isInvalid={formik.touched.name && !!formik.errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.name}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.lastname")} *</Form.Label>
          <Form.Control
            type="text"
            name="surName"
            placeholder={t("form.lastname")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.surName}
            isInvalid={formik.touched.surName && !!formik.errors.surName}
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.surName}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.dob")} *</Form.Label>
          <Form.Control
            type="date"
            name="dateOfBirth"
            placeholder={t("form.dob")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dateOfBirth}
            isInvalid={
              formik.touched.dateOfBirth && !!formik.errors.dateOfBirth
            }
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.dateOfBirth}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.sex")} *</Form.Label>
          <Form.Select
            name="sex"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.sex}
            isInvalid={formik.touched.sex && !!formik.errors.sex}
          >
            <option value="" disabled>
              {t("form.select")}
            </option>
            <option value="MALE">{t("form.man")}</option>
            <option value="FEMALE">{t("form.woman")}</option>
            <option value="NOT DECLARED">{t("form.nd")}</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.sex}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.birthPlace")} *</Form.Label>
          <Form.Control
            type="text"
            name="placeOfBirth"
            placeholder={t("form.birthPlace")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.placeOfBirth}
            isInvalid={
              formik.touched.placeOfBirth && !!formik.errors.placeOfBirth
            }
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.placeOfBirth}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.birthCountry")} *</Form.Label>
          <Form.Select
            name="provinceOfBirth"
            placeholder={t("form.birthCountry")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.provinceOfBirth}
            isInvalid={
              formik.touched.provinceOfBirth && !!formik.errors.provinceOfBirth
            }
          >
            <option value="" disabled>
              {t("form.select")}
            </option>
            {countries.map((country, i) => (
              <option key={i} value={country.value}>
                {country.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.provinceOfBirth}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.mobileNumber")} *</Form.Label>
          <PhoneInput
            international
            defaultCountry="US"
            name="mobilePhone"
            onChange={(value) => formik.setFieldValue("mobilePhone", value)}
            onBlur={formik.handleBlur}
            value={formik.values.mobilePhone}
            required={true}
            className={
              formik.errors.mobilePhone && formik.touched.mobilePhone
                ? "is-invalid"
                : ""
            }
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik?.errors?.mobilePhone}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("telephone")}</Form.Label>
          <Form.Control
            type="text"
            name="telephone"
            placeholder={t("form.telephone")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.telephone}
          />
        </Form.Group>
      </Row>
    </>
  );
};

export default PersonalData;
