import React from "react";
import { Container, Navbar } from "react-bootstrap";
import SwitchLanguage from "../SwitchLanguage";

const SmHeader = () => {
  return (
    <Navbar fixed="top" className="navbar-transparent">
      <Container fluid>
        <Navbar.Brand></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <SwitchLanguage />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default SmHeader;
