import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    headers.set("organizationid", process.env.REACT_APP_ORG_ID);
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "helior",
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/user/excelior-login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: "/user/excelior-signup",
        method: "POST",
        body: { ...data },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/user/forgot-password",
        method: "POST",
        body: { ...data },
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "/user/reset-password",
        method: "POST",
        body: { ...data },
      }),
    }),
    checkEmailExists: builder.mutation({
      query: (data) => ({
        url: "/user/user-exist",
        method: "POST",
        body: { ...data },
      }),
    }),
    getRoles: builder.query({
      query: () => "/user/get-userRole",
    }),
    activateAccount: builder.mutation({
      query: (data) => ({
        url: "/user/account-activation",
        method: "POST",
        body: { ...data },
      }),
    }),
    getProfile: builder.query({
      query: () => "/user/user-detail",
    }),
    getHeliorPlan: builder.query({
      query: () => `/crm/helior-plan`,
    }),
    getHeliorOne: builder.query({
      query: () => `/crm/helior-one/`,
    }),
    getPaymentPeriodicity: builder.query({
      query: ({ planId, typeId }) =>
        `/crm/admin/payment-periodicity/${planId}?typeId=${typeId}`,
    }),
    purchaseHeliorPlan: builder.mutation({
      query: ({ data, planId }) => ({
        url: `/crm/helior-plan/purchase-plan/${planId}`,
        method: "POST",
        body: { ...data },
      }),
    }),
    payRecurringAmount: builder.mutation({
      query: ({ data, contractCode }) => ({
        url: `/crm/helior-plan/add-instalment/${contractCode}`,
        method: "POST",
        body: { ...data },
      }),
    }),
    getHeliorPlanDetails: builder.query({
      query: ({ planId }) => `/crm/helior-plan/${planId}`,
    }),
    getContractDetails: builder.query({
      query: ({ contractCode }) => `/crm/helior-plan/user/${contractCode}`,
    }),
    getPlanDetail: builder.mutation({
      query: (contractCode) => ({
        url: `/crm/helior-plan/user/${contractCode}`,
        method: "GET",
      }),
    }),
    getStaticValue: builder.query({
      query: () => `/crm/get-static-value`,
    }),
    getValue: builder.query({
      query: () => `/crm/admin/value`,
    }),
    purchaseHeliorOne: builder.mutation({
      query: ({ data, planId }) => ({
        url: `/crm/helior-one/purchase/${planId}`,
        method: "POST",
        body: { ...data },
      }),
    }),
    getSingleHOne: builder.query({
      query: ({ contractCode }) => `/crm/helior-one/${contractCode}`,
    }),
    getHOneDetail: builder.mutation({
      query: (contractCode) => ({
        url: `/crm/helior-one/${contractCode}`,
        method: "GET",
      }),
    }),
    sendReferralLink: builder.mutation({
      query: (data) => ({
        url: `/crm/collaborator/referral-link`,
        method: "POST",
        body: { ...data },
      }),
    }),
    getDownlineCollaborator: builder.query({
      query: () => `/crm/collaborator/down-line-collaborator`,
    }),
    getCollaborators: builder.query({
      query: () => `/crm/collaborator/refer-collaborator`,
    }),
    getUserDetail: builder.query({
      query: ({ userId }) => `/crm/admin/user/${userId}`,
    }),
    updateUser: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/crm/admin/user/${userId}`,
        method: "POST",
        body: { ...data },
      }),
    }),
    getSingleCollaborator: builder.query({
      query: ({ userId }) => `/crm/admin/collaborator/${userId}`,
    }),
    getReferralCode: builder.query({
      query: () => `/crm/collaborator/referral-code`,
    }),
    getClients: builder.query({
      query: () => `/crm/collaborator/clients`,
    }),
    checkAffiliateCode: builder.mutation({
      query: (data) => ({
        url: `/user/referral-code-exist`,
        method: "POST",
        body: { ...data },
      }),
    }),
    getDiscount: builder.query({
      query: () => `/crm/discount/`,
    }),
    createDiscount: builder.mutation({
      query: (data) => ({
        url: `/crm/discount/`,
        method: "POST",
        body: { ...data },
      }),
    }),
    updateDiscount: builder.mutation({
      query: ({ data, discountId }) => ({
        url: `/crm/discount/${discountId}`,
        method: "POST",
        body: { ...data },
      }),
    }),
    deleteDiscount: builder.mutation({
      query: ({ id }) => ({
        url: `/crm/discount/${id}`,
        method: "DELETE",
      }),
    }),
    getQualification: builder.query({
      query: ({ collabId }) => `crm/collaborator/qualification/${collabId}`,
    }),
    getCommissions: builder.query({
      query: () => `/crm/collaborator/commission`,
    }),
    requestForPayout: builder.mutation({
      query: (data) => ({
        url: `/crm/collaborator/request-payout`,
        method: "POST",
        body: { ...data },
      }),
    }),
    getRequestPayouts: builder.query({
      query: () => `/crm/collaborator/request-payout`,
    }),
    collabDashboad: builder.query({
      query: () => `/crm/collaborator/dashboard`,
    }),

    /***
     * Updated Collaborator Screens Endpoints
    ***/
    newCollabDashboard: builder.query({
      query: () => `/crm/collaborator/dash-board`,
    })
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useCheckEmailExistsMutation,
  useGetRolesQuery,
  useActivateAccountMutation,
  useGetProfileQuery,
  useGetHeliorOneQuery,
  useGetHeliorPlanQuery,
  useGetPaymentPeriodicityQuery,
  usePurchaseHeliorPlanMutation,
  usePayRecurringAmountMutation,
  useGetHeliorPlanDetailsQuery,
  useGetContractDetailsQuery,
  useGetPlanDetailMutation,
  useGetStaticValueQuery,
  useGetValueQuery,
  usePurchaseHeliorOneMutation,
  useGetSingleHOneQuery,
  useGetHOneDetailMutation,
  useSendReferralLinkMutation,
  useGetDownlineCollaboratorQuery,
  useGetCollaboratorsQuery,
  useGetUserDetailQuery,
  useUpdateUserMutation,
  useGetSingleCollaboratorQuery,
  useGetReferralCodeQuery,
  useGetClientsQuery,
  useCheckAffiliateCodeMutation,
  useGetDiscountQuery,
  useCreateDiscountMutation,
  useUpdateDiscountMutation,
  useDeleteDiscountMutation,
  useGetQualificationQuery,
  useGetCommissionsQuery,
  useRequestForPayoutMutation,
  useGetRequestPayoutsQuery,
  useCollabDashboadQuery,
  /***
   * Updated Collaborator Screens Endpoints
  ***/
 useNewCollabDashboardQuery
} = apiSlice;
