import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { formatNumberToLocal } from "../../helpers";
import { Badge, Card } from "react-bootstrap";

const ContractDetailTable = ({ payments }) => {
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState(null);

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-2 text-start bg-dark">
        {data?.commission?.length > 0 && (
          <Card className="mt-2" bg="dark1 text-light">
            <Card.Header className="text-uppercase">
              {t("provisions")}
            </Card.Header>
            <Card.Body>
              <DataTable
                value={data?.commission}
                size={"small"}
                showGridlines
                stripedRows
              >
                <Column field="role" header={t("role")} />
                <Column
                  field="firstName"
                  header={t("collaborator")}
                  body={(rowData) =>
                    rowData.user?.first_name
                      ? `${
                          rowData.user?.first_name +
                          " " +
                          rowData.user?.last_name
                        }`
                      : "-"
                  }
                />
                <Column
                  field="percentage"
                  header={t("percentage")}
                  body={(rowData) => `${(rowData.percent * 100).toFixed(2)}%`}
                />
                <Column field="amount" header={t("compensationAmount")} />
                <Column field="overFromNet" header={t("overFromNet")} />
              </DataTable>
            </Card.Body>
          </Card>
        )}
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData?.commission?.length > 0;
  };

  return (
    <Card bg="dark1 text-light">
      <Card.Body>
        <DataTable
          value={payments}
          sortMode="multiple"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          size={"small"}
          showGridlines
          stripedRows
          removableSort
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander={allowExpansion} />
          <Column
            sortable
            header={t("date")}
            body={(rowData) => rowData.issue_date.split("T")[0]}
          ></Column>
          <Column
            sortable
            field="gold_fixing_per_gram"
            header={t("fixings")}
            body={(rowData) =>
              formatNumberToLocal(rowData.gold_fixing_per_gram)
            }
          ></Column>
          <Column
            sortable
            field="movement_type"
            header={t("type")}
            body={(rowData) => t(`${rowData.movement_type}`)}
          ></Column>
          <Column
            sortable
            field="amount_paid"
            header={t("amountPaid")}
          ></Column>
          <Column
            sortable
            field="manufacturing_charge_for_ingots"
            header={t("manufacturing")}
          ></Column>
          <Column
            sortable
            field="price_per_gram_delivery"
            header={t("pricePerGrDelivery")}
            body={(rowData) =>
              formatNumberToLocal(rowData.price_per_gram_delivery)
            }
          ></Column>
          <Column
            sortable
            field="grams_paid"
            header={t("grPaid")}
            body={(rowData) => formatNumberToLocal(rowData.grams_paid)}
          ></Column>
          <Column
            sortable
            field="progressive_total_grams_paid"
            header={t("progGrPaid")}
            body={(rowData) =>
              formatNumberToLocal(rowData.progressive_total_grams_paid)
            }
          ></Column>
          <Column
            sortable
            field="grams_accumulated_for_the_next_ingot"
            header={t("grAccumulatedUntilTheNextIngot")}
            body={(rowData) =>
              formatNumberToLocal(rowData.grams_accumulated_for_the_next_ingot)
            }
          ></Column>
          <Column sortable field="number_of_ingot" header={t("bars")}></Column>
          <Column
            field="payment_status"
            body={(rowData) => (
              <>
                {(() => {
                  if (rowData.payment_status === "In-Progress") {
                    return <Badge>{t(`${rowData.payment_status}`)}</Badge>;
                  } else if (rowData.payment_status === "Completed") {
                    return (
                      <Badge bg="gold">{t(`${rowData.payment_status}`)}</Badge>
                    );
                  } else if (rowData.payment_status === "Failed") {
                    return (
                      <Badge bg="danger">
                        {t(`${rowData.payment_status}`)}
                      </Badge>
                    );
                  } else {
                    return (
                      <Badge bg="secondary">
                        {t(`${rowData.payment_status}`)}
                      </Badge>
                    );
                  }
                })()}
              </>
            )}
          ></Column>
        </DataTable>
      </Card.Body>
    </Card>
  );
};

export default ContractDetailTable;
