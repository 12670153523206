import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useGetSingleCollaboratorQuery } from "../../../app/features/apiSlice";
import { Col, Row } from "react-bootstrap";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import "moment/locale/it";
import ClientListTable from "../../../components/ClientListTable";
import SetPageTitle from "../../../components/SetPageTitle";
import ProfileCard from "../../../components/ProfileCard";

const ReadCollaborator = () => {
  const { t } = useTranslation();
  const { userId, lang } = useParams();
  const [tableData, setTableData] = useState([]);
  lang === "it" ? moment.locale("it") : moment.locale("en");
  const sessionId = useRef(Date.now()).current;
  const { data, isLoading, isSuccess, isError, error } =
    useGetSingleCollaboratorQuery({
      sessionId,
      userId,
    });

  useEffect(() => {
    if (isSuccess) {
      const items = data?.clients;
      const tableItem = [];
      let i = 1;
      if (items.length > 0) {
        items.forEach((item) => {
          tableItem.push({
            sno: i,
            name: `${item?.customer?.first_name} ${item?.customer?.last_name}`,
            email: item?.customer?.email,
            phone: item?.customer?.phone,
            id: item?.customer?.id,
            status: item?.customer?.organizationUser?.status,
          });
          i++;
        });
      }
      setTableData(tableItem);
    }
  }, [isSuccess, data]);

  return (
    <Row className="my-4">
      {isLoading && <Loader />}
      {isError && <Error error={error} />}
      {isSuccess && (
        <>
          <SetPageTitle
            title={`${data?.user?.first_name} ${data?.user?.last_name}`}
            display={false}
          />
          <Col
            md="12"
            sm="12"
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              {t("created")}:{" "}
              {moment(data?.user?.organizationUser?.createdAt[0].startDate).fromNow()}
              <br />
              {t("lastUpdated")}:{" "}
              {moment(data?.user?.organizationUser?.updatedAt).fromNow()}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Link
                variant="icon-button"
                to={`/${lang}/collaborator/update/${userId}`}
              >
                <i className="fa fa-pen mx-3 text-light" />
              </Link>
            </div>
          </Col>
          <ProfileCard data={data} />
          {data.clients.length > 0 && (
            <Col md={12} className="mt-3">
              <ClientListTable tableData={tableData} />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default ReadCollaborator;
