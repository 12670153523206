import React from 'react'

const Footer = () => {
  return (
    <footer className='bg-dark1'>
      <span className='text-light'>© 2023 Banco Metalli Golden Age Srl - P. IVA 02689550420</span>
    </footer>
  )
}

export default Footer