import React from "react";
import { Col, Row, Tab, Nav, Card, Image } from "react-bootstrap";
import PerformerCard from "./PerformerCard";
import Performer from "./Performer";
import { useTranslation } from "react-i18next";
import UpcomingTeamManager from "./UpcomingTeamManager";
import { Link, useParams } from "react-router-dom";

const TeamPerformance = ({ data }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  return (
    <Row className="teamPerformance">
      <Col md="9">
        <Card bg="dark1" className="h-100">
          <Card.Header className="text-light text-uppercase">
            {t("teamPerformance")}
          </Card.Header>
          <Card.Body>
            <Tab.Container defaultActiveKey="topPerformers">
              <Row className="h-100">
                <Col sm={3} className="h-100">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="topPerformers">
                        {t("topPerformers")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="topRecruiters">
                        {t("topRecruiters")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="upcomingTeamManagers">
                        {t("upcomingTeamManagers")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="lowPerformers">
                        {t("lowPerformers")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9} className="h-100">
                  <Row className="h-100 align-content-between">
                    <Col md="12">
                      <Tab.Content>
                        <Tab.Pane eventKey="topPerformers">
                          {data.topPerformers.length > 0 ? (
                            <>
                              {data?.topPerformers.map((team, i) => (
                                <PerformerCard user={team} key={i} />
                              ))}
                            </>
                          ) : (
                            <p className="text-light">{t("noRecordFound")}</p>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="topRecruiters">
                          {data.topRecruiters.length > 0 ? (
                            <>
                              {data?.topRecruiters.map((team, i) => (
                                <PerformerCard user={team} key={i} />
                              ))}
                            </>
                          ) : (
                            <p className="text-light">{t("noRecordFound")}</p>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="upcomingTeamManagers">
                          {data.upcomingTeamManagers.length > 0 ? (
                            <>
                              {data?.upcomingTeamManagers.map((team, i) => (
                                <UpcomingTeamManager user={team} key={i} />
                              ))}
                            </>
                          ) : (
                            <p className="text-light">{t("noRecordFound")}</p>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="lowPerformers">
                          {data.lowPerformers.length > 0 ? (
                            <>
                              {data?.lowPerformers.map((team, i) => (
                                <PerformerCard user={team} key={i} />
                              ))}
                            </>
                          ) : (
                            <p className="text-light">{t("noRecordFound")}</p>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                    <Col md="12">
                      <h6 className="text-light text-uppercase">
                        {t("myTeam")}
                      </h6>
                      <ul className="list-unstyled d-flex justify-content-between text-light">
                        <li>
                          {t("direct")}:{" "}
                          {data?.memberCount?.directCollaboratorCount}
                        </li>
                        <li>
                          {t("first2Levels")}:{" "}
                          {data?.memberCount?.firstTwoLevelCollaboratorCount}
                        </li>
                        <li>
                          {t("totalTeam")}: {data?.memberCount?.teamMemberCount}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Col>
      <Col md="3">
        <Card bg="dark1" className="mb-3">
          <Card.Header className="text-light text-uppercase">
            {t("newMembers")}
          </Card.Header>
          <Card.Body>
            {data.newMembers.length > 0 ? (
              <>
                {data?.newMembers.map((team, i) => (
                  <div
                    className="d-flex align-items-center justify-content-between mb-3"
                    key={i}
                  >
                    <div className="d-flex align-items-center">
                      <Image src="/man.png" alt="Man" height={30} />
                      <Link
                        to={`/${lang}/collaborator/${team?.user_id}`}
                        className="ms-3 text-light text-decoration-none"
                      >
                        {team?.user?.first_name} {team?.user?.last_name}
                      </Link>
                    </div>
                    <b>{team?.referral_code}</b>
                  </div>
                ))}
              </>
            ) : (
              <p className="text-light">{t("noRecordFound")}</p>
            )}
          </Card.Body>
        </Card>
        <Card bg="dark1" className="mb-3">
          <Card.Header className="text-light text-uppercase">
            {t("myTeamManager")}
          </Card.Header>
          <Card.Body>
            <Performer
              user={{
                user: {
                  role_id: 10,
                  _date_register: "2024-08-01T12:57:55.000Z",
                  first_name: "XXX",
                  last_name: "XXX",
                  email: "mohitcollab2@yopmail.com",
                  userRoles: {
                    type: "collaborator",
                  },
                },
                position: "1111111",
              }}
            />
          </Card.Body>
        </Card>
        <Card bg="dark1" className="mb-3">
          <Card.Header className="text-light text-uppercase">
            {t("myCloser")}
          </Card.Header>
          <Card.Body>
            <Performer
              user={{
                user: {
                  role_id: 10,
                  _date_register: "2024-08-01T12:57:55.000Z",
                  first_name: "XXX",
                  last_name: "XXX",
                  email: "mohitcollab2@yopmail.com",
                  userRoles: {
                    type: "collaborator",
                  },
                },
                position: "raman1996",
              }}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default TeamPerformance;
