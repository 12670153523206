import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import locationData from "../../countries.json";

const Domicile = ({ formik }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);

  const handleSameAsDomicile = (e) => {
    const { checked } = e.target;
    if (checked) {
      formik.setValues({
        ...formik.values,
        addressHome: formik.values.address,
        provinceHome: formik.values.province,
        cityHome: formik.values.city,
        postcodeHome: formik.values.postcode,
      });
    } else {
      formik.setValues({
        ...formik.values,
        addressHome: "",
        provinceHome: "",
        cityHome: "",
        postcodeHome: "",
      });
    }
  };

  useEffect(() => {
    const data = locationData.map((country) => country.name);
    setCountries(data);
  }, []);

  return (
    <>
      <Row>
        <Form.Group>
          <Form.Check
            type="switch"
            label={t("sameAsDomicile")}
            className="mb-3"
            onChange={(e) => handleSameAsDomicile(e)}
          />
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.address")} *</Form.Label>
          <Form.Control
            type="text"
            name="addressHome"
            placeholder={t("form.address")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.addressHome}
            isInvalid={
              formik.touched.addressHome && !!formik.errors.addressHome
            }
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.addressHome}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.country")} *</Form.Label>
          <Form.Select
            name="provinceHome"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.provinceHome}
            isInvalid={
              formik.touched.provinceHome && !!formik.errors.provinceHome
            }
          >
            <option value="" disabled>
              {t("form.select")}
            </option>
            {countries.map((country, i) => (
              <option key={i} value={country}>
                {country}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.provinceHome}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.city")} *</Form.Label>
          <Form.Control
            type="text"
            name="cityHome"
            placeholder={t("form.city")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cityHome}
            isInvalid={formik.touched.cityHome && !!formik.errors.cityHome}
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.cityHome}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.postcode")} *</Form.Label>
          <Form.Control
            type="text"
            name="postcodeHome"
            placeholder={t("form.postcode")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postcodeHome}
            isInvalid={
              formik.touched.postcodeHome && !!formik.errors.postcodeHome
            }
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.postcodeHome}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </>
  );
};

export default Domicile;
