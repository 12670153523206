import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import locationData from "../../countries.json";

const Residence = ({ formik }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const data = locationData.map((country) => country.name);
    setCountries(data);
  }, []);

  return (
    <>
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.address")} *</Form.Label>
          <Form.Control
            type="text"
            name="address"
            placeholder={t("form.address")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            isInvalid={formik.touched.address && !!formik.errors.address}
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.address}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.country")} *</Form.Label>
          <Form.Select
            name="province"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.province}
            isInvalid={formik.touched.province && !!formik.errors.province}
          >
            <option value="" disabled>
              {t("form.select")}
            </option>
            {countries.map((country, i) => (
              <option key={i} value={country}>
                {country}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.province}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.city")} *</Form.Label>
          <Form.Control
            type="text"
            name="city"
            placeholder={t("form.city")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            isInvalid={formik.touched.city && !!formik.errors.city}
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.city}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.postcode")} *</Form.Label>
          <Form.Control
            type="text"
            name="postcode"
            placeholder={t("form.postcode")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postcode}
            isInvalid={formik.touched.postcode && !!formik.errors.postcode}
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.postcode}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </>
  );
};

export default Residence;
