import React, { useState } from "react";
import { formatNumberToLocal } from "../../helpers";
import { Button, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

const HOneConfirmCard = ({ paymentData, stampDuty }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmPurchase = () => {
    setIsLoading(true);
    navigate(`/${lang}/payment-success`);
    setIsLoading(false);
  };
  return (
    <div className="px-5 py-2">
      <h6 className="mb-3">{t("summary")}</h6>
      <Table className="payment-table">
        <thead>
          <tr>
            <th></th>
            <th>{t("amount")}</th>
            <th>{t("guaranteeMargin")} (5%)</th>
            <th>{t("total")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("goldCost")}</td>
            <td>
              {formatNumberToLocal(paymentData?.purchase?.goldPrice) || 0} €
            </td>
            <td>
              {formatNumberToLocal(
                paymentData?.warranty?.goldPriceWithMargin
              ) || 0}{" "}
              €
            </td>
            <td>
              {formatNumberToLocal(
                paymentData?.purchase?.goldPrice +
                  paymentData?.warranty?.goldPriceWithMargin
              ) || 0}{" "}
              €
            </td>
          </tr>
          <tr>
            <td>
              {t("spreadCost")}{" "}
              {paymentData?.spreadValue > 0 && (
                <span>({paymentData?.spreadValue * 100}%)</span>
              )}{" "}
            </td>
            <td>
              {formatNumberToLocal(paymentData?.purchase?.cost_spread) || 0} €
            </td>
            <td>
              {formatNumberToLocal(
                paymentData?.warranty?.costSpreadWithMargin
              ) || 0}{" "}
              €
            </td>
            <td>
              {formatNumberToLocal(
                paymentData?.purchase?.cost_spread +
                  paymentData?.warranty?.costSpreadWithMargin
              ) || 0}{" "}
              €
            </td>
          </tr>
          <tr>
            <td>
              {t("storageCost")}{" "}
              {paymentData?.storageValue > 0 && (
                <span>({paymentData?.storageValue * 100}%)</span>
              )}{" "}
            </td>
            <td>
              {formatNumberToLocal(paymentData?.purchase?.cost_storage) || 0} €
            </td>
            <td>
              {formatNumberToLocal(
                paymentData?.warranty?.storageCostWithMargin
              ) || 0}{" "}
              €
            </td>
            <td>
              {formatNumberToLocal(
                paymentData?.purchase?.cost_storage +
                  paymentData?.warranty?.storageCostWithMargin
              ) || 0}{" "}
              €
            </td>
          </tr>
          <tr>
            <td>{t("manufacturingAmount")}</td>
            <td>
              {formatNumberToLocal(
                paymentData?.purchase?.totalManufacturingCost
              ) || 0}{" "}
              €
            </td>
            <td>-</td>
            <td>
              {formatNumberToLocal(
                paymentData?.purchase?.totalManufacturingCost
              ) || 0}{" "}
              €
            </td>
          </tr>
          <tr>
            <td>{t("total")}</td>
            <td>
              {formatNumberToLocal(paymentData?.purchase?.total_payment) || 0} €
            </td>
            <td>
              {formatNumberToLocal(
                paymentData?.warranty?.total_payment_with_margin
              ) || 0}{" "}
              €
            </td>
            <td>
              {formatNumberToLocal(
                paymentData?.purchase?.total_payment +
                  paymentData?.warranty?.total_payment_with_margin
              ) || 0}{" "}
              €
            </td>
          </tr>
        </tbody>
      </Table>
      {/* <hr /> */}
      <div className="px-1">
      <p className="d-flex justify-content-between mt-2 mb-1 text-uppercase">
        <span>{t("totalGrams")}</span> {paymentData.total_gr}gr
      </p>
      <p className="d-flex justify-content-between mb-1 text-uppercase">
        <span>{t("price")}</span>{" "}
        {formatNumberToLocal(paymentData?.purchase?.bank_transfer) || 0} €
      </p>
      <p className="d-flex justify-content-between text-uppercase">
        <span>{t("stampDuty")}</span> {formatNumberToLocal(stampDuty)} €
      </p>
      <hr />
      <p className="d-flex justify-content-between mb-5 text-uppercase">
        <span>{t("totalPayment")}</span>{" "}
        {formatNumberToLocal(paymentData?.purchase?.bank_transfer + stampDuty)}{" "}
        €
      </p>
      </div>
      <Button
        variant="primary-outline"
        className="w-100 d-flex align-items-center justify-content-center"
        onClick={handleConfirmPurchase}
      >
        {t("confirmPurchase")}
        {isLoading && <Spinner size="sm" className="ms-2" animation="grow" />}
      </Button>
    </div>
  );
};

export default HOneConfirmCard;
