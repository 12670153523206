import React, { useRef, useState } from "react";
import { Card, Button, Form, Badge } from "react-bootstrap";
import SetPageTitle from "../../../components/SetPageTitle";
import { useTranslation } from "react-i18next";
import RequestPayout from "../../../components/payout/RequestPayout";
import { useGetRequestPayoutsQuery } from "../../../app/features/apiSlice";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useParams } from "react-router";
import moment from "moment";
import "moment/locale/it";
import { formatNumberToLocal } from "../../../helpers";

const Payouts = () => {
  const dt = useRef();
  const { t } = useTranslation();
  const { lang } = useParams();
  lang === "it" ? moment.locale("it") : moment.locale("en");
  const sessionId = useRef(Date.now()).current;
  const exportCSV = () => dt.current.exportCSV();
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isLoading, isError, error } = useGetRequestPayoutsQuery({
    sessionId,
  });

  if (isLoading) return <Loader />;
  if (isError) return <Error error={error} />;

  return (
    <Card bg="dark1 text-light">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <SetPageTitle title="sidebar.payouts" />
        <div className="d-flex justify-content-between align-items-center">
          <RequestPayout />
          <Button
            variant="icon-button text-light"
            onClick={() => exportCSV(false)}
            data-pr-tooltip="CSV"
          >
            <i className="fa fa-download" />
          </Button>
          <Form.Control
            type="text"
            placeholder="Search"
            className=" mr-sm-2"
            size="sm"
            onInput={(e) => setGlobalFilter(e.target.value)}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <DataTable
          value={data?.data}
          sortMode="multiple"
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          ref={dt}
          size={"small"}
          showGridlines
          stripedRows
          removableSort
          globalFilter={globalFilter}
        >
          <Column
            field="amount"
            header={t("amount")}
            body={(rowData) => formatNumberToLocal(rowData.amount) + "€"}
          />
          <Column
            field="status"
            header={t("status")}
            body={(rowData) => rowData.status === "Completed" ? <Badge bg="success" >{t(`${rowData.status}`)}</Badge>: t(`${rowData.status}`)}
          />
          <Column field="note" header={t("note")} />
          <Column
            field="createdAt"
            header={t("createdAt")}
            body={(rowData) => moment(rowData.createdAt).fromNow()}
          />
          <Column
            field="updatedAt"
            header={t("updatedAt")}
            body={(rowData) => moment(rowData.updatedAt).fromNow()}
          />
          <Column />
        </DataTable>
      </Card.Body>
    </Card>
  );
};

export default Payouts;
