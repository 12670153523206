import React, { useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useCheckEmailExistsMutation,
  useSendReferralLinkMutation,
} from "../../../app/features/apiSlice";
import { useParams } from "react-router";
import { toastError, toastSuccess } from "../../../helpers";
import SetPageTitle from "../../../components/SetPageTitle";

const SendReferral = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [inputData, setInputData] = useState({ email: "", language: lang });
  const [isValid, setIsValid] = useState(false);

  const [checkEmailExists] = useCheckEmailExistsMutation();
  const handleOnBlur = async () => {
    if (!inputData.email) return false;

    const regExp = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    if (!regExp.test(inputData.email)) {
      toastError("", t(`error.invalidEmail`));
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const [sendReferralLink] = useSendReferralLinkMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      return false;
    }
    setIsLoading(true);
    try {
      const isExistsRes = await checkEmailExists({ email: inputData.email });
      if (isExistsRes?.error) {
        toastError(isExistsRes, t("error.serverNotResponding"));
      } else {
        if (isExistsRes.data.message === "exists") {
          toastError("", t(`error.emailAlreadyExists`));
          setIsValid(false);
        } else {
          const response = await sendReferralLink(inputData).unwrap();
          console.log(response);
          response?.error
            ? toastError(response, t("error.serverNotResponding"))
            : toastSuccess(response?.message);
        }
      }
    } catch (err) {
      toastError(err, t("error.serverNotResponding"));
    }
    setIsLoading(false);
  };

  return (
    <div className="my-3">
      <Card bg="dark1 text-light">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <SetPageTitle title="sendReferralLink" />
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("email")}</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={inputData.email}
                onChange={(e) =>
                  setInputData((prev) => ({ ...prev, email: e.target.value }))
                }
                onBlur={handleOnBlur}
              />
            </Form.Group>
            <Button
              type="submit"
              className="btn-block btn-primary-outline py-2"
            >
              {t("sendLink")}
              {isLoading && (
                <Spinner size="sm" className="ms-2" animation="grow" />
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SendReferral;
