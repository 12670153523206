import React from 'react'

const Loader = () => {
  return (
    <div className='d-flex justify-content-center'>
        <div className="shapes"></div>
    </div>
  )
}

export default Loader