import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../app/features/DataSlice";

const SetPageTitle = ({ title, display = "true" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(t(`${title}`)));
  });
  return <div className={display ? "d-block" : "d-none"}>{t(`${title}`)}</div>;
};

export default SetPageTitle;
