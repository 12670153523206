import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "data",
  initialState: { lang: "en", title: "Dashboard", user: [], paymentData: {} },
  reducers: {
    setDefaultLang: (state, action) => {
      state.lang = action.payload;
    },
    setPageTitle: (state, action) => {
      state.title = action.payload;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setPaymentData: (state, action) => {
      state.paymentData = action.payload;
    },
    setResetData: (state, action) => {
      state.lang = "en";
      state.title = "";
      state.user = [];
      state.paymentData = [];
    }
  },
});

export const { setDefaultLang, setPageTitle, setUserData, setPaymentData, setResetData } = dataSlice.actions;

export default dataSlice.reducer;

export const selectDefaultLang = (state) => state.data.lang
export const selectPageTitle = (state) => state.data.title
export const selectUserData = (state) => state.data.user
export const selectPaymentData = (state) => state.data.paymentData