import React from "react";
import { Badge, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatNumberToLocal } from "../../helpers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useParams } from "react-router-dom";

const HOneDetails = ({ plans }) => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <Card bg="dark1">
      <Card.Header>
        <h6 className="text-light">Helior One</h6>
      </Card.Header>
      <Card.Body>
        <DataTable
          value={plans}
          sortMode="multiple"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          size={"small"}
          showGridlines
          stripedRows
          removableSort
        >
          <Column
            sortable
            field="contract_date"
            header={t("date")}
            body={(rowData) => rowData?.contract_date?.split("T")[0]}
          />
          <Column sortable field="contract_code" header={t("contractNumber")} />
          <Column
            sortable
            field="duration"
            header={t("caseDuration")}
            body={(rowData) => `${rowData.duration} ${t("years")}`}
          />
          <Column
            sortable
            field="total_gr"
            header={t("gramsPurchased")}
            body={(rowData) => `${rowData.total_gr}gr`}
          />
          <Column
            sortable
            field="total_payment"
            header={t("totalPayment")}
            body={(rowData) => `€
                    ${formatNumberToLocal(rowData.total_payment) || 0}`}
          />
          <Column
            sortable
            field="total_payment_with_margin"
            header={t("totalGuarantee")}
            body={(rowData) => `€
                    ${
                      formatNumberToLocal(rowData.total_payment_with_margin) ||
                      0
                    }`}
          />
          <Column
            sortable
            field="bankTransfer"
            header={t("totalBankTransfer")}
            body={(rowData) => `€
              ${formatNumberToLocal(rowData.bankTransfer) || 0}`}
          />
          <Column
            sortable
            header={t("status")}
            body={(rowData) => (
              <Badge
                bg={`${rowData.status === "Active" ? "success" : "secondary"}`}
              >
                {rowData.status === "Active" ? t("active") : t("inactive")}
              </Badge>
            )}
          ></Column>
          <Column
            body={(rowData) => (
              <Link
                to={`/${lang}/plan/helior-one/${rowData.contract_code}`}
                className="btn btn-outline-light btn-sm"
              >
                <i className="fa fa-eye" />
              </Link>
            )}
          />
        </DataTable>
      </Card.Body>
    </Card>
  );
};

export default HOneDetails;
