import React, { useRef, useState } from "react";
import { formatNumberToLocal, toastError } from "../../helpers";
import { Button, Card, Image, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import productImg from "../../assets/images/product-image.svg";
import {
  useGetValueQuery,
  usePurchaseHeliorOneMutation,
} from "../../app/features/apiSlice";
import { useNavigate, useParams } from "react-router";
import Loader from "../Loader";
import Error from "../Error";

const HOnePurchaseSummary = ({ inputData }) => {
  const { t } = useTranslation();
  const sessionId = useRef(Date.now()).current;
  const { planId, lang } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    data,
    isLoading: loading,
    isError,
    error,
  } = useGetValueQuery({ sessionId });

  const validateData = (data) => {
    // if (!data.ingots.some((item) => item.quantity > 0)) {
    //   toastError("", t("ingotQuantitIs0"));
    //   return false;
    // }
    if (
      data.ingots.reduce(
        (acc, ingot) => acc + ingot.ingot * ingot.quantity,
        0
      ) < 100
    ) {
      toastError("", t("ingotlessThan100"));
      return false;
    }
    if (!data.acceptTNC) {
      toastError("", t("accpetTNCRequired"));
      return false;
    }
    if (!data.paymentMethod) {
      toastError("", t("paymentMethodRequired"));
      return false;
    }
    return true;
  };

  const [purchaseHeliorOne] = usePurchaseHeliorOneMutation();
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let paymentMethod = "";
      paymentMethod =
        inputData.paymentMethod === "wireTransfer"
          ? "Wire Transfer"
          : paymentMethod;
      paymentMethod =
        inputData.paymentMethod === "creditCard"
          ? "Credit Card"
          : paymentMethod;
      paymentMethod =
        inputData.paymentMethod === "postal" ? "Postal" : paymentMethod;
      const ingots = inputData.ingots.filter((item) => item.quantity > 0);
      const data = {
        clientId: "",
        duration: inputData.duration.toString(),
        ingots,
        contractDate: Date.now(),
        type: inputData.type === "custody" ? "case" : "home_delivery",
        acceptTNC: inputData.acceptTNC,
        paymentMethod,
        language: lang,
      };
      if (validateData(data)) {
        const response = await purchaseHeliorOne({ data, planId });
        if (response?.error) {
          toastError(response, t("error.serverNotResponding"));
        } else {
          navigate(`/${lang}/helior-one/purchase-confirmation`);
        }
      }
    } catch (err) {
      toastError(err, t("error.serverNotResponding"));
    }
    setIsLoading(false);
  };

  if (loading) return <Loader />;
  if (isError) return <Error error={error} />;
  return (
    <Card.Body>
      <Image src={productImg} className="img-fluid" alt="Helior One" />
      <h5 className="title">Helior One</h5>
      <hr />
      <h6 className="mb-3">{t("summary")}</h6>
      <Table className="payment-table mb-1">
        <thead>
          <tr>
            <th></th>
            <th>{t("amount")}</th>
            <th>{t("guaranteeMargin")} (5%)</th>
            <th>{t("total")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("goldCost")}</td>
            <td>{formatNumberToLocal(inputData.goldCost) || 0} €</td>
            <td>{formatNumberToLocal(inputData.goldPriceWithMargin) || 0} €</td>
            <td>
              {formatNumberToLocal(
                inputData.goldCost + inputData.goldPriceWithMargin
              ) || 0}{" "}
              €
            </td>
          </tr>
          <tr>
            <td>
              {t("spreadCost")}{" "}
              {inputData.spreadValue > 0 && (
                <span>({inputData.spreadValue * 100}%)</span>
              )}{" "}
            </td>
            <td>{formatNumberToLocal(inputData.spreadCost) || 0} €</td>
            <td>
              {formatNumberToLocal(inputData.costSpreadWithMargin) || 0} €
            </td>
            <td>
              {formatNumberToLocal(
                inputData.spreadCost + inputData.costSpreadWithMargin
              ) || 0}{" "}
              €
            </td>
          </tr>
          <tr>
            <td>
              {t("storageCost")}{" "}
              {inputData.storageValue > 0 && (
                <span>({inputData.storageValue * 100}%)</span>
              )}{" "}
            </td>
            <td>{formatNumberToLocal(inputData.storageCost) || 0} €</td>
            <td>
              {formatNumberToLocal(inputData.storageCostWithMargin) || 0} €
            </td>
            <td>
              {formatNumberToLocal(
                inputData.storageCost + inputData.storageCostWithMargin
              ) || 0}{" "}
              €
            </td>
          </tr>
          <tr>
            <td>{t("manufacturingAmount")}</td>
            <td>
              {formatNumberToLocal(inputData.totalManufacturingCost) || 0} €
            </td>
            <td>-</td>
            <td>
              {formatNumberToLocal(inputData.totalManufacturingCost) || 0} €
            </td>
          </tr>
          <tr>
            <td>{t("total")}</td>
            <td>{formatNumberToLocal(inputData.totalCost) || 0} €</td>
            <td>
              {formatNumberToLocal(inputData.total_payment_with_margin) || 0} €
            </td>
            <td>
              {formatNumberToLocal(
                inputData.totalCost + inputData.total_payment_with_margin
              ) || 0}{" "}
              €
            </td>
          </tr>
        </tbody>
      </Table>
      {inputData.bank_transfer > 0 && (
        <>
          <hr />
          <p className="d-flex justify-content-between mb-1 text-uppercase">
            <span>{t("totalGrams")}</span> {inputData.totalGrams}gr
          </p>
          <p className="d-flex justify-content-between mb-1 text-uppercase">
            <span>{t("price")}</span>{" "}
            {formatNumberToLocal(inputData.bank_transfer) || 0} €
          </p>
          <p className="d-flex justify-content-between text-uppercase">
            <span>{t("stampDuty")}</span>{" "}
            {formatNumberToLocal(data?.stampDuty?.value) || 0} €
          </p>
          <hr />
          <p className="d-flex justify-content-between mb-5 text-uppercase">
            <span>{t("totalPayment")}</span>{" "}
            {formatNumberToLocal(
              inputData.bank_transfer + data?.stampDuty?.value
            )}{" "}
            €
          </p>
          <Button
            variant="primary-outline"
            className="w-100"
            onClick={handleSubmit}
          >
            {t("buyPlan")}
            {isLoading && (
              <Spinner size="sm" className="ms-2" animation="grow" />
            )}
          </Button>
        </>
      )}
    </Card.Body>
  );
};

export default HOnePurchaseSummary;
