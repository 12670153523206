import React from "react";
import { Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatNumberToLocal } from "../helpers";

const ListingCard = ({ size, title, subTitle, data, isAmount }) => {
  const { t } = useTranslation();
  return (
    <Col className="mb-3" md={size}>
      <Card bg="dark1" className="h-100">
        <Card.Header className="text-light d-flex justify-content-between">
          <span className="text-uppercase">{t(`${title}`)}</span>
          {subTitle && <span>{t(`${subTitle}`)}</span>}
        </Card.Header>
        <Card.Body>
          {data?.length > 0 && (
            <ul className="list-unstyled">
              {data.map((item, i) => (
                <li key={i} className="d-flex justify-content-between mb-3">
                  <span>{t(`${item.title}`)}</span>
                  {item.status && (
                    <i
                      className={`fa fa-circle text-${
                        item.status === "active" ? "success" : "danger"
                      }`}
                    />
                  )}
                  {item.value && Array.isArray(item.value) ? (
                    <span>{item.value}</span>
                  ) : (
                    ""
                  )}
                  {item.value && !Array.isArray(item.value) && (
                    <span>
                      {isAmount
                        ? `${formatNumberToLocal(item.value)}€`
                        : item.value}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ListingCard;
