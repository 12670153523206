import React, { useRef } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useGetUserDetailQuery } from "../../../app/features/apiSlice";
import { Col, Row } from "react-bootstrap";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import "moment/locale/it";
import SetPageTitle from "../../../components/SetPageTitle";
import ProfileCard from "../../../components/ProfileCard";
import HOneDetails from "../../../components/HeliorOne/HOneDetails";
import HeliorPlanDetails from "../../../components/HeliorPlan/HeliorPlanDetails";

const ReadClient = () => {
  const { t } = useTranslation();
  const { userId, lang } = useParams();

  lang === "it" ? moment.locale("it") : moment.locale("en");

  const sessionId = useRef(Date.now()).current;
  const { data, isLoading, isSuccess, isError, error } = useGetUserDetailQuery({
    sessionId,
    userId,
  });

  return (
    <Row className="my-4">
      {isLoading && <Loader />}
      {isError && <Error error={error} />}
      {isSuccess && (
        <>
          <SetPageTitle
            title={`${data?.user?.first_name} ${data?.user?.last_name}`}
            display={false}
          />
          <Col
            md="12"
            sm="12"
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              {t("created")}:{" "}
              {moment(data?.user?.organizationUser?.createdAt[0].startDate).fromNow()}
              <br />
              {t("lastUpdated")}:{" "}
              {moment(data?.user?.organizationUser?.updatedAt).fromNow()}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Link
                variant="icon-button"
                to={`/${lang}/client/update/${userId}`}
              >
                <i className="fa fa-pen mx-3 text-light" />
              </Link>
            </div>
          </Col>

          <ProfileCard data={data} />
          {data?.plan.length > 0 && (
            <Col className="mt-3">
              <HeliorPlanDetails plans={data?.plan} />
            </Col>
          )}
          {data?.heliorOne.length > 0 && (
            <Col className="mt-3">
              <HOneDetails plans={data?.heliorOne} />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default ReadClient;
