import React from "react";
import { Card, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const UserCard = ({ userData }) => {
  const { t } = useTranslation();

  return (
    <Card bg="dark1 text-light">
      <Card.Body className="text-center bg-dark rounded-top profile-card">
        <div className="user-box">
          <Image
            src={
              (userData?.orgUser?.sex ||
                userData?.user?.organizationUser.sex) !== "MALE"
                ? "/woman.png"
                : "/man.png"
            }
            className=""
            roundedCircle
          />
        </div>
        <h5 className="mb-1 text-white">
          {userData?.user?.first_name} {userData?.user?.last_name}
        </h5>
        {/* <h6 className="text-light">
          {t(`${userData?.user?.userRoles?.role_name}`)}
        </h6> */}
        <h6 className="text-light">
          {t(`${userData?.user?.email}`)}
        </h6>
        {userData?.orgUser?.referral_code && (
          <p>({userData?.orgUser?.referral_code})</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default UserCard;
