import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import OverlayTooltip from "../OverlayTooltip";

const Credentials = ({ formik }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>{t("email")} *</Form.Label>
        <Form.Control
          type="email"
          placeholder={t("emailPlaceholder")}
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          isInvalid={formik.touched.email && !!formik.errors.email}
          autoComplete="email"
        />
        <Form.Control.Feedback type="invalid">
          {t(`error.${formik.errors.email}`)}
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label className="d-flex justify-content-between">
            {t("form.password")} *
            <OverlayTooltip text={t(`error.invalidPassword`)} />
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder={t("form.passwordPlaceholder")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              isInvalid={formik.touched.password && !!formik.errors.password}
              autoComplete="new-password"
            />
            <Button
              className="btn-input"
              onClick={() => setShowPassword(!showPassword)}
            >
              <i className={`fa fa-${showPassword ? "eye" : "eye-slash"}`}/>
            </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.password}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.confirmPassword")} *</Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            placeholder={t("form.confirmPasswordPlaceholder")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            isInvalid={
              formik.touched.confirmPassword && !!formik.errors.confirmPassword
            }
            autoComplete="new-password"
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.confirmPassword}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.typology")} *</Form.Label>
          <Form.Select
            name="type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.type}
            isInvalid={formik.touched.type && !!formik.errors.type}
          >
            <option value="" disabled>
              {t("form.select")}
            </option>
            <option value="PRIVATE">{t("form.private")}</option>
            <option value="COMPANY">{t("form.company")}</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.type}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.role")} *</Form.Label>
          <Form.Select
            name="roleId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.roleId}
            isInvalid={formik.touched.roleId && !!formik.errors.roleId}
          >
            <option value="9">{t("Customer")}</option>
            <option value="10">{t("Collaborator")}</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.type}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.taxId")} *</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("form.taxId")}
            name="taxCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.taxCode}
            isInvalid={formik.touched.taxCode && !!formik.errors.taxCode}
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.taxCode}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      {formik.values.type === "COMPANY" && (
        <Form.Group className="mb-3">
          <Form.Label>{t("form.vat")} *</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("form.vat")}
            name="vatNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vatNumber}
            isInvalid={formik.touched.vatNumber && !!formik.errors.vatNumber}
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.vatNumber}`)}
          </Form.Control.Feedback>
        </Form.Group>
      )}
      <Row>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.pec")} </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("form.pec")}
            name="pec"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.pec}
            isInvalid={formik.touched.pec && !!formik.errors.pec}
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.pec}`)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <Form.Label>{t("form.affiliateCode")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("form.affiliateCode")}
            name="affiliateCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.affiliateCode}
            isInvalid={
              formik.touched.affiliateCode && !!formik.errors.affiliateCode
            }
          />
          <Form.Control.Feedback type="invalid">
            {t(`error.${formik.errors.affiliateCode}`)}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </>
  );
};

export default Credentials;
