import React, { useEffect, useRef, useState } from "react";
import { Button, Image, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatNumberToLocal, toastError, toastSuccess } from "../../helpers";
import productImg from "../../assets/images/product-image.svg";
import {
  useGetValueQuery,
  usePurchaseHeliorPlanMutation,
} from "../../app/features/apiSlice";
import { useNavigate, useParams } from "react-router";
import ApplyCouponCode from "../discounts/ApplyCouponCode";
import Loader from "../Loader";
import Error from "../Error";

const HeliorPurchaseSummary = ({ purchaseData, formData, setPurchaseData }) => {
  const navigate = useNavigate();
  const sessionId = useRef(Date.now()).current;
  const { t } = useTranslation();
  const { planId, lang } = useParams();
  const [enableCoupon, setEnableCoupon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    data,
    isLoading: loading,
    isError,
    error,
  } = useGetValueQuery({ sessionId });

  const validateData = (data) => {
    if (data.paymentAmount <= 0) {
      toastError("", t("recurringPaymentAmountZero"));
      return false;
    }
    if (
      data.nroRecurringPayments < formData?.minRecurringPaymentNumber ||
      data.nroRecurringPayments > formData?.maxRecurringPaymentNumber
    ) {
      toastError(
        "",
        `${t("minRecurringNumberMsg")} ${
          formData?.minRecurringPaymentNumber
        } ${t("and")} ${formData?.maxRecurringPaymentNumber}`
      );
      return false;
    }
    if (data.nroInitialPayments <= 0) {
      toastError("", t("initialPaymentNumberZero"));
      return false;
    }
    if (data.nroInitialPayments >= parseInt(data.nroRecurringPayments)) {
      toastError("", t("initialPaymentNumberError"));
      return false;
    }
    if (!data.acceptTNC) {
      toastError("", t("accpetTNCRequired"));
      return false;
    }
    if (!data.paymentMethod) {
      toastError("", t("paymentMethodRequired"));
      return false;
    }
    return true;
  };

  const [purchaseHeliorPlan] = usePurchaseHeliorPlanMutation();
  const handleSubmit = async () => {
    setIsLoading(true);
    let paymentMethod = "";
    paymentMethod =
      purchaseData.paymentMethod === "wireTransfer"
        ? "Wire Transfer"
        : paymentMethod;
    paymentMethod =
      purchaseData.paymentMethod === "creditCard"
        ? "Credit Card"
        : paymentMethod;
    paymentMethod =
      purchaseData.paymentMethod === "postal" ? "Postal" : paymentMethod;
    try {
      const data = {
        language: lang,
        periodicityOfPayment: purchaseData.paymentPeriodicity,
        paymentAmount: purchaseData.recurringPaymentAmount,
        nroRecurringPayments: purchaseData.recurringPaymentNumber,
        nroInitialPayments: purchaseData.initialPaymentNumber,
        acceptTNC: purchaseData.acceptTNC,
        paymentMethod,
        isDocSplit: purchaseData.docSplit,
        ingot: purchaseData.ingot,
        discountCode: purchaseData.discountCode,
      };
      if (validateData(data)) {
        const response = await purchaseHeliorPlan({ data, planId });
        if (response?.error) {
          toastError(response, t("error.serverNotResponding"));
        } else {
          if (data.isDocSplit && purchaseData.dossierOpeningCharge <= 12000) {
            toastSuccess(response?.data?.message);
            navigate(`/${lang}/dashboard`);
          } else {
            navigate(`/${lang}/helior-plan/purchase-confirmation`);
          }
        }
      }
    } catch (err) {
      toastError(err, t("error.serverNotResponding"));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (purchaseData.dossierOpeningCharge >= 2000) {
      setEnableCoupon(true);
    } else {
      setEnableCoupon(false);
    }
  }, [purchaseData]);

  if (loading) return <Loader />;
  if (isError) return <Error error={error} />;

  return (
    <>
      <Image src={productImg} className="img-fluid" alt="Helior Plan" />
      <h5 className="title">Helior Plan</h5>
      <hr />
      <ul className="list-unstyled">
        <li className="d-flex justify-content-between mb-3">
          <span>{t("recurringAmountPaid")} </span>€
          {formatNumberToLocal(purchaseData.totalRecurringAmount) || 0}
        </li>
        <li className="d-flex justify-content-between mb-3">
          <span>{t("numberOfInitialPayments2")} </span>
          {purchaseData.initialPaymentNumber || 0}
        </li>
        <li className="d-flex justify-content-between mb-3">
          <span>{t("numberOfRecurringPayments2")} </span>
          {purchaseData.recurringPaymentNumber || 0}
        </li>
        <li className="d-flex justify-content-between mb-3">
          <span>{t("paymentAmount")} </span>€
          {purchaseData.recurringPaymentAmount || 0}
        </li>
        <li className="d-flex justify-content-between mb-3">
          <span>{t("docShareOfTotal")} </span>€
          {formatNumberToLocal(purchaseData.dossierOpeningCharge) || 0}
        </li>
        {purchaseData.docSplit && (
          <li className="d-flex justify-content-between mb-3">
            <span>{t("docSplitAmount")} </span>€
            {formatNumberToLocal(purchaseData.docSplitAmount) || 0}
          </li>
        )}
        <li className="d-flex justify-content-between mb-3">
          <span>{t("initialPaymentAmount")} </span>€
          {formatNumberToLocal(purchaseData.initialPaymentAmount) || 0}
        </li>
        {enableCoupon && (
          <ApplyCouponCode
            purchaseData={purchaseData}
            setPurchaseData={setPurchaseData}
          />
        )}
      </ul>
      {purchaseData.totalPayment > 0 && (
        <>
          <hr />
          <p className="d-flex justify-content-between text-uppercase mb-1">
            <span>{t("price")}</span>€
            {formatNumberToLocal(purchaseData.totalPayment)}
          </p>
          <p className="d-flex justify-content-between text-uppercase mb-1">
            <span>{t("stampDuty")}</span>€
            {formatNumberToLocal(data?.stampDuty?.value || 0)}
          </p>
          <p className="d-flex justify-content-between text-uppercase mb-1">
            <span>{t("paymentMethod")}</span>{" "}
            {t(`${purchaseData.paymentMethod}`)}
          </p>
          <hr />
          <p className="d-flex justify-content-between text-uppercase mb-5">
            <span>{t("totalPayment")}</span>€
            {purchaseData.totalPayment !== 0
              ? formatNumberToLocal(
                  purchaseData.totalPayment + (data?.stampDuty?.value || 0)
                )
              : formatNumberToLocal(purchaseData.totalPayment)}
          </p>
          <Button
            variant="primary-outline"
            className="w-100 d-flex align-items-center justify-content-center"
            onClick={handleSubmit}
          >
            {purchaseData.docSplit && purchaseData.dossierOpeningCharge <= 12000
              ? t("sendRequest")
              : t("buyPlan")}
            {isLoading && (
              <Spinner size="sm" className="ms-2" animation="grow" />
            )}
          </Button>
        </>
      )}
    </>
  );
};

export default HeliorPurchaseSummary;
