import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Col } from "react-bootstrap";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-light p-2">
        {payload[0].payload.name}<br/>{payload[0].value}
      </div>
    );
  }
  return null;
};

const NewMemberChart = ({size, data}) => {
  const { t } = useTranslation();

  return (
    <Col className="mb-3" md={size}>
      <Card bg="dark1" className="h-100">
        <Card.Header className="text-light text-uppercase">{t("newMembers")}</Card.Header>
        <Card.Body>
          <div style={{ height: 350 }}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={data}
                margin={{
                  top: 30,
                  right: 10,
                  left: -10,
                  bottom: 40,
                }}
              >
                <XAxis
                  dataKey="name"
                  angle={-50}
                  textAnchor="end"
                  interval={0}
                  stroke="#fff"
                  tick={{ fill: "#fff", fontSize: 10 }}
                />
                <YAxis stroke="#fff" tick={{ fill: "#fff", fontSize: 10 }} />
                <Tooltip content={<CustomTooltip />}/>
                <Area
                  type="monotone"
                  dataKey="count"
                  stroke="#fff"
                  fill="#b8932c"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default NewMemberChart;
