import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Col } from "react-bootstrap";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const DocNetworkChart = ({ size, data }) => {
  const { t } = useTranslation();
  return (
    <Col className="mb-3" md={size}>
      <Card bg="dark1" className="h-100">
        <Card.Header className="text-light text-uppercase">
          {t("docPeerVsDocNetwork")}
        </Card.Header>
        <Card.Body>
          <div style={{ height: 350 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 30,
                }}
              >
                <XAxis
                  dataKey="name"
                  angle={-50}
                  textAnchor="end"
                  interval={0}
                  stroke="#fff"
                  tick={{ fill: "#fff", fontSize: 10 }}
                />
                <YAxis stroke="#fff" tick={{ fill: "#fff", fontSize: 10 }} />
                <Tooltip />
                <Legend
                  verticalAlign="top"
                  align="center"
                  wrapperStyle={{
                    top: 0,
                    left: 0,
                    right: 0,
                    lineHeight: "40px",
                  }}
                />
                <Bar
                  dataKey="personal"
                  fill="#b8932c"
                  activeBar={<Rectangle fill="#b8932c" stroke="#fff" />}
                />
                <Bar
                  dataKey="network"
                  fill="#17171F"
                  activeBar={<Rectangle fill="#17171F" stroke="#fff" />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default DocNetworkChart;
