import React from 'react'
import "../assets/css/style404.css"
import { useTranslation } from 'react-i18next'
import { Image } from 'react-bootstrap'

const NoMatch = () => {
  const { t } = useTranslation()
  return (
    <div className='no-page'>
      <div className="background">
        <div className="ground"></div>
      </div>
      <div className="container">
        <div className="left-section">
          <div className="inner-content">
            <h1 className="heading">404</h1>
            <p className="subheading">{t('error404')}</p>
          </div>
        </div>
        <div className="right-section">
          <Image src="/img-404.svg" alt="404" className='svgimg' />
        </div>
      </div>
    </div>
  )
}

export default NoMatch