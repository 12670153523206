import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserCard from "./UserCard";

const ProfileCard = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col lg={3}>
        <UserCard userData={data} commission={data?.commission}/>
      </Col>
      <Col lg={9}>
        <Card bg="dark1 text-light h-100">
          <Card.Body>
            <Row>
              <Col lg={4}>
                <h6>{t("signup.personalData")}</h6>
                <ul className="list-unstyled user-data my-4">
                  <li className="text-capitalize">
                    {t("form.email")}:{" "}
                    <span className="text-lowercase">{data?.user?.email}</span>
                  </li>
                  <li className="text-capitalize">
                    {t("form.mobileNumber")}: {data?.user?.phone}
                  </li>
                  <li className="text-capitalize">
                    {t("form.telephone")}:{" "}
                    {data?.user?.organizationUser?.telephone}
                  </li>
                  <li className="text-capitalize">
                    {t("form.dob")}:{" "}
                    {data?.user?.organizationUser?.date_of_birth}
                  </li>
                  <li className="text-capitalize">
                    {t("form.sex")}: {data?.user?.organizationUser?.sex}
                  </li>
                  <li className="text-capitalize">
                    {t("form.typology")}: {data?.user?.organizationUser?.type}
                  </li>
                  {data?.user?.organizationUser?.type === "COMPANY" && (
                    <li className="text-capitalize">
                      {t("form.vat")}: {data?.user?.organizationUser?.vatNumber}
                    </li>
                  )}
                  <li className="text-capitalize">
                    {t("form.taxId")}: {data?.user?.organizationUser?.tax_code}
                  </li>
                  <li className="text-capitalize">
                    {t("form.pec")}: {data?.user?.organizationUser?.PEC}
                  </li>
                  {data?.user?.userRoles?.role_name === "Collaborator" && (
                    <li className="text-capitalize">
                      {t("referralCode")}:{" "}
                      {data?.user?.organizationUser?.referral_code}
                    </li>
                  )}
                  <li className="text-capitalize">
                    {t("form.affiliateCode")}:{" "}
                    {data?.user?.organizationUser?.affiliate_code}
                  </li>
                </ul>
              </Col>
              <Col lg={4}>
                <h6>{t("signup.residence")}</h6>
                <ul className="list-unstyled user-data my-4">
                  <li className="text-capitalize">
                    {t("form.address")}: {data?.user?.organizationUser?.address}
                  </li>
                  <li className="text-capitalize">
                    {t("form.country")}:{" "}
                    {data?.user?.organizationUser?.province}
                  </li>
                  <li className="text-capitalize">
                    {t("form.city")}: {data?.user?.organizationUser?.city}
                  </li>
                  <li className="text-capitalize">
                    {t("form.postcode")}:{" "}
                    {data?.user?.organizationUser?.postcode}
                  </li>
                </ul>
              </Col>
              <Col lg={4}>
                <h6>{t("signup.domicile")}</h6>
                <ul className="list-unstyled user-data my-4">
                  <li className="text-capitalize">
                    {t("form.address")}:{" "}
                    {data?.user?.organizationUser?.address_home}
                  </li>
                  <li className="text-capitalize">
                    {t("form.country")}:{" "}
                    {data?.user?.organizationUser?.province_home}
                  </li>
                  <li className="text-capitalize">
                    {t("form.city")}: {data?.user?.organizationUser?.city_home}
                  </li>
                  <li className="text-capitalize">
                    {t("form.postcode")}:{" "}
                    {data?.user?.organizationUser?.postcode_home}
                  </li>
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default ProfileCard;
