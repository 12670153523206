import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../app/features/DataSlice";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const PaymentSuccess = () => {
  const pageTitle = "paymentSuccess";
  const { lang } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(setPageTitle(t(`${pageTitle}`)));
    // eslint-disable-next-line
  }, []);
  return (
    <Row className="my-5">
      <Col md="12" className="text-center p-3">
        <h3>{t(`${pageTitle}`)}</h3>
        <Image
          src="/payment-success.webp"
          width={270}
          height={250}
          alt="Payment Succes"
        />
        <h5>{t("thankYouForPurchase")}</h5>
        <h5>{t("checkEmail")}</h5>
        <Link to={`/${lang}`} className="btn btn-primary mt-3">
          {t("backToDashboard")}
        </Link>
      </Col>
    </Row>
  );
};

export default PaymentSuccess;
