import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useGetProfileQuery } from "../../app/features/apiSlice";
import Loader from "../Loader";
import Error from "../Error";
import {
  hPlanCalculation,
  toastError,
  toastSuccess,
} from "../../helpers";

const ApplyCouponCode = ({ purchaseData, setPurchaseData }) => {
  const sessionId = useRef(Date.now()).current;
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { data, isLoading, isError, error } = useGetProfileQuery({
    sessionId,
  });

  const handleDiscountCoupon = (discountCode) => {
    const newData = { ...purchaseData, discountCode };
    const { message, ...result } = hPlanCalculation(newData);
    if (message.type === "error") {
      toastError("", t(message.text));
    }
    if (message.type === "success") {
      toastSuccess(t(message.text));
    }
    setPurchaseData(result);
    setShow(false);
  };

  const handleRemoveCoupon = () => {
    const newData = { ...purchaseData, discountCode: "" };
    const { message, ...result } = hPlanCalculation(newData);
    if (message.type === "error") {
      toastError("", t(message.text));
    }
    if (message.type === "success") {
      toastSuccess(t(message.text));
    }
    setPurchaseData(result);
  };

  return (
    <>
      <li className="d-flex justify-content-between mb-3">
        <span className="text-gold">
          <i className="fa fa-gift"></i>{" "}
          {purchaseData.discountCode || t("applyCoupon")}
        </span>
        <span
          onClick={() =>
            purchaseData.discountCode ? handleRemoveCoupon("") : setShow(true)
          }
          className="btn-span text-gold"
        >
          {purchaseData.discountCode ? t("removeCoupon") : t("addCoupon")}
        </span>
      </li>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark h6">
            {t("selectCouponCode")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader />}
          {isError && <Error error={error} />}
          {data?.discounts?.length > 0 ? (
            <>
              {data?.discounts?.map((discount, i) => (
                <div className="coupon-wrap" key={i}>
                  <p className="coupon-text">{discount.dis_code}</p>
                  <button
                    className="btn-coupon btn-sm"
                    onClick={() => handleDiscountCoupon(discount.dis_code)}
                  >
                    {t("applyCoupon")}
                  </button>
                </div>
              ))}
            </>
          ) : (
            <p className="text-dark">{t("notAvailable")}</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplyCouponCode;
