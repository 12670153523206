import { t } from "i18next";
import React from "react";
import { Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const UpcomingTeamManager = ({ user }) => {
  const { lang } = useParams();

  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <div className="d-flex align-items-center">
        <Image src="/man.png" alt="Man" height={50} />
        <Link
          to={`/${lang}/collaborator/${user?.userId}`}
          className="ms-3 text-light text-decoration-none"
        >
          <b className="m-0">{user?.referral_code}</b>
          <p className="mb-0">
            {user?.first_name} {user?.last_name}
          </p>
        </Link>
      </div>
      <div className="text-light">
        {t("current")}: {user?.currentQualification}
        <br />
        {t("next")}: {user?.nextQualification}
      </div>
    </div>
  );
};

export default UpcomingTeamManager;
