import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NewMemberChart from "../../components/dashboard/NewMemberChart";
import DocNetworkChart from "../../components/dashboard/DocNetworkChart";
import SmallCards from "../../components/dashboard/SmallCards";
import wallet from "../../assets/images/wallet.webp";
import commission from "../../assets/images/commission.webp";
import network from "../../assets/images/network.webp";
import personal from "../../assets/images/personal.webp";
import pool from "../../assets/images/pool.webp";
import { formatNumberToLocal } from "../../helpers";
import IncomeDOC from "../../components/dashboard/IncomeDOC";
import ListingCard from "../../components/ListingCard";
import TeamPerformance from "../../components/teamPerformance/TeamPerformance";
import {
  useGetProfileQuery,
  useNewCollabDashboardQuery,
} from "../../app/features/apiSlice";
import Loader from "../../components/Loader";
import Error from "../../components/Error";
import { useDispatch } from "react-redux";
import { setUserData } from "../../app/features/DataSlice";
import SetPageTitle from "../../components/SetPageTitle";

const endRowTemplate = [
  {
    title: "activityStatus",
    items: [
      {
        title: "academyRegistration",
        status: "active",
      },
      {
        title: "presenceAtWebinars",
        status: "inactive",
      },
      {
        title: "rightToOvers",
        value: "11-2024",
      },
    ],
  },
  {
    title: "CONTEST START UP",
    items: [
      {
        title: "firstMonth",
        status: "active",
      },
      {
        title: "secondMonth",
        status: "inactive",
      },
    ],
  },
  {
    title: "personalIncentivePlan",
    items: [
      {
        title: "incentiveDeadline",
        value: "-43 days",
      },
    ],
  },
  {
    title: "discountCoupons",
    items: [
      {
        title: "toBeUsed",
        value: [],
      },
    ],
  },
  {
    title: "autumContest",
    items: [
      {
        title: "docMissingFromPrize",
        value: "-12.500",
      },
    ],
  },
];

const CollaboratorDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sessionId = useRef(Date.now()).current;

  const { data, isLoading, isError, error, isSuccess } =
    useNewCollabDashboardQuery({ sessionId });

  const profile = useGetProfileQuery({ sessionId });

  useEffect(() => {
    if (profile?.isSuccess) {
      const user = profile?.data?.user;
      dispatch(setUserData(user));
    }
    // eslint-disable-next-line
  }, [profile]);

  // Derive endRowData based on the fetched data
  const endRowData = React.useMemo(() => {
    if (!isSuccess) return endRowTemplate;

    return endRowTemplate.map((section) => {
      if (
        section.title === "discountCoupons" &&
        data?.data?.givenDiscounts.length > 0
      ) {
        return {
          ...section,
          items: section.items.map((item) =>
            item.title === "toBeUsed"
              ? { ...item, value: data.data.givenDiscounts.join(", ") }
              : item
          ),
        };
      }
      return section;
    });
  }, [isSuccess, data]);

  if (isLoading) return <Loader />;
  if (isError) return <Error error={error} />;

  return (
    <div className="my-3">
      <SetPageTitle title="dashboard" display={false}/>
      <Row className="mb-3">
        <SmallCards
          imgSrc={wallet}
          title={t("eWalletBalance")}
          number={`${formatNumberToLocal(0)}€`}
          tooltipText={t("totalEWalletBalance")}
        />
        <SmallCards
          imgSrc={commission}
          title={t("commissionOfMonth")}
          number={`${formatNumberToLocal(data?.data?.commissionOfMonth || 0)}€`}
          tooltipText={t("totalCommissionsCurrentMonth")}
        />
        <SmallCards
          imgSrc={pool}
          title={t("poolBonus")}
          number={`${formatNumberToLocal(data?.data?.poolBonus || 0)}€`}
          tooltipText={t("totalSilverGoldPoolMonthlyEarnings")}
        />
        <SmallCards
          imgSrc={personal}
          title={t("personalDoc")}
          number={`${formatNumberToLocal(data?.data?.personalDoc || 0)}`}
          tooltipText={t("totalPersonalDocCurrentMonth")}
        />
        <SmallCards
          imgSrc={network}
          title={t("networkDoc")}
          number={`${formatNumberToLocal(data?.data?.networkDoc || 0)}`}
          tooltipText={t("totalDocQuotasEntireNetworkCurrentMonth")}
        />
      </Row>
      <Row>
        <Col md="9">
          <Row>
            <NewMemberChart size="6" data={data?.data?.newMemberData} />
            <DocNetworkChart size="6" data={data?.data?.personalDocData} />
            <IncomeDOC size="6" data={data} />
            <Col md="6">
              <Row>
                <ListingCard
                  size="12"
                  title="nextObjective"
                  subTitle="docMissing"
                  data={[
                    {
                      title: "teamManager",
                      value: "13.400",
                    },
                    {
                      title: "Pool Bonus Silver",
                      value: "15.000",
                    },
                  ]}
                  isAmount={true}
                />

                <ListingCard
                  size="12"
                  title="lastMonthPoolDistribution"
                  data={[
                    {
                      title: 'teamManagerPool',
                      value:
                        data?.data?.distributedPool
                          ?.lastMonthTeamManagerPoolBonus,
                    },
                    {
                      title: "Pool Bonus Silver",
                      value:
                        data?.data?.distributedPool?.lastMonthSilverPoolBonus,
                    },
                    {
                      title: "Pool Bonus Gold",
                      value:
                        data?.data?.distributedPool?.lastMonthGoldPoolBonus,
                    },
                  ]}
                  isAmount={true}
                />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md="3">
          <Row className="h-100">
            {endRowData.map((section, i) => (
              <ListingCard
                key={i}
                size="12"
                title={section.title}
                data={section.items}
              />
            ))}
          </Row>
        </Col>
      </Row>
      <TeamPerformance data={data?.data} />
    </div>
  );
};

export default CollaboratorDashboard;
