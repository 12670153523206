import { Container } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./_includes/Sidebar";
import TopNav from "./_includes/TopNav";
import { useState } from "react";
import Footer from "./_includes/Footer";
import { useSelector } from "react-redux";
import { selectIsLogged } from "../app/features/authSlice";
import "primereact/resources/themes/lara-light-cyan/theme.css";

const MainLayout = () => {
  const { lang } = useParams();
  const isLoggedIn = useSelector(selectIsLogged);
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [title, setTitle] = useState('Dashboard');

  if (!isLoggedIn) {
    return navigate(`/${lang}/login`);
  }

  return (
    <>
      <div className={`sb-nav-fixed ${toggleMenu ? "sb-sidenav-toggled" : ""}`}>
        <div id="layoutSidenav">
          <Sidebar />
          <div id="layoutSidenav_content">
            <main>
            <TopNav toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} title={title}/>
              <Container fluid className="p-4 bg-light-dark2 content">
                <Outlet setTitle={setTitle}/>
              </Container>
            </main>
            <Footer />
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default MainLayout;
