import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "../components/Loader";
import { useActivateAccountMutation } from "../app/features/apiSlice";
import { swalError, swalSuccess } from "../helpers";
import { useTranslation } from "react-i18next";

const VerifyAccount = () => {
  const { token, lang } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activateAccount] = useActivateAccountMutation();
  const handleSubmit = async () => {
    const data = { token: token, language: lang };
    try {
      const response = await activateAccount(data).unwrap();
      swalSuccess(response?.msg);
    } catch (err) {
      swalError(err, t("error.serverNotResponding"));
    }
    navigate(`/${lang}/login`);
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line
  }, []);

  return <Loader />;
};

export default VerifyAccount;
