import React from "react";
import { formatNumberToLocal } from "../../helpers";
import { Image } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const PerformerCard = ({ user }) => {
  const { lang } = useParams();

  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <div className="d-flex align-items-center">
        <Image src="/man.png" alt="Man" height={50} />
        <Link
          to={`/${lang}/collaborator/${user?.user_id || user?.user?.id}`}
          className="ms-3 text-light text-decoration-none"
        >
          <b className="m-0">{user?.referral_code}</b>
          <p className="mb-0">
            {user?.user?.first_name} {user?.user?.last_name}
          </p>
        </Link>
      </div>
      <div className="text-light">
        {(() => {
          if (user?.total_doc) {
            return formatNumberToLocal(user?.total_doc || 0);
          } else if (user?.count) {
            return user?.count;
          } else {
            return 0;
          }
        })()}
      </div>
    </div>
  );
};

export default PerformerCard;
