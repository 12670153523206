import React from "react";
import { Card, Col, Image } from "react-bootstrap";
import OverlayTooltip from "../_includes/OverlayTooltip";

const SmallCards = ({ imgSrc, title, number, tooltipText, icon }) => {
  return (
    <Col>
      <Card bg="dark1" className="h-100">
        <Card.Body className="p-2 text-light">
          <div className="d-flex align-items-center">
          {imgSrc ? <Image src={imgSrc} alt={imgSrc} height={60} /> : <span className="sm-card-icon">{icon}</span>}

            <div className="ms-3">
              <b className="m-0">{title}</b>
              <p className="mb-0">{number}</p>
            </div>
          </div>
          {tooltipText && (
            <div style={{ position: "absolute", top: "3px", right: "5px" }}>
              <OverlayTooltip text={tooltipText} />
            </div>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default SmallCards;
